import React from "react";
import {Routes, Route} from "react-router-dom";

import {observer} from "mobx-react-lite";

import {store} from "store";

import Create from "./components/Create";
import Table from "./components/Table";


interface CashiersProps {
    branchId: number;
}

const Cashiers: React.FC<CashiersProps> = observer((props) => {
    const {branchId} = props;
    const {user} = store;

    const canCreate = !user.actions.readOnly;

    return (
        <Routes>
            <Route
                path="/"
                element={<Table branchId={branchId} canCreate={canCreate}/>}
            />

            {canCreate && (
                <Route
                    path="/create/"
                    element={<Create />}
                />
            )}

        </Routes>
    );
});

export default Cashiers;
