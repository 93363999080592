import CustomerData from 'pages/MerchantDashboard/containers/CustomerData'

import s from './dashboadrd.module.scss'
import { MerchantsDetail } from 'services/api/MerchantsApi.models'
interface Props {
	data: MerchantsDetail
}
export default function Dashboard({ data }: Props) {
	return (
		<section className={s.container}>
			<CustomerData data={data} />
		</section>
	)
}
