import ReactDOM from 'react-dom/client'
import './index.css'
import { MKASSA_ARM_TOKEN } from 'services/storage/constants'
import ShadowDOMWidjet from 'ShadowDOMWidjet'

export const inject = (merchantToken: string, node: HTMLElement) => {
	sessionStorage.setItem(MKASSA_ARM_TOKEN, merchantToken)
	const root = ReactDOM.createRoot(node)

	root.render(
		<div className='MKASSA-ARM-WIDGET'>
			<ShadowDOMWidjet />
		</div>
	)
	return root
}
