import React from 'react'
import { useNavigate, useResolvedPath, useSearchParams } from 'react-router-dom'

import { useTableData } from 'hooks'

import { AppTable } from 'components'

import { API_DATE_FORMAT, displayDate } from 'utils'

import { ShiftsApi } from 'services/api/ShiftsApi'
import { ShiftsItem } from 'services/api/ShiftsApi.module'
import SortTitle from 'components/Sort/SortTitle'
import ShiftFilter from '../shift-filter'

import dayjs from "dayjs"
import { useAppDispatch } from 'hooks/useRedux'
import { resetFilters, setDateRange } from 'store/rtk/slices/filters/transactions-filter.slice'

interface Props {
	id?: string
	hideCashierName?: boolean
}

const tableColumns = [
	{
		dataIndex: 'id',
		title: '№ Смены',
	},

	{
		dataIndex: 'is_open',
		title: 'Открыт',
		render: (value: boolean) => (value ? 'Да' : 'Нет'),
	},
	{
		dataIndex: 'started_at',
		title: 'Открытие смены',
		render: (value: string) => displayDate(value),
	},
	{
		dataIndex: 'end_at',
		title: 'Закрытие смены',
		render: (value: string) => displayDate(value),
	},

	{
		dataIndex: 'transaction_sum',
		title: () => (
			<>
				<SortTitle
					paramKey='order_by'
					value='transaction_sum'
					title='Сумма'
				/>
			</>
		),
	},

	{
		dataIndex: 'transaction_count',
		title: () => (
			<>
				<SortTitle
					paramKey='order_by'
					value='transaction_count'
					title='Количество успешных транзакций'
				/>
			</>
		),
	},
]

const ShiftsCommon: React.FC<Props> = ({ hideCashierName }) => {
	const [searchParams] = useSearchParams()
	const dispatch = useAppDispatch()
	const gteDate = searchParams.get('start_gte')
	const lteDate = searchParams.get('start_lte')
	const order_by = searchParams.get('order_by')

	const cashierId = searchParams.get('cashierId')

	const navigate = useNavigate()
	const pathname = useResolvedPath('').pathname

	const {
		loading,
		tableData,
		handleTableChange,
		pagination,
		// @ts-ignore
	} = useTableData(ShiftsApi.getShifts as any, {
		cashier: cashierId,
		order_by,
		start_lte: lteDate,
		start_gte: gteDate,
	})

	const showDetail = (
		record: ShiftsItem,
		e: React.MouseEvent<any, MouseEvent>
	) => {
		const date_gte = record.started_at
			? dayjs(record.started_at).format(API_DATE_FORMAT)
			: dayjs().format(API_DATE_FORMAT);
		const date_lte = record.end_at
			? dayjs(record.end_at).format(API_DATE_FORMAT)
			: dayjs().format(API_DATE_FORMAT);
		dispatch(resetFilters())
		dispatch(setDateRange({startDate: date_gte, endDate: date_lte}))

		if (e.metaKey || e.ctrlKey) {
			window.open(`${pathname}${record.id}/`)
			return
		}
		navigate(`${pathname}${record.id}/`)
	}

	const combineTable = [...tableColumns]

	if (!hideCashierName) {
		const cashier = {
			dataIndex: 'cashier_name',
			title: 'Кассир',
		}
		combineTable.push(cashier)
	}

	return (
		<div>
			<ShiftFilter />
			<div>
				<AppTable
					columns={combineTable}
					data={tableData?.results}
					pagination={pagination}
					onChange={handleTableChange}
					loading={loading}
					onRow={(record: any) => {
						return {
							onClick: e => {
								showDetail(record, e)
							},
						}
					}}
				/>
			</div>
		</div>
	)
}

export default ShiftsCommon
