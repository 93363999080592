import { Navigate, Route, Routes, useNavigate } from 'react-router'
import MerchantLayout from '../common/MerchantLayout'
import { Dashboard } from '../components'
import { MerchantRoutes } from './routes-merchant'
import { getInStorage } from 'services/storage'
import Branches from '../components/Dashboard/components/Branches'
import Cashiers from '../components/Dashboard/components/Cashier'
import CashRegister from '../components/Dashboard/components/CashRegister'
import Transactions from '../components/Dashboard/components/Transactions'
import Shifts from '../components/Dashboard/components/Shift'
import Branch from '../components/Dashboard/components/Branches/BranchDetail'
import CashierDetail from '../components/Dashboard/components/Cashier/CashierDetail'
import { store } from 'store'
import CreateCashier from '../components/Dashboard/components/Cashier/components/Create'
import CreateBranch from '../components/Dashboard/components/Branches/components/Create'
import { useEffect } from 'react'
import ShiftDetail from '../components/Dashboard/components/Shift/ShiftDetail'
import { MKASSA_ARM_TOKEN } from 'services/storage/constants'
import { AuthApi } from 'services/api/AuthApi'
import { observer } from 'mobx-react-lite'
import { ARM_ROUTES } from 'router/routes'
import { LoadingArea } from 'components'
import TransactionDetailPage from '../components/Dashboard/components/TransactionDetail'
import TransactionDetailBranchPage from '../components/Dashboard/components/TransactionDetailBranch'
import { Content } from 'antd/es/layout/layout'
import Cashout from '../components/Cashout'
import ReportsList from '../components/Dashboard/components/ReportsList'

const MerchantDashboardRoutes = observer(() => {
	const { user, merchantStore } = store

	const canCreate = !user.actions.readOnly

	const navigate = useNavigate()

	const token = getInStorage(MKASSA_ARM_TOKEN)

	const userData = user?.getUser()

	const merchantDetail = merchantStore.getDetailInfo()

	const merchantID = merchantDetail?.id

	const isMerchant = userData?.user_type === 'SHOP_OWNER'

	useEffect(() => {
		const getUser = async () => {
			const { data: userMe } = await AuthApi.getUser()

			if (userMe.user_type === 'CASHIER') {
				navigate('/cashier-dashboard/')
				return
			}
			merchantStore.getAsyncDetail(userMe.company_id)

			user.setUser(userMe)
		}

		if (token) {
			getUser()
		} else {
			navigate('/auth')
		}
	}, [token, user])


	if (!userData || !merchantDetail) {
		return (
			<LoadingArea spin loading={true}>
				<div style={{ width: '100vw', height: '100vh' }}> Минуту</div>
			</LoadingArea>
		)
	}

	if (!isMerchant) {
		return <Navigate to={ARM_ROUTES.auth} />
	}

	return (
		<Content style={{ display: 'contents' }}>
			<Routes>
				{/* @ts-ignore */}
				<Route element={<MerchantLayout data={merchantDetail} />}>
					<Route
						path={`${MerchantRoutes.dashboard}/detail/*`}
						// @ts-ignore
						element={<Dashboard data={merchantDetail} />}
					/>
					<Route path={`${MerchantRoutes.branches}/*`} element={<Branches />} />
					<Route path={`/dashboard/cashout/*`} element={<Cashout />} />

					{canCreate && (
						<Route
							path={`${MerchantRoutes.branches}/create/`}
							element={<CreateBranch />}
						/>
					)}

					<Route path={`${MerchantRoutes.cashier}/*`} element={<Cashiers />} />
					{canCreate && (
						<Route
							path={`${MerchantRoutes.cashier}/create/`}
							element={<CreateCashier />}
						/>
					)}

					<Route
						path={`${MerchantRoutes.cash_register}/*`}
						element={
							<CashRegister
								branchId={Number(merchantID)}
								// @ts-ignore
								kkmExists={merchantDetail.kkm_user_exists}
							/>
						}
					/>
					<Route
						path={`${MerchantRoutes.transaction_detail}/*`}
						element={<TransactionDetailPage />}
					/>
					<Route
						element={<Transactions params={{ company: merchantID }} />}
						path={`${MerchantRoutes.reports}/*`}
					/>
					<Route
						element={<Shifts id={String(merchantID)} />}
						path={`${MerchantRoutes.shifts}/*`}
					/>
					<Route
						element={<ReportsList ownerId={merchantDetail?.owner?.id} />}
						path={`${MerchantRoutes.reports_list}/`}
					/>
				</Route>

				<Route
					path={`${MerchantRoutes.branches}/:branchId/*`}
					element={<Branch />}
				/>
				<Route
					path={`${MerchantRoutes.transaction_detail}/:transactionDetailId/*`}
					element={<TransactionDetailBranchPage />}
				/>
				<Route
					path={`${MerchantRoutes.cashier}/:cashierId/*`}
					element={<CashierDetail />}
				/>
				<Route
					element={<ShiftDetail />}
					path={`${MerchantRoutes.shifts}/:shiftId/*`}
				/>
				<Route
					path='*'
					element={
						<Navigate
							to={`${ARM_ROUTES.merchant_dashboard}${MerchantRoutes.dashboard}/detail/`}
						/>
					}
				/>
			</Routes>
		</Content>
	)
})

export default MerchantDashboardRoutes
