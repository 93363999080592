import {clientApi} from "./clientApi";
import {Pagination} from "../../models/pagination.models";

interface Props extends Pagination {
    company?: string;
    branch?: string;
    query?: string;
}

export interface CashRegisterList {
    id: number;
    name: string;
    registration_number: string;
    fiscal_number: string;
    created_date: string;
    deregistration_date: string;
    fn_expires_at: string;
    tax_system: string;
    vat_payer: string;
    has_product: boolean;
    has_service: boolean;
    has_prepayment: boolean;
    has_post_payment: boolean;
    entrepreneurship_object: string;
    business_activity: string;
    tax_authority_department: string;
    postal_code: string;
    closed_shifts_counter: number;
    transactions_counter: number;
    is_shift_open: boolean;
}

export const CashierRegisterApi = {
    getCashRegister(params?: Props) {
        return clientApi.get("crm/crm_cash_register/", { params });
    },
};
