import QRCodeStyling, { Options } from 'qr-code-styling'

import { useEffect, useRef } from 'react'

interface Props {
    url: string
    options: Options
    className?: string
}

const AppQr = (props: Props) => {
    const { url, options, className } = props

    const qrCode = useRef<QRCodeStyling | null>(null)
    const ref = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (!qrCode.current) {
            qrCode.current = new QRCodeStyling({
                width: 300,
                height: 300,
                ...options,
                data: url,
            })
            qrCode.current.append(ref.current!)
        } else {
            qrCode.current.update({
                data: url,
                ...options,
            })
        }
    }, [])

    return (
        <div className={className}>
            <div ref={ref} />
        </div>
    )
}

export default AppQr
