import TransactionDetail from 'shared/common/reports/transaction-detail'
import s from './TransactionDetail.module.scss'

export default function TransactionDetailPage() {
	return (
		<section>
			<TransactionDetail />
		</section>
	)
}
