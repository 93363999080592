const MbankIconFull = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={121} height={32} fill="none">
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M23.239 27.56a2.36 2.36 0 0 0 2.354-2.353V11.296c0-.605-.228-1.21-.687-1.668a2.36 2.36 0 0 0-3.33 0l-4.987 4.988a2.36 2.36 0 0 0 0 3.33 2.36 2.36 0 0 0 3.33 0l.966-.966v8.227a2.36 2.36 0 0 0 2.354 2.353Z"
      clipRule="evenodd"
    />
    <path
      fill="#FABE00"
      fillRule="evenodd"
      d="M4.728 11.296c0-.604.228-1.209.687-1.668a2.36 2.36 0 0 1 3.329 0l4.987 4.989a2.361 2.361 0 0 1 0 3.329 2.36 2.36 0 0 1-3.329 0l-.966-.966v8.227a2.36 2.36 0 0 1-2.354 2.353 2.36 2.36 0 0 1-2.354-2.353V11.296Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      d="M108.91 8.925c-2.59 0-4.929.791-6.439 2.194-.648.612-1.008 1.26-1.008 1.943 0 1.08.9 1.978 2.087 1.978.467 0 .863-.144 1.439-.575 1.043-.9 2.158-1.331 3.417-1.331 2.158 0 3.345 1.15 3.345 3.13v.791h-4.46c-4.173 0-6.727 1.979-6.727 5.18 0 3.166 2.482 5.325 6.151 5.325 2.123 0 3.921-.684 5.216-1.907.216 1.08 1.151 1.871 2.339 1.871 1.331 0 2.338-1.007 2.338-2.41V16.55c0-4.856-2.806-7.626-7.698-7.626Zm-.9 15.073c-1.582 0-2.554-.755-2.554-1.942 0-1.187.864-1.8 2.482-1.8h3.813v.217c0 2.015-1.618 3.525-3.741 3.525Z"
    />
    <path
      fill="#fff"
      d="M108.91 8.925c-2.59 0-4.929.791-6.439 2.194-.648.612-1.008 1.26-1.008 1.943 0 1.08.9 1.978 2.087 1.978.467 0 .863-.144 1.439-.575 1.043-.9 2.158-1.331 3.417-1.331 2.158 0 3.345 1.15 3.345 3.13v.791h-4.46c-4.173 0-6.727 1.979-6.727 5.18 0 3.166 2.482 5.325 6.151 5.325 2.123 0 3.921-.684 5.216-1.907.216 1.08 1.151 1.871 2.339 1.871 1.331 0 2.338-1.007 2.338-2.41V16.55c0-4.856-2.806-7.626-7.698-7.626Zm-.9 15.073c-1.582 0-2.554-.755-2.554-1.942 0-1.187.864-1.8 2.482-1.8h3.813v.217c0 2.015-1.618 3.525-3.741 3.525Z"
    />
    <path
      fill="#5A5A6B"
      d="M108.91 8.925c-2.59 0-4.929.791-6.439 2.194-.648.612-1.008 1.26-1.008 1.943 0 1.08.9 1.978 2.087 1.978.467 0 .863-.144 1.439-.575 1.043-.9 2.158-1.331 3.417-1.331 2.158 0 3.345 1.15 3.345 3.13v.791h-4.46c-4.173 0-6.727 1.979-6.727 5.18 0 3.166 2.482 5.325 6.151 5.325 2.123 0 3.921-.684 5.216-1.907.216 1.08 1.151 1.871 2.339 1.871 1.331 0 2.338-1.007 2.338-2.41V16.55c0-4.856-2.806-7.626-7.698-7.626Zm-.9 15.073c-1.582 0-2.554-.755-2.554-1.942 0-1.187.864-1.8 2.482-1.8h3.813v.217c0 2.015-1.618 3.525-3.741 3.525Z"
    />
    <path
      fill="#fff"
      d="M31.999 3.807c-1.403 0-2.446 1.08-2.446 2.482v18.789c0 1.403 1.043 2.482 2.446 2.482 1.367 0 2.41-1.043 2.41-2.482V6.289c0-1.439-1.007-2.482-2.41-2.482Z"
    />
    <path
      fill="#fff"
      d="M31.999 3.807c-1.403 0-2.446 1.08-2.446 2.482v18.789c0 1.403 1.043 2.482 2.446 2.482 1.367 0 2.41-1.043 2.41-2.482V6.289c0-1.439-1.007-2.482-2.41-2.482Z"
    />
    <path
      fill="#5A5A6B"
      d="M31.999 3.807c-1.403 0-2.446 1.08-2.446 2.482v18.789c0 1.403 1.043 2.482 2.446 2.482 1.367 0 2.41-1.043 2.41-2.482V6.289c0-1.439-1.007-2.482-2.41-2.482Z"
    />
    <path
      fill="#fff"
      d="m45.453 23.819-5.108-6.404 4.532-4.605c.54-.54.792-1.115.792-1.727 0-1.223-.971-2.158-2.23-2.158a2.3 2.3 0 0 0-1.691.755l-5.684 5.936c-.611.684-.863 1.188-.863 1.8 0 .539.216 1.042.755 1.762l6.08 7.483c.467.611 1.043.9 1.798.9 1.224 0 2.195-.972 2.195-2.232 0-.539-.18-1.007-.576-1.51Z"
    />
    <path
      fill="#fff"
      d="m45.453 23.819-5.108-6.404 4.532-4.605c.54-.54.792-1.115.792-1.727 0-1.223-.971-2.158-2.23-2.158a2.3 2.3 0 0 0-1.691.755l-5.684 5.936c-.611.684-.863 1.188-.863 1.8 0 .539.216 1.042.755 1.762l6.08 7.483c.467.611 1.043.9 1.798.9 1.224 0 2.195-.972 2.195-2.232 0-.539-.18-1.007-.576-1.51Z"
    />
    <path
      fill="#5A5A6B"
      d="m45.453 23.819-5.108-6.404 4.532-4.605c.54-.54.792-1.115.792-1.727 0-1.223-.971-2.158-2.23-2.158a2.3 2.3 0 0 0-1.691.755l-5.684 5.936c-.611.684-.863 1.188-.863 1.8 0 .539.216 1.042.755 1.762l6.08 7.483c.467.611 1.043.9 1.798.9 1.224 0 2.195-.972 2.195-2.232 0-.539-.18-1.007-.576-1.51Z"
    />
    <path
      fill="#fff"
      d="M56.569 8.925c-2.59 0-4.928.791-6.44 2.194-.647.612-1.006 1.26-1.006 1.943 0 1.08.899 1.978 2.086 1.978.467 0 .863-.144 1.439-.575 1.043-.9 2.158-1.331 3.417-1.331 2.159 0 3.346 1.15 3.346 3.13v.791h-4.46c-4.174 0-6.728 1.979-6.728 5.18 0 3.166 2.482 5.325 6.151 5.325 2.123 0 3.922-.684 5.217-1.907.215 1.08 1.15 1.871 2.338 1.871 1.33 0 2.338-1.007 2.338-2.41V16.55c0-4.856-2.806-7.626-7.698-7.626Zm-.9 15.073c-1.582 0-2.553-.755-2.553-1.942 0-1.187.863-1.8 2.482-1.8h3.813v.217c0 2.015-1.62 3.525-3.742 3.525Z"
    />
    <path
      fill="#fff"
      d="M56.569 8.925c-2.59 0-4.928.791-6.44 2.194-.647.612-1.006 1.26-1.006 1.943 0 1.08.899 1.978 2.086 1.978.467 0 .863-.144 1.439-.575 1.043-.9 2.158-1.331 3.417-1.331 2.159 0 3.346 1.15 3.346 3.13v.791h-4.46c-4.174 0-6.728 1.979-6.728 5.18 0 3.166 2.482 5.325 6.151 5.325 2.123 0 3.922-.684 5.217-1.907.215 1.08 1.15 1.871 2.338 1.871 1.33 0 2.338-1.007 2.338-2.41V16.55c0-4.856-2.806-7.626-7.698-7.626Zm-.9 15.073c-1.582 0-2.553-.755-2.553-1.942 0-1.187.863-1.8 2.482-1.8h3.813v.217c0 2.015-1.62 3.525-3.742 3.525Z"
    />
    <path
      fill="#5A5A6B"
      d="M56.569 8.925c-2.59 0-4.928.791-6.44 2.194-.647.612-1.006 1.26-1.006 1.943 0 1.08.899 1.978 2.086 1.978.467 0 .863-.144 1.439-.575 1.043-.9 2.158-1.331 3.417-1.331 2.159 0 3.346 1.15 3.346 3.13v.791h-4.46c-4.174 0-6.728 1.979-6.728 5.18 0 3.166 2.482 5.325 6.151 5.325 2.123 0 3.922-.684 5.217-1.907.215 1.08 1.15 1.871 2.338 1.871 1.33 0 2.338-1.007 2.338-2.41V16.55c0-4.856-2.806-7.626-7.698-7.626Zm-.9 15.073c-1.582 0-2.553-.755-2.553-1.942 0-1.187.863-1.8 2.482-1.8h3.813v.217c0 2.015-1.62 3.525-3.742 3.525Z"
    />
    <path
      fill="#fff"
      d="M74.7 27.56c3.885 0 6.942-2.015 6.942-5.684 0-2.77-1.798-4.137-5-5.433l-1.727-.719c-1.295-.504-1.87-.863-1.87-1.583 0-.863.755-1.259 1.906-1.259 1.187 0 2.303.432 3.238 1.08.432.287.863.503 1.295.503 1.079 0 1.978-.9 1.978-2.015 0-.647-.288-1.223-.863-1.655-1.439-1.223-3.49-1.906-5.612-1.906-3.777 0-6.547 1.942-6.547 5.36 0 2.806 1.835 4.21 4.856 5.432l1.907.792c1.08.468 1.834.792 1.834 1.619 0 1.007-1.043 1.475-2.266 1.475-1.547 0-2.841-.684-3.885-1.583-.503-.396-.935-.54-1.403-.54-1.115 0-2.014.936-2.014 2.051 0 .576.216 1.115.683 1.511 1.583 1.547 3.885 2.554 6.547 2.554Z"
    />
    <path
      fill="#fff"
      d="M74.7 27.56c3.885 0 6.942-2.015 6.942-5.684 0-2.77-1.798-4.137-5-5.433l-1.727-.719c-1.295-.504-1.87-.863-1.87-1.583 0-.863.755-1.259 1.906-1.259 1.187 0 2.303.432 3.238 1.08.432.287.863.503 1.295.503 1.079 0 1.978-.9 1.978-2.015 0-.647-.288-1.223-.863-1.655-1.439-1.223-3.49-1.906-5.612-1.906-3.777 0-6.547 1.942-6.547 5.36 0 2.806 1.835 4.21 4.856 5.432l1.907.792c1.08.468 1.834.792 1.834 1.619 0 1.007-1.043 1.475-2.266 1.475-1.547 0-2.841-.684-3.885-1.583-.503-.396-.935-.54-1.403-.54-1.115 0-2.014.936-2.014 2.051 0 .576.216 1.115.683 1.511 1.583 1.547 3.885 2.554 6.547 2.554Z"
    />
    <path
      fill="#5A5A6B"
      d="M74.7 27.56c3.885 0 6.942-2.015 6.942-5.684 0-2.77-1.798-4.137-5-5.433l-1.727-.719c-1.295-.504-1.87-.863-1.87-1.583 0-.863.755-1.259 1.906-1.259 1.187 0 2.303.432 3.238 1.08.432.287.863.503 1.295.503 1.079 0 1.978-.9 1.978-2.015 0-.647-.288-1.223-.863-1.655-1.439-1.223-3.49-1.906-5.612-1.906-3.777 0-6.547 1.942-6.547 5.36 0 2.806 1.835 4.21 4.856 5.432l1.907.792c1.08.468 1.834.792 1.834 1.619 0 1.007-1.043 1.475-2.266 1.475-1.547 0-2.841-.684-3.885-1.583-.503-.396-.935-.54-1.403-.54-1.115 0-2.014.936-2.014 2.051 0 .576.216 1.115.683 1.511 1.583 1.547 3.885 2.554 6.547 2.554Z"
    />
    <path
      fill="#fff"
      d="M91.067 27.56c3.885 0 6.943-2.015 6.943-5.684 0-2.77-1.799-4.137-5-5.433l-1.727-.719c-1.295-.504-1.87-.863-1.87-1.583 0-.863.755-1.259 1.906-1.259 1.187 0 2.302.432 3.237 1.08.432.287.864.503 1.295.503 1.08 0 1.979-.9 1.979-2.015 0-.647-.288-1.223-.864-1.655-1.438-1.223-3.489-1.906-5.611-1.906-3.778 0-6.547 1.942-6.547 5.36 0 2.806 1.834 4.21 4.856 5.432l1.906.792c1.08.468 1.835.792 1.835 1.619 0 1.007-1.043 1.475-2.266 1.475-1.547 0-2.842-.684-3.885-1.583-.504-.396-.936-.54-1.403-.54-1.115 0-2.015.936-2.015 2.051 0 .576.216 1.115.684 1.511 1.583 1.547 3.885 2.554 6.547 2.554Z"
    />
    <path
      fill="#fff"
      d="M91.067 27.56c3.885 0 6.943-2.015 6.943-5.684 0-2.77-1.799-4.137-5-5.433l-1.727-.719c-1.295-.504-1.87-.863-1.87-1.583 0-.863.755-1.259 1.906-1.259 1.187 0 2.302.432 3.237 1.08.432.287.864.503 1.295.503 1.08 0 1.979-.9 1.979-2.015 0-.647-.288-1.223-.864-1.655-1.438-1.223-3.489-1.906-5.611-1.906-3.778 0-6.547 1.942-6.547 5.36 0 2.806 1.834 4.21 4.856 5.432l1.906.792c1.08.468 1.835.792 1.835 1.619 0 1.007-1.043 1.475-2.266 1.475-1.547 0-2.842-.684-3.885-1.583-.504-.396-.936-.54-1.403-.54-1.115 0-2.015.936-2.015 2.051 0 .576.216 1.115.684 1.511 1.583 1.547 3.885 2.554 6.547 2.554Z"
    />
    <path
      fill="#5A5A6B"
      d="M91.067 27.56c3.885 0 6.943-2.015 6.943-5.684 0-2.77-1.799-4.137-5-5.433l-1.727-.719c-1.295-.504-1.87-.863-1.87-1.583 0-.863.755-1.259 1.906-1.259 1.187 0 2.302.432 3.237 1.08.432.287.864.503 1.295.503 1.08 0 1.979-.9 1.979-2.015 0-.647-.288-1.223-.864-1.655-1.438-1.223-3.489-1.906-5.611-1.906-3.778 0-6.547 1.942-6.547 5.36 0 2.806 1.834 4.21 4.856 5.432l1.906.792c1.08.468 1.835.792 1.835 1.619 0 1.007-1.043 1.475-2.266 1.475-1.547 0-2.842-.684-3.885-1.583-.504-.396-.936-.54-1.403-.54-1.115 0-2.015.936-2.015 2.051 0 .576.216 1.115.684 1.511 1.583 1.547 3.885 2.554 6.547 2.554Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M23.239 27.56a2.36 2.36 0 0 0 2.354-2.353V11.296c0-.604-.229-1.209-.687-1.668a2.36 2.36 0 0 0-3.33 0l-4.987 4.989a2.361 2.361 0 0 0 0 3.329 2.36 2.36 0 0 0 3.33 0l.965-.966v8.227a2.36 2.36 0 0 0 2.355 2.353Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M23.239 27.56a2.36 2.36 0 0 0 2.354-2.353V11.296c0-.604-.229-1.209-.687-1.668a2.36 2.36 0 0 0-3.33 0l-4.987 4.989a2.361 2.361 0 0 0 0 3.329 2.36 2.36 0 0 0 3.33 0l.965-.966v8.227a2.36 2.36 0 0 0 2.355 2.353Z"
      clipRule="evenodd"
    />
    <path
      fill="#5A5A6B"
      fillRule="evenodd"
      d="M23.239 27.56a2.36 2.36 0 0 0 2.354-2.353V11.296c0-.604-.229-1.209-.687-1.668a2.36 2.36 0 0 0-3.33 0l-4.987 4.989a2.361 2.361 0 0 0 0 3.329 2.36 2.36 0 0 0 3.33 0l.965-.966v8.227a2.36 2.36 0 0 0 2.355 2.353Z"
      clipRule="evenodd"
    />
    <path
      fill="#CBCBCB"
      fillRule="evenodd"
      d="M4.728 11.296c0-.604.228-1.209.686-1.667a2.36 2.36 0 0 1 3.33 0l4.987 4.988a2.36 2.36 0 0 1 0 3.329 2.36 2.36 0 0 1-3.33 0l-.965-.966v8.227a2.36 2.36 0 0 1-2.354 2.354 2.36 2.36 0 0 1-2.354-2.354v-13.91Z"
      clipRule="evenodd"
    />
  </svg>
);
export default MbankIconFull;
