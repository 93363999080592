import { Empty } from 'antd'
import clsx from 'clsx'

import { useEffect, useRef, useState } from 'react'

import NotificationItem from './NotificationItem'
import styles from './styles.module.scss'
import { Notification } from 'services/api/Notifications/NotificationApi.model'
import { NotificationsApi } from 'services/api/Notifications/NotificationsApi'
import useClickOutside from 'hooks/useClickOutside'
import AppIcon from 'common/AppIcon'

interface Props {
    className?: string
}

const LOADING_INTERVAL = 10 * 1000

const Notifications = (props: Props) => {
    const { className } = props

    const [notifications, setNotifications] = useState<Notification[]>([])
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [deletingId, setDeletingId] = useState<number | null>(null)
    const ref = useRef<HTMLDivElement>(null)
    const timerRef = useRef<NodeJS.Timeout | null>(null)
    const hasUnreadNotifications =
        notifications.length && notifications[0].is_read === false

    useClickOutside(ref, () => setIsOpen(false))

    useEffect(() => {
        fetchNotifications()
        timerRef.current = setInterval(
            () => fetchNotifications(),
            LOADING_INTERVAL,
        )
        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current)
            }
        }
    }, [])

    const fetchNotifications = async () => {
        try {
            const res = await NotificationsApi.getNotifications()
            setNotifications(res.data.results)
        } catch (err) {
            console.error('Failed to fetch notifications', err)
        }
    }

    const showNotifications = () => {
        setIsOpen(true)
    }

    const markAsReadById = async (id: number) => {
        try {
            await NotificationsApi.markAsReadById(id)
            setDeletingId(id);
            setTimeout(() => {
                setNotifications(prev =>
                    prev.filter(notification => notification.id !== id),
                )
                setDeletingId(null);
              }, 500); 
        } catch (err) {
            console.error('Failed to mark notifications as read', err)
        }
    }

    return (
        <div className={clsx(styles.wrapper, className)} ref={ref}>
            <div
                className={clsx(
                    styles.icon,
                    hasUnreadNotifications && styles.active,
                )}
                onClick={showNotifications}
            >
                <AppIcon id="notifications" width={16} height={16} />
            </div>
            <div
                className={clsx(styles.notifications, isOpen && styles.active)}
            >
                <div className={styles.header}>
                    <h3 className={styles.title}>Уведомление</h3>
                    <div
                        className={styles.closeIcon}
                        onClick={() => setIsOpen(false)}
                    >
                        <AppIcon id="close" width={16} height={16} />
                    </div>
                </div>
                {notifications.length ? (
                    <div className={styles.notificationsList}>
                        {notifications.map(notification => (
                            <NotificationItem
                                key={notification.id}
                                title={notification.title}
                                text={notification.message}
                                date={notification.created_at}
                                markAsRead={() =>
                                    markAsReadById(notification.id)
                                }
                                className={clsx(styles.notificationItem, deletingId === notification.id ? styles.removing : '')}
                            />
                        ))}
                    </div>
                ) : (
                    <Empty
                        description="Уведомлений нет"
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                )}
            </div>
        </div>
    )
}

export default Notifications
