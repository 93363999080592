import React from 'react'
import { useParams } from 'react-router'
import { useNavigate, useResolvedPath, useSearchParams } from 'react-router-dom'

import { useTableData } from 'hooks'

import { AppTable } from 'components'

import { displayDate } from 'utils'

import { ShiftsApi } from 'services/api/ShiftsApi'
import { ShiftsItem } from 'services/api/ShiftsApi.module'
import SortTitle from 'components/Sort/SortTitle'
import ShiftFilter from 'shared/common/shift/shift-filter'
import { CashierItem } from 'services/api/CashierApi.models'

interface Props {
	id?: string
}

const tableColumns = [
	{
		dataIndex: 'id',
		title: '№ Смены',
	},
	{
		dataIndex: 'cashier_name',
		title: 'Кассир',
	},
	{
		dataIndex: 'is_open',
		title: 'Открыт',
		render: (value: boolean) => (value ? 'Да' : 'Нет'),
	},
	{
		dataIndex: 'started_at',
		title: 'Открытие смены',
		render: (value: string) => displayDate(value),
	},
	{
		dataIndex: 'end_at',
		title: 'Закрытие смены',
		render: (value: string) => displayDate(value),
	},
	{
		dataIndex: 'transaction_sum',
		title: () => (
			<>
				<SortTitle
					paramKey='order_by'
					value='transaction_sum'
					title='Сумма'
				/>
			</>
		),
	},

	{
		dataIndex: 'transaction_count',
		title: () => (
			<>
				<SortTitle
					paramKey='order_by'
					value='transaction_count'
					title='Количество успешных транзакций'
				/>
			</>
		),
	},
]

const Shifts: React.FC<Props> = () => {
	const [searchParams] = useSearchParams()
	const gteDate = searchParams.get('start_gte')
	const lteDate = searchParams.get('start_lte')
	const order_by = searchParams.get('order_by')
	const { cashierId } = useParams()
	const navigate = useNavigate()
	const pathname = useResolvedPath('').pathname

	const { loading, tableData, handleTableChange, pagination } = useTableData(
		ShiftsApi.getShifts as any,
		{
			cashier: cashierId,
			order_by,
			start_lte: lteDate,
			start_gte: gteDate,
		}
	)

	const showDetail = (
		record: ShiftsItem,
		e: React.MouseEvent<any, MouseEvent>
	) => {
		if (e.metaKey || e.ctrlKey) {
			window.open(
				`/merchant-user/dashboard/shifts/${record.id}/transactions/?search_by_sum=`
			)

			return
		}
		navigate(
			`/merchant-user/dashboard/shifts/${record.id}/transactions/?search_by_sum=`
		)
	}

	return (
		<section>
			<ShiftFilter />
			<div>
				<AppTable
					columns={tableColumns}
					data={tableData?.results}
					pagination={pagination}
					onChange={handleTableChange}
					loading={loading}
					onRow={(record: any) => {
						return {
							onClick: e => {
								showDetail(record, e)
							},
						}
					}}
				/>
			</div>
		</section>
	)
}

export default Shifts
