import { MKASSA_ARM_TOKEN } from './constants'
import { Props } from './index'

export const setLocalStorage = ({ key = MKASSA_ARM_TOKEN, value }: Props) => {
	localStorage.setItem(key, value)
}

export const getLocalStorage = (key = MKASSA_ARM_TOKEN) => {
	return JSON.parse(JSON.stringify(localStorage.getItem(key)))
}

export const deleteLocalStorage = (key = MKASSA_ARM_TOKEN) => {
	localStorage.removeItem(key)
}
