import clsx from "clsx";
import React from "react";

import styles from "./AppFilterDate.module.scss";

interface Props {
    filter: {
        name: string;
        key: string;
        param?: string;
    }[];
    current?: string;
    onChange?: (item: any) => void;
    disabled?: boolean
}

const AppFilterDate: React.FC<Props> = ({filter, current, onChange, disabled}) => {

    return (
        <div className={styles.container}>
            {filter.map((item, index) => (
                <div
                    onClick={() => !disabled && onChange && onChange(item)}
                    className={clsx(styles.item, current === item.key && styles.active, disabled && styles.disabled)}
                    key={item.key}
                >
                    {item.name}
                </div>
            ))}
        </div>
    );
};

export default AppFilterDate;
