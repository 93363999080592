import React, { useCallback } from "react";

import { clsx } from "clsx";

import { SIZE_BUTTON } from "constants/ComponentSize";

import styles from "./AppButton.module.scss";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "yellow" | "lightBlue" | "black" | "siren" | "white" | "outline";
  size?: SIZE_BUTTON;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  color?: string;
}

export const useButtonClick = (type: string, onClick: () => void) =>
  useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      if (type === "submit") {
        return;
      }

      const { target } = event;

      // @ts-ignore
      target.parentElement.setAttribute("disabled", "");

      if (onClick) {
        // @ts-ignore
        await onClick(event);
      }
      // @ts-ignore
      target.parentElement.removeAttribute("disabled");
    },
    [type, onClick]
  );

const AppButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      variant = "siren",
      size = SIZE_BUTTON.SMALL,
      iconLeft,
      iconRight,
      color,
      children,
      className,
      type,
      onClick,
      ...props
    },
    ref
  ) => {
    const variantColor = {
      yellow: styles.yellow,
      lightBlue: styles.lightBlue,
      black: styles.black,
      siren: styles.siren,
      white: styles.white,
      outline: styles.outline,
    };

    const varianSize = {
      small: styles.small,
      normal: styles.normal,
    };

    // @ts-ignore
    const onButtonClick = useButtonClick(type, onClick);

    return (
      <button
        {...props}
        className={clsx(
          styles.button,
          variantColor[variant],
          varianSize[size],
          className
        )}
        style={{ backgroundColor: color }}
        ref={ref}
        onClick={onButtonClick}
        type={type}
      >
        {iconLeft ? <span className={styles.leftIcon}>{iconLeft}</span> : null}
        {children}
        {iconRight ? (
          <span className={styles.rightIcon}>{iconRight}</span>
        ) : null}
      </button>
    );
  }
);

AppButton.displayName = "AppButton";

export default AppButton;
