import { User } from 'models/user.models'
import { clientApi } from './clientApi'
import { AuthResponse } from 'models/auth.model'

interface AuthUser {
	device_uuid: string
	login: string
	password: string
}

export const AuthApi = {
	authUser: async (data: AuthUser) => {
		return clientApi.post<AuthResponse>('users/login/', data)
	},
	getUser: () => {
		return clientApi.get<User>('crm/user/me/')
	},
	getUserHeaders: (token: string) => {
		return clientApi.get('users/me/', {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
	},
	ARMauth: async (data: AuthUser) => {
		return clientApi.post<AuthResponse>('arm/login/', data)
	},
}
