import { Routes, Route, Navigate, HashRouter } from 'react-router-dom'

import MerchantDashboardRoutes from 'pages/MerchantDashboard/routes'
import { Auth, CashierDashboard } from 'pages'
import { ARM_ROUTES } from './routes'

import { observer } from 'mobx-react-lite'

const HashRouting = observer(() => {
	return (
		<HashRouter>
			<Routes>
				<Route element={<Auth />} path={`${ARM_ROUTES.auth}/*`} />

				<Route
					element={<MerchantDashboardRoutes />}
					path={`${ARM_ROUTES.merchant_dashboard}/*`}
				/>

				<Route
					element={<CashierDashboard />}
					path={`${ARM_ROUTES.cashier_dashboard}/*`}
				/>

				<Route path='*' element={<Navigate to={ARM_ROUTES.auth} replace />} />
			</Routes>
		</HashRouter>
	)
})

export default HashRouting
