import React from 'react'

import { useParams } from 'react-router'

import { useTableData } from 'hooks'

import { CashierRegisterApi } from 'services/api/CashRegisterApi'

import { AppTable } from 'components'

import styles from './cash-register.module.scss'
import { Empty } from 'antd'

const tableColumns = [
	{
		dataIndex: 'id',
		title: '№',
	},
	{
		dataIndex: 'name',
		title: 'Наименование',
	},
	{
		dataIndex: 'registration_number',
		title: 'РН ККМ (при наличии)',
	},
	{
		dataIndex: 'fiscal_number',
		title: 'ФМ (при наличии)',
	},
	{
		dataIndex: 'created_date',
		title: 'Дата регистрации',
	},
	{
		dataIndex: 'deregistration_date',
		title: 'Дата де-регистрации',
	},
	{
		dataIndex: 'fn_expires_at',
		title: 'Дата истечения',
	},
	{
		dataIndex: 'tax_system',
		title: 'Налоговый режим',
	},
	{
		dataIndex: 'vat_payer',
		title: 'Плательщик НДС',
		render: (value: boolean) => (value ? 'Да' : 'Нет'),
	},
	{
		dataIndex: 'has_product',
		title: 'Товар',
		render: (value: boolean) => (value ? 'Да' : 'Нет'),
	},
	{
		dataIndex: 'has_service',
		title: 'Услуги или работы',
		render: (value: boolean) => (value ? 'Да' : 'Нет'),
	},
	{
		dataIndex: 'has_prepayment',
		title: 'Авансовый платеж',
		render: (value: boolean) => (value ? 'Да' : 'Нет'),
	},
	{
		dataIndex: 'has_post_payment',
		title: 'Кредитный платеж',
		render: (value: boolean) => (value ? 'Да' : 'Нет'),
	},
	{
		dataIndex: 'entrepreneurship_object',
		title: 'Тип объекта предпринимательства',
	},
	{
		dataIndex: 'business_activity',
		title: 'Вид бизнес деятельности',
	},
	{
		dataIndex: 'tax_authority_department',
		title: 'УГНС',
	},
	{
		dataIndex: 'postal_code',
		title: 'Почтовый индекс',
	},
	{
		dataIndex: 'closed_shifts_counter',
		title: 'Кол-во закрытых смен',
	},
	{
		dataIndex: 'is_shift_open',
		title: 'Смена открыта',
		render: (value: boolean) => (value ? 'Да' : 'Нет'),
	},
	{
		dataIndex: 'transactions_counter',
		title: 'Кол-во транзакций',
	},
]

interface CashRegister {
	branchId: number
	kkmExists?: boolean | undefined
}

const CashRegister: React.FC<CashRegister> = props => {
	const { branchId, kkmExists } = props

	const {
		loading,
		tableData,
		handleTableChange,
		pagination,
		// @ts-ignore
	} = useTableData(CashierRegisterApi.getCashRegister, {
		branch: branchId,
	})

	return (
		<div className={styles.wrapper}>
			<div className={styles.table}>
				<AppTable
					columns={tableColumns}
					data={tableData?.results}
					pagination={pagination}
					onChange={handleTableChange}
					loading={loading}
					// @ts-ignore
					scroll={{ x: 1300 }}
					locale={kkmExists !== undefined && !kkmExists && {emptyText: <Empty description={"ККМ не подключен!"} />}}
				/>
			</div>
		</div>
	)
}

export default CashRegister
