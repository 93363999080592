import clsx from 'clsx'
import AppInput from 'components/AppInput'
import AppRadio from 'components/AppRadio'
import { set } from 'date-fns'
import { CashoutBranch } from 'services/api/Cashout/types'

import { UseFormSetValue } from 'react-hook-form'

import s from './style.module.scss'

interface Form extends CashoutBranch {
	commissionType: 'PROCENT' | 'MONEY'
}

interface Props {
	commissionType: 'PROCENT' | 'MONEY'
	register: any
	setValue: UseFormSetValue<any>
	className?: string
	disbaled?: boolean
}

const CashoutCommisssionEdit = ({
	commissionType,
	setValue,
	register,
	className = '',

	
	disbaled,
}: Props) => {
	const isProcent = commissionType === 'PROCENT'
	const isMoney = commissionType === 'MONEY'

	const onChangeRadio = (type: Form['commissionType']) => {
		setValue('commissionType', type)

		if (type === 'MONEY') {
			setValue('cash_out_commission_percent', null)
		}
		if (type === 'PROCENT') {
			setValue('cash_out_commission_int', null)
		}
	}

	return (
		<div className={clsx(s.container, className)}>
			<h4>Комиссия за выдачу наличных QR MBANK</h4>

			<div className={s.list}>
				<div className={clsx(s.inputItem, !isMoney && s.disabled)}>
					<div className={s.label} onClick={() => onChangeRadio('MONEY')}>
						<AppRadio
							disabled={disbaled}
							label='В сомах'
							checked={isMoney}
							value={commissionType}
							onChange={() => onChangeRadio('MONEY')}
						/>
					</div>

					<AppInput
						{...register('cash_out_commission_int', {
							required: isMoney ? 'Обязательное поле' : false,
						})}
						placeholder='C'
						disabled={isProcent || disbaled}
					/>
				</div>
				<div className={clsx(s.inputItem, !isProcent && s.disabled)}>
					<AppRadio
						disabled={disbaled}
						label='В
                        процентах'
						checked={isProcent}
						value={commissionType}
						onChange={() => onChangeRadio('PROCENT')}
					/>
					<AppInput
						{...register('cash_out_commission_percent', {
							required: isProcent ? 'Обязательное поле' : false,
						})}
						placeholder='%'
						disabled={isMoney || disbaled}
					/>
				</div>
			</div>
		</div>
	)
}

export default CashoutCommisssionEdit
