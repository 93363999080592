import React from 'react'

import { observer } from 'mobx-react-lite'

import { store } from 'store'

import { BranchDetail as BranchDetailInterface } from 'services/api/BranchesApi.models'

import { Route, Routes } from 'react-router-dom'
import View from './components/View'
import Edit from './components/Edit'

interface DetailProps {
	data: BranchDetailInterface
	getBranch: () => void
}

const Detail: React.FC<DetailProps> = observer(({ data, getBranch }) => {
	const { user } = store

	const isEditable = !user.actions.readOnly
	return (
		<Routes>
			{isEditable && (
				<Route
					path='/edit/'
					element={<Edit data={data} getBranch={getBranch} />}
				/>
			)}

			<Route path='/' element={<View data={data} />} />
		</Routes>
	)
})

export default Detail
