interface Props {
  page: number;
  limit: number;
}

export const getOffset = (page: number, limit: number) => {
 // Минус 1 т.к. пагинация по умолчанию начинается с 1
  if (!(page - 1)) return 0;

  return  (page - 1) * limit;
};

interface PropsPage {
  count: number;
  limit: number;
}

export const getCountPage = ({ count, limit }: PropsPage) => {
  return Math.ceil(count / limit);
};