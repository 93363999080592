import React, {useEffect, useState} from 'react';

import {Select, Spin} from 'antd';
import type {SelectProps} from 'antd/es/select';
import {get, uniqueId} from 'lodash';
import clsx from "clsx";

import {useDebounce} from "utils";

import styles from './SelectField.module.scss';

const {Option} = Select;

interface OptionProps {
    id: string | number;
    name: string | number;
}

export interface DebounceSelectProps<ValueType = any>
    extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
    fetchOptions: (search: string, params: any) => Promise<any>;
    debounceTimeout?: number;
    optionLabelKey?: string;
    placeholder?: string;
    className?: string;
    disabled?: boolean;
}

export function DebounceSelect<ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any,
    >(
        {
            fetchOptions,
            debounceTimeout = 800,
            optionLabelKey = 'name',
            placeholder,
            value,
            disabled,
            className,
            ...restProps
        }: DebounceSelectProps<ValueType>) {

    const [fetching, setFetching] = useState(true);
    const [options, setOptions] = useState<OptionProps[]>([]);
    const [search, setSearch] = useState('');

    const debouncedSearch = useDebounce(search);

    const [tableParams, setTableParams] = useState({
        page: 0,
        limit: 20,
        hasNext: false
    });

    const onSearch = (val: string) => {
        setSearch(val);
        setOptions([]);
        setTableParams({
            page: 0,
            limit: 20,
            hasNext: false
        })
    }

    const loadOptions = () => {
        setFetching(true);

        fetchOptions(debouncedSearch as string,
            {
                offset: (tableParams.page > 0 ? (tableParams.page * tableParams.limit) : tableParams.page),
                limit: tableParams.limit
            }
            ).then((res) => {

            setTableParams({
                ...tableParams,
                page: !!res.data.next ? tableParams.page + 1 : tableParams.page,
                hasNext: !!res.data.next
            });

            if (Array.isArray(res.data)) {
                setOptions(res.data);

            }

            if (Array.isArray(res.data.results)) {
                setOptions(res.data.results);

            }

            setFetching(false);
        });
    };

    useEffect(() => {
        loadOptions();
    }, [debouncedSearch]);

    return (
        <Select
            {...restProps}
            className={clsx([styles.wrapper, className, disabled && styles.disabled])}
            allowClear
            onSearch={onSearch}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            loading={fetching}
            value={value}
            dropdownRender={(menu) => (
                <Spin spinning={fetching && !!options.length}>

                    {menu}

                    {tableParams.hasNext && (
                        <div className={styles.loadMore}>
                            <button onClick={loadOptions}>+ Загрузить еще
                            </button>
                        </div>
                    )}

                </Spin>
            )}
            placeholder={placeholder}
            disabled={disabled}
        >
            {options?.map((option: OptionProps) => (
                <Option
                    key={uniqueId('optionKey')}
                    value={option?.id}
                    label={get(option, optionLabelKey, '-')}
                >
                    {get(option, optionLabelKey, '-')}
                </Option>
            ))}

        </Select>
    );
}