import { AppInput } from "components";
import { SearchIcon } from "components/icon";
import { DEFAULT_DEBOUNCE_DELAY } from "constants/common";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { setTransactionId } from "store/rtk/slices/filters/transactions-filter.slice";
import { useDebouncedCallback } from "use-debounce";

interface Props {
  className?: string;
  disabled?: boolean;
}

const SearchById = (props: Props) => {
  const { disabled, className } = props;
  const id = useAppSelector((store) => store.transactionsFilters.transactionId);
  const dispatch = useAppDispatch();

  const onChangeById = useDebouncedCallback((value: string) => {
    dispatch(setTransactionId(value));
  }, DEFAULT_DEBOUNCE_DELAY);

  return (
    <AppInput
      className={className}
      placeholder="Поиск по id"
      icon={<SearchIcon />}
      onChange={(e) => onChangeById(e.currentTarget.value)}
      disabled={disabled}
      defaultValue={id ?? undefined}
    />
  );
};

export default SearchById;
