import { ReportStatusEnum } from 'services/api/Reports/ReportsApi.model'
import styles from "./styles.module.scss"
import clsx from 'clsx'

interface Props {
    status: ReportStatusEnum
}

const STATUS_LABELS = {
    [ReportStatusEnum.PROCESSING]: 'В обработке',
    [ReportStatusEnum.ERROR]: 'Ошибка',
    [ReportStatusEnum.COMPLETED]: 'Завершено',
}

const ReportStatus = (props: Props) => {
    const { status } = props
    return <div className={styles.status}>
        <div className={clsx(styles.indicator, styles[status])}/>
        <div>{STATUS_LABELS[status]}</div>
    </div>
}

export default ReportStatus
