import React, { useMemo } from 'react'
import {
	Navigate,
	NavLink,
	Route,
	Routes,
	useResolvedPath,
} from 'react-router-dom'
import { useParams } from 'react-router'
import { observer } from 'mobx-react-lite'
import { AppBreadСrumbs } from 'components'

import { store } from 'store'

import { AppTabs, LoadingArea } from 'components'

import Header from './common/Header'

import Detail from './components/Detail'
import Shifts from './components/Shifts'

import styles from './cashier..module.scss'
import Transactions from '../../Transactions'
import MERCHANT_DASHBOARD_BREADCRUBMS from 'pages/MerchantDashboard/merchBreadCrumbs'
import Cashout from "../../../../Cashout";

const CashierDetail = observer(() => {
	const { cashierId, branchId } = useParams()
	const pathname = useResolvedPath('').pathname

	const { cashierStore, branchStore } = store

	React.useEffect(() => {
		cashierStore.getAsyncDetail(cashierId)

		return () => cashierStore.setDetail(null)
	}, [cashierId])

	React.useEffect(() => {
		if (branchId) {
			branchStore.getAsyncDetail(branchId)
		}
	}, [branchId])

	const TabOptions = useMemo(
		() => [
			{
				label: <NavLink to={`${pathname}detail/`}>Данные кассира</NavLink>,
				key: `${pathname}detail/`,
			},
			{
				label: <NavLink to={`${pathname}transactions/`}>Транзакции</NavLink>,
				key: `${pathname}transactions/`,
			},
			{
				label: <NavLink to={`${pathname}shifts/`}>Смены</NavLink>,
				key: `${pathname}shifts/`,
			},
			{
				label: (
					<NavLink to={`${pathname}cashout/`}>Выдача наличных</NavLink>
				),
				key: `${pathname}cashout/`,
			},
		],
		[pathname]
	)

	const detail = cashierStore.getDetailInfo()

	if (!detail) return null

	return (
		<>
			<div style={{ margin: '20px 16px' }}>
				<AppBreadСrumbs links={[]} routes={MERCHANT_DASHBOARD_BREADCRUBMS} />
			</div>

			<div className={styles.wrapper}>
				<LoadingArea loading={cashierStore.isLoading}>
					<Header data={detail} />

					<div className={styles.tabs}>
						<AppTabs items={TabOptions} />
					</div>

					<Routes>
						<Route
							path={`/detail/*`}
							element={
								<Detail
									data={detail}
									getCashier={() => cashierStore.getAsyncDetail(cashierId)}
								/>
							}
						/>

						<Route
							path={`/transactions/`}
							element={
								<Transactions hideCashierName params={{ cashier: cashierId }} />
							}
						/>

						<Route path={`/shifts/`} element={<Shifts />} />
						<Route path={`/cashout/`} element={<Cashout />} />

						<Route
							path='*'
							element={<Navigate to={`${pathname}detail/`} replace />}
						/>
					</Routes>
				</LoadingArea>
			</div>
		</>
	)
})

export default CashierDetail
