import { DefaultOptionType, SelectProps } from "antd/es/select";
import { AppSelect } from "components";
import { TYPE_TRANSACTION_SELECT } from "constants/transaction.model";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { setType } from "store/rtk/slices/filters/transactions-filter.slice";

const OPTIONS = [
  { value: '', label: "Все" },
  ...Object.keys(TYPE_TRANSACTION_SELECT).map((key) =>
    // @ts-ignore
    ({ value: key, label: TYPE_TRANSACTION_SELECT[key] })
  ),
];

const FilterByTransactionType = (props: SelectProps) => {
  const dispatch = useAppDispatch();
  const transactionType = useAppSelector(
    (store) => store.transactionsFilters.type
  );

  const handleChange = (
    _: DefaultOptionType | null,
    option: DefaultOptionType
  ) => {
    dispatch(setType(option || null));
  };

  return (
    <AppSelect
      allowClear
      placeholder="Способ оплаты"
      options={OPTIONS}
      defaultValue={transactionType}
      onChange={handleChange}
      {...props}
    />
  );
};

export default FilterByTransactionType;
