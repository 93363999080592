import React, { useMemo } from 'react'
import {
	Navigate,
	NavLink,
	Route,
	Routes,
	useResolvedPath,
} from 'react-router-dom'
import { useParams } from 'react-router'

import { observer } from 'mobx-react-lite'

import { LoadingArea, AppTabs } from 'components'

import { store } from 'store'

import Detail from './components/Detail'
import Shifts from './components/Shifts'
import CashRegister from './components/CashRegister'
import styles from './Branch.module.scss'
import Header from './common/Header'
import Cashiers from './components/Cashiers'
import Transactions from '../../Transactions'
import Cashout from "../../../../Cashout";

const Branch = observer(() => {
	const { branchId } = useParams()

	const pathname = useResolvedPath('').pathname

	const { branchStore, merchantStore } = store

	const merchantDetail = merchantStore.getDetailInfo()

	React.useEffect(() => {
		branchStore.getAsyncDetail(branchId)

		return () => branchStore.setDetail(null)
	}, [branchId])

	const TabOptions = useMemo(
		() => [
			{
				label: <NavLink to={`${pathname}detail/`}>Общие данные</NavLink>,
				key: `${pathname}detail/`,
			},
			{
				label: <NavLink to={`${pathname}transactions/`}>Транзакции</NavLink>,
				key: `${pathname}transactions/`,
			},
			{
				label: <NavLink to={`${pathname}cashiers/`}>Кассиры</NavLink>,
				key: `${pathname}cashiers/`,
			},
			{
				label: <NavLink to={`${pathname}cash_register/`}>ККМ</NavLink>,
				key: `${pathname}cash_register/`,
			},
			{
				label: <NavLink to={`${pathname}shifts/`}>Смены</NavLink>,
				key: `${pathname}shifts/`,
			},
			{
				label: (
					<NavLink to={`${pathname}cashout/`}>Выдача наличных</NavLink>
				),
				key: `${pathname}cashout/`,
			},
		],
		[pathname]
	)

	const detail = branchStore.getDetailInfo()

	if (!detail) return null

	return (
		<div className={styles.wrapper}>
			<LoadingArea loading={branchStore.isLoading} spin={!!detail}>
				<Header
					branch={detail}
					getBranch={() => branchStore.getAsyncDetail(branchId)}
				/>

				<div className={styles.tabs}>
					<AppTabs items={TabOptions} />
				</div>

				<Routes>
					<Route
						path='/detail/*'
						element={
							<Detail
								data={detail}
								getBranch={() => branchStore.getAsyncDetail(branchId)}
							/>
						}
					/>

					<Route
						path='/transactions/'
						element={
							<Transactions hideBranchName params={{ branches: branchId }} />
						}
					/>
					<Route
						path='/cashiers/*'
						element={<Cashiers branchId={detail.id} />}
					/>
					<Route
						path='/cash_register/'
						element={<CashRegister branchId={detail.id} kkmExists={merchantDetail?.kkm_user_exists} />}
					/>
					<Route path='/shifts/' element={<Shifts />} />
					<Route path={`/cashout/`} element={<Cashout />} />

					<Route
						path='*'
						element={<Navigate to={`${pathname}detail/`} replace />}
					/>
				</Routes>
			</LoadingArea>
		</div>
	)
})

export default Branch
