import AppStatuses, { StatusItem } from "components/AppStatuses";
import { CASHOUT_STATUSES } from "constants/cashout/cashout-statuses";
import { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";
import { CashoutApi } from "services/api/Cashout";
import { CashoutListCounters } from "services/api/Cashout/types";

const CashoutStatusFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [counters, setCounters] = useState<CashoutListCounters>();

  const status = searchParams.get("status") || "";
  const start_date = searchParams.get('start_date')
  const end_date = searchParams.get('end_date')

  const statusList: StatusItem[] = Object.keys(CASHOUT_STATUSES).map((key) => {
    return {
      key: key,
      label: CASHOUT_STATUSES[key].label,
      color: CASHOUT_STATUSES[key].color,
      count: counters?.[key as keyof CashoutListCounters] || 0,
    };
  });

  const onClickCard = (statusItem: StatusItem) => {
    if (statusItem.key === "all") {
      searchParams.set("offset", "0");
      searchParams.set("currentPage", "1");
      searchParams.delete("status");
      setSearchParams(searchParams);
      return;
    }

    searchParams.set("offset", "0");
    searchParams.set("currentPage", "1");
    searchParams.set("status", statusItem.key);
    setSearchParams(searchParams);
  };

  const fetchCounters = async () => {
    const { data } = await CashoutApi.getCashoutListCounters({
        start_date,
        end_date
    });
    setCounters(data);
  };

  useEffect(() => {
    fetchCounters();
  }, [start_date, end_date]);

  return (
    <>
      <AppStatuses
        active={status}
        onClick={onClickCard}
        statuses={[
          {
            label: "Все",
            key: "all",
            color: "$purple",
            active: !status,
          },
          ...statusList,
        ]}
      />
    </>
  );
};

export default CashoutStatusFilter;
