import React from 'react'

import clsx from 'clsx'


import styles from './AppStatusIndicator.module.scss'

interface Props {
	status?: string
}

const AppStatusIndicator: React.FC<Props> = ({ status }) => {
	const variantStatus = {
		all: styles.all,
		inited: styles.processing,
		waiting: styles.waiting,
		failed: styles.error,
		canceled: styles.refused,
		paid: styles.success,

		qr_scanned: styles.qr_scanned,
		rollbacked: styles.rollbacked,
		for_rollback: styles.for_rollback,

		rollback: styles.rollback,
		final_committed: styles.rollback,
		final_paid: styles.rollback,
		reversal_by_card: styles.qr_scanned,
		rollback_card: styles.error,
		rollback_cash: styles.processing,
		unknown: styles.rollbacked,

		committed: styles.success,
		processing: styles.processing,
		rollbacking: styles.rollback_cash,
		payed: styles.rollback_card,
		canceled_by_timer: styles.canceled_by_timer,
		unpaid: styles.unpaid,
		confirm: styles.success,
		in_progress: styles.processing,
	}

	// @ts-ignore
	return <span className={clsx(variantStatus[status.toLowerCase()], styles.point)} />
}

export default AppStatusIndicator
