import { PaginationResult } from 'models/pagination.models'
import { clientApi } from '../clientApi'
import { Notification } from './NotificationApi.model'

export const NotificationsApi = {
    getNotifications() {
        return clientApi.get<PaginationResult<Notification>>(
            'crm_notification/',
        )
    },
    markAsReadById(id: number) {
        return clientApi.post(`crm_notification/mark_read/?id=${id}`)
    },
}
