export const PALETTE = {
    $siren: 'rgba(0, 146, 161, 0.7)',
    $siren_light: '#F3FAFB',

    $black: '#090B17',
    $gray: '#575963',
    $primary: '#0092a1',

    $black_2: '#2f3137',

    $gray_blue: '#',

    $gray_blue_3: '#575963;',

    $gray_blue_2: '#758795',

    $white: '#ffff',

    $primary_app: '#0098C1',

    $gray_2: '#5e626e',
    $gray_4: '#babcc4',
    $gray_3: '#838795',
    $gray_5: '#D6D7DC',

    $light_gray_3: '#f7f7f8',
    $light_gray_2: '#f1f2f3',
    $light_grey: '#E3E4E8',

    $blue: '#1890ff',
    $dark_blue: '#244f6d',

    $green: '#389e0d',

    $yellow: '#fdd400',

    $orange: '#faad14',
    $orange_2: '#F1880C',

    $red: '#f5222d',

    $purple: '#722ED1',

    $punk: '#EB2F96',

    $light_blue: '#e6f5f6',
    $light_blue_4: '#d4e3ff',
    $light_blue_3: '#b5c7d9',
    $light_blue_2: '#ecf0f0',

    $transparent: 'transparent',

    $bgPrimary:
        'linear-gradient(178deg, #E4F0FF -49.55%, #E4F0FF -49.53%, #F2F4F7 98.62%), #FFF',

    statuses: {
        $blue: '#1890ff',
        $orange: '#FF9A00',
        $blue_1: '#514EFF',
        $blue_2: '#647DFF;',
        $pink: '#FF64AE',
        $red: '#FF6464',
        $tagShiftError: '#11ABCD',
        $green: '#389E0D',
        $purple: '#722ED1',
        $yellow: '#ECC500',
        $salt: '#0AC878',
        $pink2: '#E253E5',
        $error: '#FF1313',
    },
}
