import React from 'react';
import {matchPath, useLocation} from 'react-router';
import {NavLink} from 'react-router-dom';

import {ArrowSimple} from "components/icon";

import styles from './Item.module.scss';

const Item = (props: any) => {
    const {
        routeItem,
    } = props;

    const {pathname} = useLocation()

    const currentMatchPath = matchPath(`${routeItem.path}*`, pathname)

    const isExact = !currentMatchPath?.params['*'];

    const pathnameBase = currentMatchPath ? `${currentMatchPath.pathnameBase}/` : undefined;

    if (pathnameBase) {
        return (
            <>
                {pathnameBase && (
                    <>
                        {isExact ? (
                            <span className={styles.text}>
                                {routeItem.title}
                            </span>
                        ) : (
                            <NavLink to={pathnameBase} className={styles.link}>
                                {routeItem.title}
                            </NavLink>
                        )}

                    </>
                )}

                {pathnameBase && !isExact && (
                    <ArrowSimple className={styles.separatorIcon} />
                )}
            </>
        );
    }

    return null;
};

export default Item;
