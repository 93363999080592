import { createApi } from "@reduxjs/toolkit/query/react";

import { PaginationResult } from "models/pagination.models";
import axiosBaseQuery from "store/rtk/helpers/axiosBaseQuery";
import { Report } from "./ReportsApi.model";

export const ReportsApi = createApi({
  reducerPath: "ReportsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Report"],
  endpoints: (build) => ({
    getReports: build.query<PaginationResult<Report>, number>({
      query: (merchantId) => {
        return {
          method: "GET",
          url: `/crm/built_reports/`,
          params: {
            user_id: merchantId,
          },
        };
      },
      providesTags: ["Report"],
    }),
    buildReport: build.mutation<void, string>({
      query: (query) => ({
        url: "/crm/crm_transactions/build_report/" + query,
        method: "GET",
      }),
      invalidatesTags: ["Report"],
    }),
    buildDetailTransactionReport: build.mutation<void, string>({
      query: (query) => ({
        url: "/transactions-detail/build_exel_report/" + query,
        method: "POST",
      }),
      invalidatesTags: ["Report"],
    }),
    deleteReport: build.mutation<void, number>({
      query: (id) => ({
        url: `/crm/built_reports/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Report"],
    }),
  }),
});

export const {
  useGetReportsQuery,
  useBuildReportMutation,
  useBuildDetailTransactionReportMutation,
  useDeleteReportMutation,
} = ReportsApi;
