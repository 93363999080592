import React from 'react';

import { Alert } from 'antd';

import {AppButton} from "../index";

import styles from './Error.module.scss';

const Error = (props: any) => {
    const { errorInfo, onRefresh } = props;

    const onUpdate = () => {
        onRefresh();
    }

    const errors = {
        status: errorInfo?.response?.status || 'Error',
        statusText: errorInfo?.response?.statusText || 'Network Error',
        detail: errorInfo?.response?.data?.detail || '',
    }

    return (
        <div className={styles.wrapper}>
            <Alert
                message={`${errors.status}: ${errors.statusText}`}
                description={errors.detail}
                type="error"
                showIcon
            />

            {
                onRefresh && (
                    <AppButton className={styles.button} onClick={onUpdate}>Обновить</AppButton>
                )
            }

        </div>
    );
};

export default Error;
