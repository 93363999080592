import { ArrowDefault } from 'components/icon'
import s from './sort-title.module.scss'
import { useSearchParams } from 'react-router-dom'
import { multiplyParamsOrder } from 'utils/multiply-params-order'
import clsx from 'clsx'

interface Props {
	title: string
	paramKey: string
	value: string
}
export default function SortTitle({ title, paramKey, value }: Props) {
	const [searchParams, setSearchParams] = useSearchParams()
	const order_by = searchParams.get(paramKey)

	const onSort = () => {
		const currentParams = multiplyParamsOrder(order_by as string, value)
		searchParams.set('order_by', currentParams.join(','))
		setSearchParams(searchParams, {replace: true})
	}

	const get = (): string => {
		const hasCurrentParams = order_by && order_by.includes(value)
		const hasMinusCurrentParams = order_by && order_by.includes(`-${value}`)

		if (hasMinusCurrentParams) {
			return 'descending'
		} else if (hasCurrentParams) {
			return 'ascending'
		} else {
			return ''
		}
	}

	return (
		<div onClick={() => onSort()} className={s.title}>
			<p>{title}</p>

			<div className={s.arrows}>
				<ArrowDefault className={clsx(s.arrow, s[get()])} />
			</div>
		</div>
	)
}
