import React from 'react'
import { useNavigate, useParams } from 'react-router'
import { useResolvedPath } from 'react-router-dom'

import { Controller, useForm, useWatch } from 'react-hook-form'

import { store } from 'store'

import { AppTitle, AppInput, AppButton, SelectField } from 'components'

import { SIZE_INPUT } from 'constants/ComponentSize'
import {
	Localities,
	BranchLocalitiesItem,
} from 'services/api/BranchesApi.models'
import { MerchantsApi } from 'services/api/MerchantsApi'
import { BranchesApi } from 'services/api/BranchesApi'

import { LEVEL_LOCALITIES } from 'constants/LevelLocalities'

import styles from './create.module.scss'
import { MerchantDashboardApi } from 'services/api/MerchantDashboard/MainApi'
import Maps from 'pages/MerchantDashboard/common/Map'

interface SetMarker {
	lat: number
	lng: number
}

interface CreateBranchForm {
	name: string
	company_owner: number
	region?: string
	rayon?: string
	ail_aimag?: string
	punkt?: string
	address: string
	house?: string
	postal_code?: string
	lat: number
	lon: number
}

interface SelectFields {
	regions?: BranchLocalitiesItem[]
	rayons?: BranchLocalitiesItem[]
	aiyl_aimags?: BranchLocalitiesItem[]
	villages?: BranchLocalitiesItem[]
}

const CreateBranch = () => {
	const { user } = store
	const currentUser = user.getUser()


	const pathname = useResolvedPath('../').pathname

	const {
		control,
		register,
		handleSubmit,
		setValue,
		setError,
		clearErrors,
		formState: { errors },
	} = useForm<CreateBranchForm>()
	const navigate = useNavigate()

	const { resultRequestStore } = store
	const watchRegion = useWatch({ control, name: 'region' })
	const watchRayon = useWatch({ control, name: 'rayon' })
	const watchAyil = useWatch({ control, name: 'ail_aimag' })
	const watchPunkt = useWatch({ control, name: 'punkt' })

	const watchLat = useWatch({ control, name: 'lat' })
	const watchLon = useWatch({ control, name: 'lon' })

	const [selectFields, seSelectFields] = React.useState<SelectFields>()

	const getLocalities = async (params?: Localities, key?: string) => {
		try {
			const { data } = await MerchantDashboardApi.getBranchLocalities(params)

			//@ts-ignore
			seSelectFields(state => ({ ...state, [key]: data }))
		} catch (error) {}
	}

	const goBack = () => {
		navigate(pathname)
	}
	const createBranch = async (form: CreateBranchForm) => {
		if (!watchLat || !watchLon) {
			setError('lat', { message: 'Поставьте метку на карте' })

			return
		} else {
			clearErrors('lat')
		}

		form.company_owner = Number(currentUser?.id)

		try {
			await MerchantDashboardApi.createBranch(form)
			resultRequestStore.setResultRequest({
				code: '201',
				description: 'Вы успешно создали ТТ',
			})
			goBack()
		} catch (error) {}
	}

	const setMarker = ({ lat, lng }: SetMarker) => {
		if (!lat || !lng) {
			setError('lat', { message: 'Поставьте марку на карте' })

			return
		} else {
			clearErrors('lat')
		}

		setValue('lat', lat)
		setValue('lon', lng)
	}

	React.useEffect(() => {
		getLocalities({ level: LEVEL_LOCALITIES.REGION }, 'regions')
	}, [])

	React.useEffect(() => {
		if (watchRegion) {
			// @ts-ignore
			getLocalities(
				{ level: LEVEL_LOCALITIES.RAYON, parent: watchRegion },
				'rayons'
			)
		}
	}, [watchRegion])

	React.useEffect(() => {
		if (watchRayon) {
			getLocalities(
				{ level: LEVEL_LOCALITIES.AIYL_AIMAG, parent: watchRayon },
				'aiyl_aimags'
			)
		}
	}, [watchRayon])

	React.useEffect(() => {
		if (watchAyil) {
			getLocalities(
				{ level: LEVEL_LOCALITIES.VILLAGE, parent: watchAyil },
				'punkts'
			)
		}
	}, [watchAyil])

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<h3 className={styles.title}>Создание торговой точки</h3>
			</div>

			<form className={styles.content} onSubmit={handleSubmit(createBranch)}>
				<div className={styles.column}>
					<div className={styles.item}>
						<AppInput
							sizeInput={SIZE_INPUT.SMALL}
							label='Название торговой точки'
							placeholder='Введите название'
							{...register('name', { required: 'Обязательное поле' })}
							error={errors?.name}
						/>
					</div>

					<div className={styles.item}>
						<Controller
							control={control}
							name='region'
							render={() => {
								return (
									<SelectField
										label={'Регион'}
										value={watchRegion}
										showSearch
										onChange={val => {
											setValue('region', val.value)
											setValue('rayon', '')
											setValue('ail_aimag', '')
											setValue('punkt', '')
										}}
										// @ts-ignore
										options={selectFields?.regions}
										className={styles.selectField}
										optionLabelKey={'name'}
										sizeInput={SIZE_INPUT.SMALL}
										error={errors.region}
									/>
								)
							}}
						/>
					</div>

					<div className={styles.item}>
						<Controller
							control={control}
							name='rayon'
							render={() => {
								return (
									<SelectField
										label={'Район'}
										value={watchRayon}
										showSearch
										onChange={val => {
											setValue('rayon', val.value)
											setValue('ail_aimag', '')
											setValue('punkt', '')
										}}
										// @ts-ignore
										options={selectFields?.rayons}
										className={styles.selectField}
										optionLabelKey={'name'}
										sizeInput={SIZE_INPUT.SMALL}
										error={errors.rayon}
										disabled={!watchRegion}
									/>
								)
							}}
						/>
					</div>

					<div className={styles.item}>
						<Controller
							control={control}
							name='ail_aimag'
							render={() => {
								return (
									<SelectField
										label='Айыл аймак'
										value={watchAyil}
										showSearch
										onChange={val => {
											setValue('ail_aimag', val.value)
											setValue('punkt', '')
										}}
										// @ts-ignore
										options={selectFields?.aiyl_aimags}
										className={styles.selectField}
										optionLabelKey={'name'}
										sizeInput={SIZE_INPUT.SMALL}
										error={errors.ail_aimag}
										disabled={!watchRayon}
									/>
								)
							}}
						/>
					</div>

					<div className={styles.item}>
						<Controller
							control={control}
							name='punkt'
							render={() => {
								return (
									<SelectField
										label='Населенный пункт'
										value={watchPunkt}
										showSearch
										onChange={val => setValue('punkt', val.value)}
										// @ts-ignore
										options={selectFields?.punkts}
										className={styles.selectField}
										optionLabelKey={'name'}
										sizeInput={SIZE_INPUT.SMALL}
										error={errors.punkt}
										disabled={!watchAyil}
									/>
								)
							}}
						/>
					</div>

					<div className={styles.item}>
						<div className={styles.buttons}>
							<AppButton type='button' variant='lightBlue' onClick={goBack}>
								Отмена
							</AppButton>

							<AppButton>Сохранить</AppButton>
						</div>
					</div>
				</div>

				<div className={styles.column}>
					<div className={styles.item}>
						<AppInput
							sizeInput={SIZE_INPUT.SMALL}
							label='Улица'
							placeholder='Введите адрес'
							{...register('address', { required: 'Обязательное поле' })}
							error={errors?.address}
						/>
					</div>

					<div className={styles.item}>
						<AppInput
							sizeInput={SIZE_INPUT.SMALL}
							label='Дом'
							placeholder='Введите номер дома'
							{...register('house')}
						/>
					</div>

					<div className={styles.item}>
						<AppInput
							sizeInput={SIZE_INPUT.SMALL}
							label='Почтовый индекс'
							placeholder='Введите почтовый индекс'
							{...register('postal_code')}
						/>
					</div>
				</div>

				<div className={styles.column}>
					<Maps onClick={setMarker} />

					{errors?.lat && (
						<p className={styles.error}>{errors?.lat?.message}</p>
					)}
				</div>
			</form>
		</div>
	)
}

export default CreateBranch
