import React, { useState } from 'react'
import { useParams } from 'react-router'

import { message, Spin } from 'antd'

import { observer } from 'mobx-react-lite'

import { BranchDetail } from 'services/api/BranchesApi.models'
import { BranchesApi } from 'services/api/BranchesApi'

import { AppTitle, QrGenerator, AppSwitch } from 'components'
import { AppBreadСrumbs } from 'components'
import styles from './header.module.scss'
import { store } from 'store'
import MERCHANT_DASHBOARD_BREADCRUBMS from 'pages/MerchantDashboard/merchBreadCrumbs'

interface Props {
	branch: BranchDetail
	getBranch: () => void

	showFormCreateCashier?(): void
}

const Header: React.FC<Props> = observer(({ branch, getBranch }) => {
	const { branchId } = useParams()

	const { user } = store

	const isEditable = !user.actions.readOnly

	const [loading, setLoading] = useState(false)

	const toggleSwitch = async (values: object) => {
		setLoading(true)
		try {
			await BranchesApi.updateBranchField(Number(branchId), { ...values })
			getBranch()
		} catch (e) {}
		setLoading(false)
	}

	const onGenerateQr = async () => {
		setLoading(true)

		try {
			await BranchesApi.generateStaticQr(Number(branchId))
			getBranch()
			message.open({
				type: 'success',
				content: 'QR успешно сгенерирован',
				// @ts-ignore
				maxCount: 1,
				key: 'qrID',
			})
		} catch (e) {}
		setLoading(false)
	}

	return (
		<>
			<div style={{ margin: '30px 0' }}>
				<AppBreadСrumbs links={[]} routes={MERCHANT_DASHBOARD_BREADCRUBMS} />
			</div>
			<div className={styles.wrapper}>
				<Spin spinning={loading}>
					<div className={styles.header}>
						<div className={styles.title}>
							<AppTitle title={branch.name} />
						</div>
					</div>
				</Spin>
			</div>
		</>
	)
})

export default Header
