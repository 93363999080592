import { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import Logo from 'assets/images/svg/mkassa_logo.svg'
import { AuthUser as AuthUserForm, ResetPassword } from './components'
import { AuthApi } from 'services/api/AuthApi'
import { getInStorage, saveInStorage } from 'services/storage'

import styles from './Auth.module.scss'
import { MKASSA_ARM_TOKEN } from 'services/storage/constants'
import { ARM_ROUTES } from 'router/routes'
import { observer } from 'mobx-react-lite'

interface AuthUser {
	device_uuid: string
	login: string
	password: string
	saveUser?: boolean
}

enum TABS {
	AUTH = 'auth',
	RESET = 'reset',
}

interface TABS_INTERFACE {
	[key: string]: {
		text: string
		actionText: string
		actionKey: string
	}
}

const TABS_NAME: TABS_INTERFACE = {
	auth: {
		text: 'Авторизация',
		actionText: 'Забыли пароль?',
		actionKey: TABS.RESET,
	},
	reset: {
		text: 'Забыли пароль?',
		actionText: 'Вход',
		actionKey: TABS.AUTH,
	},
}

const Auth = observer(() => {
	const navigate = useNavigate()
	const [tab, setTab] = useState<string>(TABS.AUTH)

	const onSubmit = async (submitData: AuthUser) => {
		const data: AuthUser = {
			device_uuid: submitData.device_uuid,
			login: submitData.login,
			password: submitData.password,
		}

		try {
			const response = await AuthApi.ARMauth(data)

			saveInStorage({
				saveLocal: !!submitData?.saveUser,
				value: response.data.token,
				key: MKASSA_ARM_TOKEN,
			})


			if (Object.keys(response.data).includes('cashier')) {
				navigate(`/cashier-dashboard/`)
			}
			if (Object.keys(response.data).includes('merchant')) {
				navigate(`/merchant-user/dashboard/detail/`)
			}
		} catch (error) {}
	}

	const renderFrom = (currentTab: string) => {
		switch (currentTab) {
			case TABS.AUTH:
				return <AuthUserForm onSubmit={onSubmit} />
			case TABS.RESET:
				return <ResetPassword />
		}
	}

	const changeTab = (tab: string) => {
		setTab(tab)
	}

	const currentTab = TABS_NAME[tab]

	const getUser = async () => {
		const { data } = await AuthApi.getUser()

		return data
	}

	useEffect(() => {
		const token = getInStorage(MKASSA_ARM_TOKEN)

		if (token) {
			const data = getUser()
			// @TODO fix later
			// @ts-ignore
			if (data?.user_type === 'CASHIER') {
				navigate('/cashier-dashboard/')
			} else {
				navigate('/merchant-user/dashboard/detail/')
			}
		}
	}, [])
	return (
		<div className={styles.container}>
			<div className={styles.content}>
				<div className={styles.logo}>
					<img src={Logo} />
				</div>

				<div className={styles.header}>
					<p>{currentTab?.text}</p>

					<span
						className={styles.link}
						onClick={() => changeTab(currentTab.actionKey)}
					>
						{currentTab?.actionText}
					</span>
				</div>

				{renderFrom(tab)}
			</div>
		</div>
	)
})

export default Auth
