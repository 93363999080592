import {makeAutoObservable} from 'mobx';

import {ShiftsApi} from "services/api/ShiftsApi";
import {ShiftsItem} from "services/api/ShiftsApi.module";

class ShiftStore {
    detailInfo: ShiftsItem | null = null;
    isLoading = false;

    constructor() {
        makeAutoObservable(this);
    }

    setLoading(val: boolean) {
        this.isLoading = val;
    }

    setDetail(data: ShiftsItem) {
        this.detailInfo = data;
    }

    getDetailInfo() {
        return this.detailInfo;
    }

    async getAsyncDetail(id: string | number | undefined) {
        if (!id) return;

        if (!Number.isInteger(+id)) {
            return
        }

        this.setLoading(true);

        try {
            const {data} = await ShiftsApi.getShiftDetail(id as string);

            this.setDetail(data);
        } catch (error) {
            // @ts-ignore
        }

        this.setLoading(false);
    }
}

export default ShiftStore;
