import { useNavigate, useResolvedPath, useSearchParams } from 'react-router-dom'

import { useTableData } from 'hooks'

import { AppInput, AppTable } from 'components'

import { BranchItem } from 'services/api/BranchesApi.models'

import styles from './table.module.scss'
import { MerchantDashboardApi } from 'services/api/MerchantDashboard/MainApi'
import { store } from 'store'
import SortTitle from 'components/Sort/SortTitle'
import { formatInts } from 'utils/format-ints'
import AppTextFiledQuery from 'components/AppTextFiledQuery'
import { SearchIcon } from 'components/icon'
import { formatTyinsToSoms } from 'helpers/formatTyinsToSoms'

const tableColumns = [
	{
		dataIndex: 'id',
		title: '№',
	},
	{
		dataIndex: 'name',
		title: () => (
			<>
				<SortTitle paramKey='order_by' value='name' title='Наименование' />
			</>
		),
	},
	{
		title: 'Адрес',
		render: (item: BranchItem) => `${item.address} ${item.house || ''}`,
	},
	{
		dataIndex: 'transactions_sum',
		render: (value: number) => formatTyinsToSoms(value),
		title: () => (
			<>
				<SortTitle
					paramKey='order_by'
					value='transactions_sum'
					title='Сумма'
				/>
			</>
		),
	},

	{
		dataIndex: 'transactions_count',
		title: () => (
			<>
				<SortTitle
					paramKey='order_by'
					value='transactions_count'
					title='Количество успешных транзакций'
				/>
			</>
		),
	},
]

interface Table {
	canCreate: boolean
}

const Table: React.FC<Table> = props => {
	const [searchParams] = useSearchParams()
	const order_by = searchParams.get('order_by')
	const searchBranch = searchParams.get('searchBranch')

	const { user } = store

	const userData = user?.getUser()

	const navigate = useNavigate()
	const pathname = useResolvedPath('../').pathname
	const { loading, tableData, handleTableChange, pagination } = useTableData(
		MerchantDashboardApi.getListBranches as any,
		{
			company: userData?.company_id,
			order_by,
			query: searchBranch,
		}
	)

	const showDetail = (
		record: BranchItem,
		e: React.MouseEvent<any, MouseEvent>
	) => {
		if (e.metaKey || e.ctrlKey) {
			window.open(`${pathname}${record.id}/detail/`)
			return
		}

		navigate(`${pathname}${record.id}/detail/`)
	}

	return (
		<div className={styles.wrapper}>
			<div className={styles.input}>
				<AppTextFiledQuery icon={<SearchIcon />} queryKey='searchBranch' placeholder='Поиск по номеру и наименованию' />
			</div>

			<div className={styles.table}>
				<AppTable
					loading={loading}
					columns={tableColumns}
					data={tableData?.results}
					pagination={pagination}
					onChange={handleTableChange}
					onRow={(record: any) => {
						return {
							onClick: e => {
								showDetail(record, e)
							},
						}
					}}
				/>
			</div>
		</div>
	)
}

export default Table
