import { DefaultOptionType, SelectProps } from "antd/es/select";
import AppAsyncSelect from "components/AppAsyncSelect";
import { BranchesApi } from "services/api/BranchesApi";

const BranchSelect = (props: SelectProps) => {
  const fetchBranchOptions = async (
    search: string,
    limit: number,
    offset: number
  ): Promise<{ results: DefaultOptionType[]; count: number }> => {
    const response = await BranchesApi.getListBranches({
      query: search,
      limit,
      offset,
    });
    return {
      results: response.data.results.map((item: any) => ({
        label: `${item?.name} - ${item?.address}`,
        value: item.id,
      })),
      count: response.data.count,
    };
  };

  return (
    <AppAsyncSelect
      fetchOptions={fetchBranchOptions}
      placeholder="Выберите торговую точку"
      {...props}
    />
  );
};

export default BranchSelect;
