import { IconProps } from "../types";

const ArrowDefault = ({ className = "" }: IconProps) => {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 14" fill="none">
        <path
          d="M7.98001 4.84L10.98 8.84C11.2767 9.23554 10.9944 9.8 10.5 9.8L4.50001 9.8C4.00559 9.8 3.72336 9.23555 4.02001 8.84L7.02001 4.84C7.26001 4.52 7.74001 4.52 7.98001 4.84Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

export default ArrowDefault;
