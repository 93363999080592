import React from 'react';

import { FieldError } from 'react-hook-form';

import styles from './AppCheckbox.module.scss';
import clsx from "clsx";

interface Props {
  name: string;
  label: string;
  onClick(
    e: React.ChangeEvent<EventTarget | HTMLInputElement>,
    name: string,
  ): void;
  error?: FieldError;
  checked?: boolean;
  className?: string
}

const AppCheckbox: React.FC<Props> = ({
  name,
  label,
  onClick,
  error,
  checked,
  className
}) => {
  return (
    <div className={clsx(styles.container, className)}>
      <label htmlFor={name} className={styles.itemCheckbox}>
        <input
          type="checkbox"
          id={name}
          onClick={(e) => onClick(e, name)}
          checked={checked}
        />
        <div className={styles.checkbox} />
        <div dangerouslySetInnerHTML={{ __html: label }} />
      </label>

      <div className={styles.error}>{error?.message}</div>
    </div>
  );
};

export default AppCheckbox