export const MerchantRoutes = {
	login: '/login',
	dashboard: '/dashboard',
	branches: '/dashboard/branches',
	branchesDetail: '/dashboard/branches/:brancheId',
	cashier: '/dashboard/cashier',
	cash_register: '/dashboard/cash_register',
	transaction_detail: '/dashboard/transaction-detail',
	cash_registerDetail: '/dashboard/cash_register/:cash_registerId',
	cashierDetail: '/dashboard/cashier/:cashierId',

	reports: '/dashboard/reports',
	reports_list: '/dashboard/reports-list/',
	reportsDetail: '/dashboard/reports/:reportsId',

	shifts: '/dashboard/shifts',
	messages: '/dashboard/messages',
	messagesDetail: '/dashboard/messages/messagesId',
	shiftsDetail: '/dashboard/shifts/shiftsId',
}
