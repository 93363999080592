const WarningIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.91732 4.9165C11.0007 -1.16683 21.0007 -1.16683 27.084 4.9165C33.1673 10.9998 33.1673 20.9998 27.084 27.0832C24.0007 30.1665 20.0007 31.6665 16.0007 31.6665C12.0007 31.6665 8.00065 30.1665 4.91732 27.0832C-1.16601 20.9998 -1.16601 10.9998 4.91732 4.9165ZM6.08399 25.9165C11.5007 31.3332 20.4173 31.3332 25.834 25.9165C31.2507 20.4998 31.2507 11.5832 25.834 6.1665C23.084 3.4165 19.5007 2.08317 15.9173 2.08317C12.334 2.08317 8.75065 3.4165 6.00066 6.1665C0.667324 11.5832 0.667322 20.4165 6.08399 25.9165ZM15.5007 13.8332C15.334 13.9998 15.1673 14.2498 15.1673 14.5832V22.5832C15.1673 22.9165 15.2507 23.1665 15.5007 23.3332C15.7507 23.4998 15.9173 23.5832 16.2507 23.5832C16.5007 23.5832 16.7507 23.4998 17.0007 23.3332C17.1673 23.1665 17.334 22.9165 17.334 22.5832V14.5832C17.334 14.2498 17.2507 13.9998 17.0007 13.8332C16.7507 13.6665 16.584 13.5832 16.2507 13.5832C15.9173 13.5832 15.6673 13.6665 15.5007 13.8332ZM15.2507 9.33317C15.0007 9.58317 14.9173 9.83317 14.9173 10.1665C14.9173 10.4998 15.0007 10.8332 15.2507 10.9998C15.5007 11.2498 15.7507 11.3332 16.1673 11.3332C16.584 11.3332 16.834 11.2498 17.084 10.9998C17.334 10.7498 17.4173 10.4998 17.4173 10.1665C17.4173 9.83317 17.334 9.49984 17.084 9.33317C16.834 9.08317 16.5007 8.99984 16.1673 8.99984C15.834 8.99984 15.5007 9.08317 15.2507 9.33317Z"
        fill="#FAAD14"
      />
    </svg>
  );
};

export default WarningIcon;
