export const KEYS_DATE = {
  TODAY: "today",
  THREE_DAYS: "three_days",
  WEEK: "week",
  TWO_WEEK: "two_week",
  MONTH: "month",
};

export const FILTER_DATE = [
  {
    key: KEYS_DATE.TODAY,
    name: "День",
  },
  {
    key: KEYS_DATE.THREE_DAYS,
    name: "3 дня",
  },
  {
    key: KEYS_DATE.WEEK,
    name: "Неделя",
  },
  {
    key: KEYS_DATE.TWO_WEEK,
    name: "2 недели",
  },
  {
    key: KEYS_DATE.MONTH,
    name: "Месяц",
  },
];
