import React from 'react'
import { Route, Routes } from 'react-router'

import View from './components/View'
import EditMerchant from './components/Edit'
import { MerchantsDetail } from 'services/api/MerchantsApi.models'

interface CustomerData {
	data: MerchantsDetail
}

const CustomerData: React.FC<CustomerData> = ({ data }) => {
	return (
		<Routes>
			<Route path='/' element={<View data={data} />} />
			<Route path='edit/' element={<EditMerchant detail={data} />} />
		</Routes>
	)
}

export default CustomerData
