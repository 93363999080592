import { useEffect, useState } from "react";
import { getOffset } from "../helpers/pagination";
import { Counters } from "../models/counters.model";
import { useLocation, useSearchParams } from "react-router-dom";
import { store } from "store";

interface Pagination {
  limit: number;
  offset: number;
}

interface ResponseProps {
  count?: number;
  next?: string;
  previous?: string;
  results: [];
  branches?: any;
  counters?: Counters;
  total?: number;
  branch_count?: number;
  cashier_count?: number;
  company_count?: number;
  transactions?: [];
  frunze_transaction?: [];
  transaction_status?: any;
  items?: any;
  branch_amount?: number;
}

type FetchProps = (
  params: Pagination
) => PromiseLike<{ data: ResponseProps }> | { data: any };

export const useTableData = (fetch: FetchProps, params: object = {}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { searchParams: searchParamsStore } = store;
  const { pathname } = useLocation();

  const currentPage =
    searchParams.get("currentPage") ||
    searchParamsStore.searchParams[pathname]?.currentPage ||
    "1";

  const pageSize =
    searchParams.get("pageSize") ||
    searchParamsStore.searchParams[pathname]?.pageSize ||
    "10";

  const [loading, setLoading] = useState(false);
  const [shouldUpdateData, setShouldUpdateData] = useState(false);

  const [tableParams, setTableParams] = useState<ResponseProps>({
    total: 0,
    results: [],
  });

  const setPageSize = (_pageSize: string | number) => {
    setSearchParams((prev) => {
      prev.set("pageSize", _pageSize as string);
      return prev;
    }, {replace: true});
    searchParamsStore.setSearchParams(pathname, "pageSize", Number(_pageSize));
  };

  const setCurrentPage = (page: string | number) => {
    setSearchParams((prev) => {
      prev.set("currentPage", page as string);
      return prev;
    }, {replace: true});
    searchParamsStore.setSearchParams(pathname, "currentPage", Number(page));
  };

  const loadData = async (queryParams: Pagination) => {
    try {
      setLoading(true);

      const { data } = await fetch({
        ...params,
        ...queryParams,
      });

      setTableParams({
        total: data.count || data.branch_amount,
        ...data,
      });
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData({
      limit: Number(pageSize),
      offset: getOffset(Number(currentPage), Number(pageSize)),
    });

    // setCurrentPage(1)
  }, [...Object.values(params)]);

  useEffect(() => {
    if (shouldUpdateData) {
      loadData({
        limit: Number(pageSize),
        offset: getOffset(Number(currentPage), Number(pageSize)),
      });

      setShouldUpdateData(false);
    }
  }, [shouldUpdateData]);

  // useEffect(() => {
  //   setPageSize(pageSize);
  //   setCurrentPage(currentPage);
  // }, []);

  useEffect(() => {
    searchParamsStore.setSearchParams(pathname, "pageSize", Number(pageSize));
		searchParamsStore.setSearchParams(pathname, "currentPage", Number(currentPage));
  });

  const handleTableChange = (pagination: any) => {
    const current =
      Number(pageSize) === Number(pagination.pageSize)
        ? Number(pagination.current)
        : 1;

    loadData({
      limit: pagination?.pageSize,
      offset: getOffset(current, Number(pageSize)),
    });

    setTableParams({
      ...tableParams,
      total: pagination.total,
    });

    setCurrentPage(current);
    setPageSize(pagination.pageSize);
  };

  const reloadData = () => setShouldUpdateData(true);

  return {
    tableData: { ...tableParams },
    loading,
    pagination: {
      total: tableParams.total || tableParams?.branch_amount,
      count: tableParams.count || tableParams?.branch_amount,
      current: Number(currentPage),
      pageSize: Number(pageSize),
    },
    handleTableChange,
    reloadData,
  };
};
