import axios from 'axios'
import { getInStorage, deleteStorage } from 'services/storage'
import { store } from 'store'
import { TYPES as requestTypes } from 'constants/TypeResults'
import {
	deleteStorageCashierUser,
	deleteStorageMerchantUser,
} from 'services/storage/MerchantUserDetail'
import { MKASSA_ARM_TOKEN } from 'services/storage/constants'

export const baseURL = process.env.REACT_APP_WIDGET_API_URL + '/api'

export const clientApi = axios.create({
	baseURL,
})

function setResponseInterceptors() {
	clientApi.interceptors.request.use(config => {
		const ARM_TOKEN = getInStorage(MKASSA_ARM_TOKEN)

		if (ARM_TOKEN) {
			config.headers.Authorization = `Bearer ${ARM_TOKEN}`
		}

		return config
	})

	clientApi.interceptors.response.use(
		response => Promise.resolve(response),
		error => {
			const { resultRequestStore } = store
			const status = error.response.status
			const { response } = error

			if (response && status === 401) {
				window.location.href = '/arm/auth'
				// deleteStorage()
				// deleteStorageMerchantUser()
				// deleteStorageCashierUser()

				return Promise.reject(error)
			}

			if (response && status >= 400 && status <= 500) {
				resultRequestStore.setResultRequest({
					description: response?.data?.message,
					code: response?.status,
					type: requestTypes.error,
				})

				return Promise.reject(error)
			}

			return Promise.reject(error)
		}
	)
}

setResponseInterceptors()
