import React from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
} from "@react-google-maps/api";

import { DEFAULT_THEME } from "constants/Theme";
import { GOOGLE_MAP_KEY } from "constants/google-map-key";

const defaultOption = {
  panControl: true,
  ClickableIcons: false,
  styles: DEFAULT_THEME,
};

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 42.8746,
  lng: 74.5698,
};

interface SetLatLong {
  lat: number | string | undefined;
  lng: number | string | undefined;
}

interface Props {
  onClick(data: SetLatLong): void;
  initial?: SetLatLong;
  disabled?: boolean;
}

const Maps: React.FC<Props> = ({ onClick, initial, disabled }) => {
  const [markers, setMarkers] = React.useState<SetLatLong>(initial as SetLatLong);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAP_KEY,
  });

  const setMarker = (e: any) => {
    if (disabled) return;

    const data = {
      lat: e?.latLng?.lat() || 0,
      lng: e?.latLng?.lng() || 0,
    };

    setMarkers(data);
    onClick(data);
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={12}
      onClick={setMarker}
      options={defaultOption}
    >
      {markers ? (
        <Marker
          position={{
            lat: Number(markers?.lat || 0),
            lng: Number(markers?.lng || 0),
          }}
        />
      ) : null}
    </GoogleMap>
  ) : null;
};

export default Maps;
