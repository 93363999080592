import { useResolvedPath } from "react-router-dom";

import { DataView } from "components";

import { CashierDetail } from "services/api/CashierApi.models";

import styles from "./view.module.scss";

const VIEW_COLUMNS = [
  [
    {
      label: "Владелец",
      dataIndex: ["user.last_name", "user.first_name", "user.middle_name"],
    },
    {
      label: "Торговая точка",
      dataIndex: "branches",
      type: "options",
      optionKey: "name",
    },
    {
      label: "ИНН",
      dataIndex: "user.inn",
    },
    {
      label: "Логин",
      dataIndex: "user.login",
    },
    {
      label: "Номер телефона",
      dataIndex: "user.phone_number",
    },
  ],
  [
    {
      label: "Мерчант",
      dataIndex: "merchant.name",
    },
    {
      label: "Тип устройства",
      dataIndex: "mpos_type",
      type: "option",
      optionValues: {
        MOBILE_APP: "Mobile app",
        SMARTPOS_MBANK: "SmartPos MBank",
        SMARTPOS_PAYMOB: "SmartPos Paymob",
      },
    },
  ],
];

interface DetailProps {
  data: CashierDetail;
}

const View: React.FC<DetailProps> = ({ data }) => {
  const pathname = useResolvedPath("").pathname;
  const isMobApp = data.mpos_type === "MOBILE_APP";
  const isMachine = data.mpos_type === "MACHINE";

  const VIEW_COLUMNS_CLONE = JSON.parse(JSON.stringify(VIEW_COLUMNS))
  const combineColumns = [...VIEW_COLUMNS_CLONE];

  if (isMachine) {
    combineColumns[1].push(
      {
        dataIndex: "api_key",
        label: "API ключ",
      },
    );
  }

  if (!isMobApp) {
    combineColumns.push([
      {
        label: "Серийный номер",
        dataIndex: "mpos_serial_number",
      },
      {
        label: "J номер",
        dataIndex: "mpos_j",
      },
    ]);
  }

  return (
    <>
      <div className={styles.wrapper}>
        <DataView
          title="Данные кассира"
          columns={combineColumns}
          data={data}
          edtLink={isMobApp ? `${pathname}edit/` : ""}
        />
      </div>
    </>
  );
};

export default View;
