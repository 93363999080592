import { enableStaticRendering } from 'mobx-react-lite'
import { ModalStore } from 'store/modules/ModalStore'
import { User } from 'store/modules/UserStore'
import { ResultRequest } from 'store/modules/BadRequestStore'
import { HeaderFiltersStore } from './modules/HeaderFiltersStore'

import MerchantStore from './modules/merchant/merchantStore'
import BranchStore from './modules/branch/branchStore'
import CashierStore from './modules/cashier/cashierStore'
import ShiftStore from './modules/shift/shiftStore'
import AsiaFiltersStore from './modules/AsiaStore/handleAsiaFilters'
import MerchantDetailStore from './modules/merchant/merchantUser'
import { UserMerchant } from './modules/UserMerchant'
import SearchParams from './modules/SearchParams'
import GlobalWindowApp from './modules/global-window'
import QueryFilterParams from './modules/queryFilters'

enableStaticRendering(typeof window === 'undefined')

export class RootStore {
	modalStore: ModalStore
	resultRequestStore: ResultRequest
	user: User
	headerFilters: HeaderFiltersStore
	merchantStore: MerchantStore
	branchStore: BranchStore
	cashierStore: CashierStore
	shiftStore: ShiftStore
	asiaFilters: AsiaFiltersStore
	merchantUser: MerchantDetailStore
	userMerchant: UserMerchant
	searchParams: SearchParams
	globalWindowApp: GlobalWindowApp
	queryFilterParams: QueryFilterParams

	constructor() {
		this.modalStore = new ModalStore()
		this.user = new User()
		this.resultRequestStore = new ResultRequest()
		this.headerFilters = new HeaderFiltersStore()
		this.merchantStore = new MerchantStore()
		this.branchStore = new BranchStore()
		this.cashierStore = new CashierStore()
		this.shiftStore = new ShiftStore()
		this.asiaFilters = new AsiaFiltersStore()
		this.merchantUser = new MerchantDetailStore()
		this.userMerchant = new UserMerchant()
		this.searchParams = new SearchParams()
		this.globalWindowApp = new GlobalWindowApp()
		this.queryFilterParams = new QueryFilterParams()
	}
}
