import { FormEvent } from "react";
import { makeAutoObservable } from "mobx";

class AsiaFiltersStore {
  search: string = "";
  constructor() {
    makeAutoObservable(this);
  }

  searchHandler(e: FormEvent<HTMLInputElement>) {
    this.search = e.currentTarget.value;
  }

  getSearchValue() {
    return this.search;
  }
}

export default AsiaFiltersStore;
