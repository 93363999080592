import s from './list-default.module.scss'

interface ListItem {
	key: string | number
	value: string | number
}
interface Props {
	list: ListItem[]
}
export default function ListDefault({ list }: Props) {
	return (
		<div>
			<ul className={s.list}>
				{list.map(item => {
					return (
						<li key={item.key}>
							<p className={s.key}>{item.key}</p>
							<p className={s.value}>{item.value}</p>
						</li>
					)
				})}
			</ul>
		</div>
	)
}
