import { AppButton, AppDatePicker, AppFilterDate, AppTable } from 'components'
import { useTableData } from 'hooks'
import { TransactionsApi } from 'services/api/TransactionsApi'

import s from './transaction-detail-branch.module.scss'
import { useParams, useSearchParams } from 'react-router-dom'
import { FILTER_DATE } from '../header'
import { useEffect, useMemo, useState } from 'react'
import { API_DATE_FORMAT, downloadFilePost } from 'utils'

import dayjs from 'dayjs'
import { notification } from 'antd'
import { baseURL } from 'services/api/clientApi'
import { DownloadIcon } from 'components/icon'
import ListOutline from 'shared/components/Lists/ListOutline'
import {
	ITransactionDetail,
	TransactionDetailList,
} from 'services/api/TransactionsApi.module'
import clsx from 'clsx'
import {
	TYPE_TRANSACTION,
	TypeTransactionEnum,
} from 'constants/transaction.model'
import moment from 'moment'
import { store } from 'store'
import { observer } from 'mobx-react-lite'
export const TransactionDetailBranch = observer(() => {
	const [searchParams, setSearchParams] = useSearchParams()
	const { transactionDetailId } = useParams()

	const [transactionDetail, setTransactionDetail] =
		useState<null | ITransactionDetail>(null)

	const [transactionDetailList, setTransactionDetailList] = useState<
		null | TransactionDetailList[]
	>(null)

	const { globalWindowApp } = store
	const [notifications] = notification.useNotification()

	const [isLoading, setIsLoading] = useState<boolean>(false)

	const gteDate = searchParams.get('start_date')
	const lteDate = searchParams.get('end_date')

	const { loading } = useTableData(
		TransactionsApi.getBranchTransactionDetail as any,
		{ start_date: gteDate, end_date: lteDate, branch: transactionDetailId }
	)

	useEffect(() => {
		if (
			!searchParams.get("start_date") &&
			!searchParams.get("end_date")
		  ) {	
			setSearchParams((prev) => {
			  prev.set("start_date", dayjs().format(API_DATE_FORMAT));
			  prev.set("end_date", dayjs().format(API_DATE_FORMAT));
			  return prev;
			}, {replace: true});
		  }
	}, [])

	const getTransactionDetail = async () => {
		try {
			const { data } = await TransactionsApi.getTransactionDetail({
				params: {
					branch: transactionDetailId as string,
					end_date: lteDate || '',
					start_date: gteDate || '',
				},
			})

			setTransactionDetail(data)
		} catch (error) {}
	}

	const getTransactionsListDetail = async () => {
		try {
			const { data } = await TransactionsApi.getBranchTransactionDetail({
				end_date: lteDate || '',
				start_date: gteDate || '',
				branch: transactionDetailId,
			})

			setTransactionDetailList(data)
		} catch (error) {}
	}

	const tableColumns = [
		{
			dataIndex: 'id',
			title: '№',
		},
		{
			dataIndex: 'sum',
			title: 'Сумма',
		},
		{
			dataIndex: 'created_at',
			title: 'Дата проведения',
		},
		{
			dataIndex: 'refund_sum',
			title: 'Сумма возмещения',
		},
		{
			dataIndex: 'refund_date',
			title: 'Дата возмещения',
		},
		{
			dataIndex: 'type',
			title: 'Тип',
			render: (value: TypeTransactionEnum) =>
				TYPE_TRANSACTION[value] || 'Не определен тип',
		},
	]

	const periodHandler = (values: [Date, Date] | null) => {
		if (values) {
			searchParams.set(
				'start_date',
				values ? dayjs(values[0]).format(API_DATE_FORMAT) : ''
			)
			searchParams.set(
				'end_date',
				values ? dayjs(values[1]).format(API_DATE_FORMAT) : ''
			)
		} else {
			searchParams.delete('start_date')
			searchParams.delete('end_date')
		}

		setSearchParams(searchParams, {replace: true})
	}

	const daysHandler = (item: { key: string; name: string; param: string }) => {
		searchParams.set('start_date', item.param)
		searchParams.set('end_date', dayjs().format(API_DATE_FORMAT))
		setSearchParams(searchParams, {replace: true})
	}

	const filterValue = useMemo(
		() => FILTER_DATE.find(it => it.param === gteDate)?.key,
		[gteDate]
	)

	const openNotification = () => {
		notifications.info({
			message: `Внимание!`,
			description:
				'Началось скачивание отчетов! Это может занять некотрое время',
		})
	}

	const downloadReport = async () => {
		try {
			globalWindowApp.setLoading({
				title: 'Загрузка отчета',
			})
			setIsLoading(true)
			openNotification()
			await downloadFilePost(
				`${baseURL}/transactions-detail/excel/` +
					`${searchParams ? `?${searchParams}` : ``}`,
				`Детальная выписка за ${gteDate} / ${lteDate}`
			)
		} catch (e) {
		} finally {
			globalWindowApp.stopLoading()
		}
		setIsLoading(false)
	}

	const PAYMENT_COUNTERS = [
		{
			key: 'Сумма платежей:',
			value: transactionDetail?.payment_total_sum || 0,
		},
		{
			key: 'QR:',
			value: transactionDetail?.payment_qr_sum || 0,
		},
		{
			key: 'Картой:',
			value: transactionDetail?.refund_card_sum || 0,
		},
	]
	const PAYMENT_REFOUND = [
		{
			key: 'Сумма возмещения:',
			value: transactionDetail?.refund_total_sum || 0,
		},
		{
			key: 'QR:',
			value: transactionDetail?.refund_qr_sum || 0,
		},
		{
			key: 'Картой:',
			value: transactionDetail?.refund_card_sum || 0,
		},
		{
			key: 'Комиссия:',
			value: transactionDetail?.bank_commission || 0,
		},
	]

	useEffect(() => {
		const promises = [getTransactionsListDetail(), getTransactionDetail()]

		Promise.allSettled(promises).then(results =>
			results.forEach(result => console.log(result.status))
		)
	}, [lteDate, gteDate])

	// useEffect(() => {
	// 	getTransactionsListDetail()
	// }, [lteDate, gteDate])

	const currentTabData = transactionDetailList?.map(item => item?.items) || []

	//@ts-ignore
	let flattenedArray = [].concat(...currentTabData)

	function disabledDate(current: any) {
		return current && current > moment().endOf('day')
	}

	return (
		<section className={s.wrapper}>
			<div className={clsx('box_mkassa', s.header)}>
				<div className={s.text}>
					<h3>{transactionDetail?.name}</h3>
					<h5>{transactionDetail?.address}</h5>
				</div>
			</div>
			<div className='box_mkassa'>
				<div className={s.counters}>
					<ListOutline list={PAYMENT_COUNTERS} />
					<ListOutline variant='warning' list={PAYMENT_REFOUND} />
				</div>
				<div className={s.controls}>
					<AppFilterDate
						filter={FILTER_DATE}
						current={filterValue || undefined}
						onChange={daysHandler}
						disabled={loading}
					/>
					<div className={s.left}>
						<AppDatePicker
							disabledDate={disabledDate}
							value={gteDate ? [gteDate, lteDate] : null}
							onChange={periodHandler}
							disabled={loading}
							allowClear={false}
						/>
						<AppButton
							disabled={loading || isLoading || !transactionDetailList?.length}
							onClick={downloadReport}
							iconLeft={<DownloadIcon />}
						>
							Выгрузить в Excel
						</AppButton>
					</div>
				</div>

				<div className={s.table}>
					<AppTable
						columns={tableColumns}
						data={flattenedArray}
						loading={loading}
					/>
				</div>
			</div>
		</section>
	)
})

export default TransactionDetailBranch
