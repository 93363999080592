import clsx from 'clsx'
import { AppButton } from 'components'

import styles from './styles.module.scss'
import AppIcon from 'common/AppIcon'

interface Props {
    onClick: () => void
    className?: string
}

const StopBtn = (props: Props) => {
    const { onClick, className } = props

    return (
        <AppButton variant="lightBlue" className={clsx(styles.btn, className)} onClick={onClick}>
            <div className={clsx(styles.content, styles.loading)}>
                <AppIcon
                    id="spin"
                    width={16}
                    height={16}
                    className={styles.rotating}
                />
                Генерируем отчет
            </div>
            <div className={clsx(styles.content, styles.stop)}>
                <AppIcon id="pause-filled" width={16} height={16} />
                Остановить генерацию
            </div>
        </AppButton>
    )
}

export default StopBtn
