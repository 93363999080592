import { Outlet, useLocation, useResolvedPath } from "react-router";
import HeaderMerchant from "../HeaderMerchant";
import { MerchantsDetail } from "services/api/MerchantsApi.models";
import styles from "./merchant-layout.module.scss";
import { Layout } from "antd";
import { NavLink } from "react-router-dom";
import { AppTabs } from "components";
import MerchantHeader from "../MerchantHeader";
interface Props {
  data: MerchantsDetail;
}
const { Content } = Layout;

export default function MerchantLayout({ data }: Props) {
  const pathname = useResolvedPath("").pathname;
  const { pathname: path } = useLocation();

  const preventNavigation = (
    e: React.MouseEvent<HTMLAnchorElement>,
    to: string
  ) => {
    if (path === to) {
      e.preventDefault();
    }
  };

  const TabOptions = [
    {
      label: (
        <NavLink to={`${pathname}dashboard/detail/`}>Общие данные</NavLink>
      ),
      key: `${pathname}dashboard/detail/`,
    },
    {
      label: (
        <NavLink to={`${pathname}dashboard/branches/`}>Торговые точки</NavLink>
      ),
      key: `${pathname}dashboard/branches/`,
    },
    {
      label: <NavLink to={`${pathname}dashboard/cashier/`}>Кассиры</NavLink>,
      key: `${pathname}dashboard/cashier/`,
    },
    {
      label: <NavLink to={`${pathname}dashboard/cash_register/`}>ККМ</NavLink>,
      key: `${pathname}dashboard/cash_register/`,
    },
    {
      label: (
        <NavLink
          to={`${pathname}dashboard/reports/`}
          onClick={(e) => preventNavigation(e, `${pathname}dashboard/reports/`)}
        >
          Транзакции
        </NavLink>
      ),
      key: `${pathname}dashboard/reports/`,
    },
    {
      label: <NavLink to={`${pathname}dashboard/shifts/`}>Смены</NavLink>,
      key: `${pathname}dashboard/shifts/`,
    },
    {
      label: (
        <NavLink
          to={`${pathname}dashboard/cashout/`}
          onClick={(e) => preventNavigation(e, `${pathname}dashboard/cashout/`)}
        >
          Выдача наличных
        </NavLink>
      ),
      key: `${pathname}dashboard/cashout/`,
    },
    {
      label: (
        <NavLink
          to={`${pathname}dashboard/transaction-detail/`}
          onClick={(e) =>
            preventNavigation(e, `${pathname}dashboard/transaction-detail/`)
          }
        >
          Детальная выписка
        </NavLink>
      ),
      key: `${pathname}dashboard/transaction-detail/`,
    },
    {
      label: (
        <NavLink to={`${pathname}dashboard/reports-list/`}>Отчеты</NavLink>
      ),
      key: `${pathname}dashboard/reports-list/`,
    },
  ];

  return (
    <Layout className={styles.wrapper}>
      <MerchantHeader />
      <HeaderMerchant data={data} />
      <div className={styles.tabs}>
        <AppTabs items={TabOptions} />
      </div>
      <Content>
        <Outlet />
      </Content>
    </Layout>
  );
}
