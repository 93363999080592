const PointsIcon = () => {
  return (
    <svg
      width="4"
      height="14"
      viewBox="0 0 4 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.99984 3.00004C2.73622 3.00004 3.33317 2.40309 3.33317 1.66671C3.33317 0.930328 2.73622 0.333374 1.99984 0.333374C1.26346 0.333374 0.666504 0.930328 0.666504 1.66671C0.666504 2.40309 1.26346 3.00004 1.99984 3.00004Z"
        fill="#0092A1"
      />
      <path
        d="M1.99984 8.33341C2.73622 8.33341 3.33317 7.73646 3.33317 7.00008C3.33317 6.2637 2.73622 5.66675 1.99984 5.66675C1.26346 5.66675 0.666504 6.2637 0.666504 7.00008C0.666504 7.73646 1.26346 8.33341 1.99984 8.33341Z"
        fill="#0092A1"
      />
      <path
        d="M1.99984 13.6667C2.73622 13.6667 3.33317 13.0697 3.33317 12.3333C3.33317 11.597 2.73622 11 1.99984 11C1.26346 11 0.666504 11.597 0.666504 12.3333C0.666504 13.0697 1.26346 13.6667 1.99984 13.6667Z"
        fill="#0092A1"
      />
    </svg>
  );
};

export default PointsIcon;
