import React, { useEffect, useState } from 'react'

import { Controller, useForm, useWatch } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { useResolvedPath } from 'react-router-dom'

import { Col, Row } from 'antd'

import { AppInput, AppButton, SelectField } from 'components'

import { SIZE_INPUT } from 'constants/ComponentSize'
import { TYPES } from 'constants/TypeResults'

import { store } from 'store'

import { MerchantsApi } from 'services/api/MerchantsApi'
import { MerchantsDetail } from 'services/api/MerchantsApi.models'

import styles from './Edit.module.scss'
import { User } from 'models/user.models'

interface FormProps {
	first_name: string
	last_name: string
	middle_name: string
	email: string
	name: string
	mbank_qr_commission: string
	other_qr_commission: string
	rbs_id: number
	category: object | null | number
}

interface EditMerchantInterface {
	detail: MerchantsDetail
}

const EditMerchant: React.FC<EditMerchantInterface> = ({ detail }) => {
	const navigate = useNavigate()
	const parentPath = useResolvedPath('../').pathname

	const [requested, setRequested] = useState(false)

	const { resultRequestStore, merchantStore } = store

	const {
		control,
		register,
		setValue,
		handleSubmit,
		setError,
		clearErrors,
		formState: { errors },
	} = useForm<FormProps>()

	const category = useWatch({ control, name: 'category' })

	useEffect(() => {
		setValue('first_name', detail.owner.first_name)
		setValue('last_name', detail.owner.last_name)
		setValue('middle_name', detail.owner.middle_name)
		setValue('name', detail.company_name)
		// @ts-ignore
		setValue('rbs_id', detail?.rbs_id)
		// @ts-ignore
		setValue('other_qr_commission', detail.other_qr_commission)
		// @ts-ignore
		setValue('mbank_qr_commission', detail.mbank_qr_commission)
		// @ts-ignore
		setValue('category', detail.category?.id || null)
	}, [])

	const getCategories = () => {
		return MerchantsApi.getMerchantCategories()
	}

	const goBack = () => {
		navigate(parentPath)
	}

	const onSubmit = async (values: FormProps) => {
		clearErrors()

		setRequested(true)

		try {
			await MerchantsApi.editMerchant(detail.id, {
				...(values as any),
			})

			resultRequestStore.setResultRequest({
				type: TYPES.success,
				description: 'Сохранено',
			})

			await merchantStore.getAsyncDetail(detail.id)

			goBack()
		} catch (error: any) {
			const errors = error.response?.data?.errors

			if (errors) {
				Object.keys(errors).forEach((key: string) => {
					// @ts-ignore
					return setError(key, { message: errors[key] })
				})
			}
		}

		setRequested(false)
	}

	return (
		<div className={styles.wrapper}>
			<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
				<h3 className={styles.title}>Редактирование мерчанта</h3>

				<Row gutter={[16, 16]}>
					<Col span={8}>
						<div className={styles.item}>
							<Controller
								control={control}
								name='last_name'
								rules={{
									maxLength: {
										value: 50,
										message: 'Максимум символов 50',
									},
								}}
								render={({ field: { value } }) => {
									return (
										<AppInput
											label='Фамилия владельца мерчанта'
											value={value}
											error={errors?.last_name}
											{...register('last_name')}
										/>
									)
								}}
							/>
						</div>

						<div className={styles.item}>
							<Controller
								control={control}
								name='first_name'
								rules={{
									maxLength: {
										value: 50,
										message: 'Максимум символов 50',
									},
								}}
								render={({ field: { value } }) => {
									return (
										<AppInput
											label='Имя владельца мерчанта'
											value={value}
											error={errors?.first_name}
											{...register('first_name')}
										/>
									)
								}}
							/>
						</div>

						<div className={styles.item}>
							<Controller
								control={control}
								name='middle_name'
								rules={{
									maxLength: {
										value: 50,
										message: 'Максимум символов 50',
									},
								}}
								render={({ field: { value } }) => {
									return (
										<AppInput
											label='Отчество владельца мерчанта'
											value={value}
											error={errors?.middle_name}
											{...register('middle_name')}
										/>
									)
								}}
							/>
						</div>

						<div className={styles.item}>
							<AppInput
								label='E-mail'
								type='email'
								error={errors?.email}
								{...register('email')}
							/>
						</div>

						<div className={styles.item}>
							<Controller
								control={control}
								name='name'
								rules={{
									required: 'Обязательное поле',
								}}
								render={({ field: { value } }) => {
									return (
										<AppInput
											label='Наименование мерчанта'
											value={value}
											error={errors?.name}
											{...register('name')}
										/>
									)
								}}
							/>
						</div>
					</Col>

					<Col span={8}>
						<div className={styles.item}>
							<AppInput
								label='Комиссия (QR MBank %)'
								type='number'
								step={0.1}
								error={errors?.mbank_qr_commission}
								{...register('mbank_qr_commission')}
							/>
						</div>

						<div className={styles.item}>
							<AppInput
								label='Комиссия (QR другие %)'
								type='number'
								step={0.1}
								max={100}
								error={errors?.other_qr_commission}
								{...register('other_qr_commission')}
							/>
						</div>

						<div className={styles.item}>
							<AppInput
								label='РБС ID'
								type='number'
								error={errors?.rbs_id}
								{...register('rbs_id')}
							/>
						</div>

						<div className={styles.formItem}>
							<Controller
								control={control}
								name='category'
								render={() => {
									return (
										<SelectField
											label='Категория бизнеса'
											sizeInput={SIZE_INPUT.SMALL}
											fetchOptions={getCategories as any}
											value={category}
											onChange={val => setValue('category', val)}
											showSearch={false}
											error={errors?.category}
										/>
									)
								}}
							/>
						</div>
					</Col>
				</Row>

				<div className={styles.buttons}>
					<AppButton type='button' variant='lightBlue' onClick={goBack}>
						Отмена
					</AppButton>

					<AppButton type='submit' disabled={requested}>
						Сохранить
					</AppButton>
				</div>
			</form>
		</div>
	)
}

export default EditMerchant
