import { Alert } from 'antd'
import clsx from 'clsx'
import {
	AppButton,
	AppInput,
	AppLoading,
	AppSwitch,
	SelectField,
} from 'components'
import { SIZE_INPUT } from 'constants/ComponentSize'
import { LEVEL_LOCALITIES } from 'constants/LevelLocalities'
import { TYPES } from 'constants/TypeResults'
import { BranchesApi } from 'services/api/BranchesApi'
import {
	BranchDetail,
	BranchDetail as BranchDetailInterface,
	BranchLocalitiesItem,
	Localities,
} from 'services/api/BranchesApi.models'
import { CashoutApi } from 'services/api/Cashout'
import { CashoutBranch } from 'services/api/Cashout/types'
import { store } from 'store'

import React from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { useResolvedPath } from 'react-router-dom'

import styles from './edit.module.scss'
import Maps from './components/Maps'
import { KG_REGIONS } from 'constants/regions'
import CashoutCommisssionEdit from 'common/Cashout/CashoutCommisssionEdit'

interface SetMarker {
	lat: number
	lng: number
	region: string
}

interface EditBranchForm {
	id?: string | number
	name: string
	company_owner: number
	region?: string
	rayon?: string
	ail_aimag?: string
	punkt?: string
	address: string
	house?: string
	postal_code?: string
	lat: number
	lon: number
	'lat-lang': string
	cash_out_commission_int: number | null
	cash_out_commission_percent: number | null
	commissionType: 'PROCENT' | 'MONEY'
	is_cash_out_available: boolean
}

interface SelectFields {
	regions?: BranchLocalitiesItem[]
	rayons?: BranchLocalitiesItem[]
	aiyl_aimags?: BranchLocalitiesItem[]
	villages?: BranchLocalitiesItem[]
}

interface EditProps {
	data: BranchDetailInterface
	getBranch: () => void
}

const Edit: React.FC<EditProps> = ({ data, getBranch }) => {
	const {
		control,
		register,
		handleSubmit,
		setValue,
		setError,

		clearErrors,
		formState: { errors },
	} = useForm<EditBranchForm>({
		defaultValues: {
			is_cash_out_available: data?.is_cash_out_available,
			cash_out_commission_int: data?.cash_out_commission_int,
			cash_out_commission_percent: data?.cash_out_commission_percent,
		},
	})
	const { resultRequestStore } = store

	const id = data.id

	const navigate = useNavigate()
	const parentPath = useResolvedPath('../').pathname

	const watchRegion = useWatch({ control, name: 'region' })
	const watchRayon = useWatch({ control, name: 'rayon' })
	const watchAyil = useWatch({ control, name: 'ail_aimag' })
	const watchPunkt = useWatch({ control, name: 'punkt' })
	const watchCashout = useWatch({ control, name: 'is_cash_out_available' })
	const watchLat = useWatch({ control, name: 'lat' })
	const watchLon = useWatch({ control, name: 'lon' })
	const commissionType = useWatch({
		control,
		name: 'commissionType',
		defaultValue:
			!!data?.cash_out_commission_percent &&
			// @ts-ignore
			data?.cash_out_commission_percent !== 0
				? 'PROCENT'
				: 'MONEY',
	})

	const [selectFields, seSelectFields] = React.useState<SelectFields>()
	const [branchDetail, setBranchDetail] = React.useState<BranchDetail>()

	const goBack = () => {
		navigate(parentPath)
	}

	const getBranches = async () => {
		const detailBranch = await BranchesApi.getDetailBranch(id)

		setDataToForm(detailBranch.data)
		setBranchDetail(detailBranch.data)
	}

	const setDataToForm = (data: BranchDetail) => {
		setValue('name', data?.name || '')
		setValue('postal_code', data?.house || '')

		data?.region?.id && setValue('region', data?.region.id || '')
		data?.rayon?.id && setValue('rayon', data?.rayon?.id || '')
		data?.ail_aimag?.id && setValue('ail_aimag', data?.ail_aimag?.id || '')
		data?.punkt?.id && setValue('punkt', data?.punkt?.id || '')
		data?.address && setValue('address', data?.address || '')
		data?.house && setValue('house', data?.house || '')
		data?.lat && setValue('lat', Number(data?.lat || '0') || 0)
		data?.lon && setValue('lon', Number(data?.lon || '0') || 0)

		setValue('lat-lang', `${data?.lat}, ${data?.lon} `)
	}

	const getLocalities = async (params?: Localities, key?: string) => {
		try {
			const { data } = await BranchesApi.getBranchLocalities(params)

			//@ts-ignore
			seSelectFields(state => ({ ...state, [key]: data }))
		} catch (error) {}
	}

	const updateBranch = async (form: EditBranchForm) => {
		if (!watchLat || !watchLon) {
			setError('lat', { message: 'Поставьте марку на карте' })

			return
		} else {
			clearErrors('lat')
		}

		form.id = id
		// @ts-ignore
		delete form['lat-lang']

		const cashoutData: CashoutBranch = {
			is_cash_out_available: form.is_cash_out_available,

			// @ts-ignore
			cash_out_commission_int:
				typeof form?.cash_out_commission_int === 'string' ||
				typeof form?.cash_out_commission_int === 'number'
					? Number(form.cash_out_commission_int)
					: null,
			// @ts-ignore
			cash_out_commission_percent:
				typeof form?.cash_out_commission_percent === 'string' ||
				typeof form?.cash_out_commission_percent === 'number'
					? Number(form?.cash_out_commission_percent)
					: null,
		}

		try {
			if (
				cashoutData.cash_out_commission_int ||
				cashoutData.cash_out_commission_percent
			) {
				await CashoutApi.createCashoutBranch({
					body: cashoutData,
					id: data?.id!,
				})
			}

			await BranchesApi.editBranch(form)

			resultRequestStore.setResultRequest({
				type: TYPES.success,
				code: '200',
				description: 'Вы отредактировали торговую точку',
			})

			getBranch()
		} catch (error) {
		} finally {
			goBack()
		}
	}

	const checkRegion = (region: string) => {
		const checkMarkRegion = KG_REGIONS.find(item => item.g_name === region)
		const currentRegion = selectFields?.regions?.find(
			item => item.id === watchRegion
		)

		return checkMarkRegion?.code === currentRegion?.code
	}

	const setMarker = ({ lat, lng, region }: SetMarker) => {
		const checkedRegion = !checkRegion(region)

		// if (!lat || !lng) {
		//     setError('lat', { message: 'Поставьте марку на карте' })

		//     return
		// } else if (region === 'not found') {
		//     clearErrors('lat')
		// } else if (checkedRegion) {
		//     setError('lat', {
		//         message:
		//             'Выбранное местоположение на карте не соответствует выбранному региону',
		//     })
		// } else if (region === '') {
		//     clearErrors('lat')
		// } else {
		//     clearErrors('lat')
		// }

		setValue('lat', lat)
		setValue('lon', lng)
		setValue('lat-lang', `${lat}, ${lng} `)
	}

	React.useEffect(() => {
		if (id) {
			getLocalities({ level: LEVEL_LOCALITIES.REGION }, 'regions')
			getBranches()
		}
	}, [id])

	React.useEffect(() => {
		if (watchRegion) {
			// @ts-ignore
			getLocalities(
				{ level: LEVEL_LOCALITIES.RAYON, parent: watchRegion },
				'rayons'
			)
		}
	}, [watchRegion])

	React.useEffect(() => {
		if (watchRayon) {
			getLocalities(
				{ level: LEVEL_LOCALITIES.AIYL_AIMAG, parent: watchRayon },
				'aiyl_aimags'
			)
		}
	}, [watchRayon])

	React.useEffect(() => {
		if (watchAyil) {
			getLocalities(
				{ level: LEVEL_LOCALITIES.VILLAGE, parent: watchAyil },
				'punkts'
			)
		}
	}, [watchAyil])

	const setLatLang = (latlang: string) => {
		const latLangs = latlang.split(',')

		setValue('lat', Number(latLangs[0]))
		setValue('lon', Number(latLangs[1]))
	}

	if (!branchDetail) {
		return <AppLoading />
	}

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<h3 className={styles.title}>Изменение торговой точки</h3>
			</div>

			{!data.is_address_editable && (
				<div className={styles.info}>
					<Alert
						message='Редактирование адреса недопускается при наличии активных ККМ.'
						type='info'
						showIcon
					/>
				</div>
			)}

			<form className={styles.content} onSubmit={handleSubmit(updateBranch)}>
				<div className={styles.column}>
					<div className={styles.item}>
						<AppInput
							sizeInput={SIZE_INPUT.SMALL}
							label='Название торговой точки'
							placeholder='Введите название'
							{...register('name', {
								required: 'Введите название компании',
							})}
							error={errors?.name}
						/>
					</div>

					<div className={styles.item}>
						<Controller
							control={control}
							name='region'
							render={() => {
								return (
									<SelectField
										label={'Регион'}
										value={watchRegion}
										showSearch
										onChange={val => {
											setValue('region', val.value)
											setValue('rayon', '')
											setValue('ail_aimag', '')
											setValue('punkt', '')
										}}
										// @ts-ignore
										options={selectFields?.regions}
										className={styles.selectField}
										optionLabelKey={'name'}
										sizeInput={SIZE_INPUT.SMALL}
										error={errors.region}
										disabled={!data.is_address_editable}
									/>
								)
							}}
						/>
					</div>

					<div className={styles.item}>
						<Controller
							control={control}
							name='rayon'
							render={() => {
								return (
									<SelectField
										label={'Район'}
										value={watchRayon}
										showSearch
										onChange={val => {
											setValue('rayon', val.value)
											setValue('ail_aimag', '')
											setValue('punkt', '')
										}}
										// @ts-ignore
										options={selectFields?.rayons}
										className={styles.selectField}
										optionLabelKey={'name'}
										sizeInput={SIZE_INPUT.SMALL}
										error={errors.rayon}
										disabled={!watchRegion || !data.is_address_editable}
									/>
								)
							}}
						/>
					</div>

					<div className={styles.item}>
						<Controller
							control={control}
							name='ail_aimag'
							render={() => {
								return (
									<SelectField
										label='Айыл аймак'
										value={watchAyil}
										showSearch
										onChange={val => {
											setValue('ail_aimag', val.value)
											setValue('punkt', '')
										}}
										// @ts-ignore
										options={selectFields?.aiyl_aimags}
										className={styles.selectField}
										optionLabelKey={'name'}
										sizeInput={SIZE_INPUT.SMALL}
										error={errors.ail_aimag}
										disabled={!watchRayon || !data.is_address_editable}
									/>
								)
							}}
						/>
					</div>

					<div className={styles.item}>
						<Controller
							control={control}
							name='punkt'
							render={() => {
								return (
									<SelectField
										label='Населенный пункт'
										value={watchPunkt}
										showSearch
										onChange={val => setValue('punkt', val.value)}
										// @ts-ignore
										options={selectFields?.punkts}
										className={styles.selectField}
										optionLabelKey={'name'}
										sizeInput={SIZE_INPUT.SMALL}
										error={errors.punkt}
										disabled={!watchAyil || !data.is_address_editable}
									/>
								)
							}}
						/>
					</div>

					<div className={styles.item}>
						<div className={styles.buttons}>
							<AppButton
								type='button'
								variant='lightBlue'
								onClick={goBack}
								className={styles.button}
							>
								Отмена
							</AppButton>

							<AppButton className={styles.button}>Сохранить</AppButton>
						</div>
					</div>
				</div>

				<div className={styles.column}>
					<div className={styles.item}>
						<AppInput
							sizeInput={SIZE_INPUT.SMALL}
							label='Улица'
							placeholder='Введите адресс'
							{...register('address', {
								required: 'Введите адресс',
							})}
							error={errors?.address}
							disabled={!data.is_address_editable}
						/>
					</div>

					<div className={styles.item}>
						<AppInput
							sizeInput={SIZE_INPUT.SMALL}
							label='Дом'
							placeholder='Введите номер дома'
							{...register('house')}
							disabled={!data.is_address_editable}
						/>
					</div>

					<div className={styles.item}>
						<AppInput
							sizeInput={SIZE_INPUT.SMALL}
							label='Почтовый индекс'
							placeholder='Введите почтовый индекс'
							{...register('postal_code')}
						/>
					</div>
					<AppInput
						type='text'
						sizeInput={SIZE_INPUT.SMALL}
						label='Координаты'
						placeholder='Введите Координаты'
						{...register('lat-lang')}
						// @ts-ignore
						onChange={e => setLatLang(e.target.value)}
					/>
					{watchCashout && (
						<CashoutCommisssionEdit
							className={clsx(
								styles.commision,
								!watchCashout && styles.disabled
							)}
							disbaled={!watchCashout}
							commissionType={commissionType}
							register={register}
							setValue={setValue}
						/>
					)}

					<div className={styles.actionItem}>
						<AppSwitch
							label={'Cashout:'}
							isChecked={watchCashout}
							onChange={() => setValue('is_cash_out_available', !watchCashout)}
						/>
					</div>
				</div>

				<div className={clsx(styles.column, styles.mapColumn)}>
					<Maps
						onClick={setMarker}
						initial={{ lat: watchLat, lng: watchLon }}
						disabled={!data.is_address_editable}
					/>

					{errors?.lat && (
						<p className={styles.error}>{errors?.lat?.message}</p>
					)}
				</div>
			</form>
		</div>
	)
}

export default Edit
