export const RouterConstants = {
  MAIN_PAGE: "/main",
  MONITORING: "/monitoring",
  AUTH: "/auth",
  AUTH_RESET: "/auth_reset",
  CLIENT: "/merchant",
  MERCHANT: "/merchant/",
  MERCHANTS: "/merchants/",
  RETAIL_OUTLETS: "/retail_outlets",
  SHIFTS: "/shifts",
  Shift: "/shift",
  CASHIERS: "/cashiers",
  CASHIER: "/cashier",
  REPORTS: "/reports",
  CREATE: "/create/",
  BRANCHES: "/branches",
  BRANCH: "/branch",
  CREATE_BRANCH: "create_branch",
  EDIT: "/edit/",
  ANALYTICS: "/analytics",

  ASIA: "/asia",

  MERCHANT_USER: "/merchant-user/",
};
