import clsx from 'clsx'
import { ALL_ICONS } from 'common/Icons/all-icons'

import { forwardRef } from 'react'

import s from './style.module.scss'

type arrTyp = (typeof ALL_ICONS)[number]

interface Props {
    className?: string
    width?: number
    height?: number
    id: arrTyp
    onClick?(): void
    style?: React.CSSProperties
}

const AppIcon = forwardRef<HTMLSpanElement, Props>(
    (
        { className, width = 24, height = 24, id, onClick, ...props }: Props,
        ref,
    ) => {
        return (
            <span ref={ref} {...props} onClick={onClick} className={className}>
                <svg
                    width={width}
                    height={height}
                    className={clsx(s.icon, onClick && s.pointer)}
                >
                    <use xlinkHref={`/icons-sprite.svg#${id}`} />
                </svg>
            </span>
        )
    },
)

export default AppIcon
