import { makeAutoObservable } from 'mobx'

class GlobalWindowApp {
	title: string = ''
	isOpen: boolean = false

	constructor() {
		makeAutoObservable(this)
	}

	setLoading(body: any) {
		this.isOpen = true
		this.title = body.title
		document.body.style.overflow = 'hidden'
	}
	stopLoading() {
		this.isOpen = false
		this.title = ''
		document.body.style.overflow = 'auto'
	}
}

export default GlobalWindowApp
