import React from 'react';
import {IconProps} from "../types";

const UserPlusIcon:React.FC<IconProps> = ({className}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className={className}>
            <g clipPath="url(#clip0_3622_28036)">
                <path
                    d="M15.3332 7.33337H13.9998V6.00004C13.9998 5.82323 13.9296 5.65366 13.8046 5.52864C13.6796 5.40361 13.51 5.33337 13.3332 5.33337C13.1564 5.33337 12.9868 5.40361 12.8618 5.52864C12.7367 5.65366 12.6665 5.82323 12.6665 6.00004V7.33337H11.3332C11.1564 7.33337 10.9868 7.40361 10.8618 7.52864C10.7367 7.65366 10.6665 7.82323 10.6665 8.00004C10.6665 8.17685 10.7367 8.34642 10.8618 8.47145C10.9868 8.59647 11.1564 8.66671 11.3332 8.66671H12.6665V10C12.6665 10.1769 12.7367 10.3464 12.8618 10.4714C12.9868 10.5965 13.1564 10.6667 13.3332 10.6667C13.51 10.6667 13.6796 10.5965 13.8046 10.4714C13.9296 10.3464 13.9998 10.1769 13.9998 10V8.66671H15.3332C15.51 8.66671 15.6796 8.59647 15.8046 8.47145C15.9296 8.34642 15.9998 8.17685 15.9998 8.00004C15.9998 7.82323 15.9296 7.65366 15.8046 7.52864C15.6796 7.40361 15.51 7.33337 15.3332 7.33337Z"
                    fill="#244F6D" />
                <path
                    d="M6 8C6.79112 8 7.56448 7.76541 8.22228 7.32588C8.88008 6.88635 9.39277 6.26164 9.69552 5.53074C9.99827 4.79983 10.0775 3.99556 9.92314 3.21964C9.7688 2.44372 9.38784 1.73098 8.82843 1.17157C8.26902 0.612165 7.55628 0.231202 6.78036 0.0768607C6.00444 -0.0774802 5.20017 0.00173314 4.46927 0.304484C3.73836 0.607234 3.11365 1.11992 2.67412 1.77772C2.2346 2.43552 2 3.20888 2 4C2.00106 5.06054 2.42283 6.07734 3.17274 6.82726C3.92266 7.57718 4.93946 7.99894 6 8ZM6 1.33334C6.52742 1.33334 7.04299 1.48973 7.48152 1.78275C7.92005 2.07577 8.26185 2.49224 8.46368 2.97951C8.66551 3.46678 8.71832 4.00296 8.61543 4.52024C8.51253 5.03753 8.25856 5.51268 7.88562 5.88562C7.51268 6.25856 7.03752 6.51254 6.52024 6.61543C6.00296 6.71832 5.46678 6.66551 4.97951 6.46368C4.49224 6.26185 4.07576 5.92005 3.78275 5.48152C3.48973 5.04299 3.33333 4.52742 3.33333 4C3.33333 3.29276 3.61428 2.61448 4.11438 2.11438C4.61448 1.61429 5.29276 1.33334 6 1.33334Z"
                    fill="#244F6D" />
                <path
                    d="M6 9.33337C4.40924 9.33514 2.88414 9.96785 1.75931 11.0927C0.634472 12.2175 0.00176457 13.7426 0 15.3334C0 15.5102 0.0702379 15.6798 0.195262 15.8048C0.320286 15.9298 0.489856 16 0.666667 16C0.843478 16 1.01305 15.9298 1.13807 15.8048C1.2631 15.6798 1.33333 15.5102 1.33333 15.3334C1.33333 14.0957 1.825 12.9087 2.70017 12.0335C3.57534 11.1584 4.76232 10.6667 6 10.6667C7.23768 10.6667 8.42466 11.1584 9.29983 12.0335C10.175 12.9087 10.6667 14.0957 10.6667 15.3334C10.6667 15.5102 10.7369 15.6798 10.8619 15.8048C10.987 15.9298 11.1565 16 11.3333 16C11.5101 16 11.6797 15.9298 11.8047 15.8048C11.9298 15.6798 12 15.5102 12 15.3334C11.9982 13.7426 11.3655 12.2175 10.2407 11.0927C9.11586 9.96785 7.59076 9.33514 6 9.33337Z"
                    fill="#244F6D" />
            </g>
            <defs>
                <clipPath id="clip0_3622_28036">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default React.memo(UserPlusIcon);