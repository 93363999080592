import WidgetApp from 'WidgetApp'

import { StyleProvider } from '@ant-design/cssinjs'
import { useEffect, useRef } from 'react'
import { createRoot } from 'react-dom/client'

const ShadowDOMWidjet = () => {
	const ref = useRef<any>()

	useEffect(() => {
		const shadowRoot = ref.current.attachShadow({ mode: 'open' })

		let link = document.createElement('link')
		link.setAttribute('rel', 'stylesheet')
		link.setAttribute(
			'href',
			'https://arm.mkassa.kg/static/css/arm-web-app.css'
		)
		shadowRoot.appendChild(link)

		const container = document.createElement('div')
		shadowRoot.appendChild(container)
		const root = createRoot(container)

		root.render(
			<StyleProvider container={shadowRoot}>
				<WidgetApp />
			</StyleProvider>
		)
	}, [])

	return <div className='MKASSA-ARM-WIDGET' ref={ref}></div>
}

export default ShadowDOMWidjet
