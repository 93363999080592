import TransactionDetailBranch from 'shared/common/reports/transaction-detail-branch'
import MerchantHeader from 'pages/MerchantDashboard/common/MerchantHeader'

export default function TransactionDetailBranchPage() {
	return (
		<div>
			<MerchantHeader />
			<div>
				<TransactionDetailBranch />
			</div>
		</div>
	)
}
