import React, { forwardRef } from 'react'
import { FieldError } from 'react-hook-form'

import s from './style.module.scss'

interface Props extends React.HTMLProps<HTMLInputElement> {
    label?: string

    error?: FieldError
}

const AppRadio = forwardRef<HTMLInputElement, Props>(
    ({ label, error, ...props }, ref) => {
        return (
            <div className={s.container}>
                <label className={s.label}>
                    <div>
                        <input
                            checked={true}
                            ref={ref}
                            type="radio"
                            {...props}
                        />
                    </div>
                    <p>{label}</p>
                </label>

                {error && <p className={s.error}>{error?.message}</p>}
            </div>
        )
    },
)

AppRadio.displayName = 'Radio'
export default AppRadio
