import { clientApi } from "./clientApi";
import { Pagination } from "models/pagination.models";
import {
  CashierDetail,
  CreateCashier,
  CreatePartnersMachinesData,
  ResetPasswordInterface,
} from "services/api/CashierApi.models";

export interface ResetCashier extends ResetPasswordInterface {
  id: string;
}

interface Props extends Pagination {
  company?: string;
  branches__in?: string;
  query?: string;
}

export const CashierApi = {
  getCashier(params?: Props) {
    return clientApi.get("crm/crm_cashiers/", { params });
  },
  getDetailCashier(id: string) {
    return clientApi.get<CashierDetail>(`crm/crm_cashiers/${id}/`);
  },
  createCashier(data: CreateCashier) {
    return clientApi.post(`crm/crm_cashiers/`, data);
  },
  editCashier(id: string | number, data: any) {
    return clientApi.put(`crm/crm_cashiers/${id}/`, data);
  },
  editCashierField(id: string | number, data: any) {
    return clientApi.patch(`crm/crm_cashiers/${id}/`, data);
  },
  resetPassword({ id, ...data }: ResetCashier) {
    return clientApi.post(`crm/crm_cashiers/${id}/reset_password/`, data);
  },
  generateStaticQr(id: number) {
    return clientApi.post(`crm/crm_cashiers/${id}/generate_static_qr/`);
  },
  ressetPasswordSMS(body: { cashier_id: number }) {
    return clientApi.post(`crm/crm_cashiers/reset_with_sms/`, body);
  },
  createPartnerMachines(body: CreatePartnersMachinesData) {
    return clientApi.post("crm/arm_partner_machines/", body)
  }
};
