import React, { useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { SelectProps, DefaultOptionType } from "antd/es/select";
import { AppSelect } from "components";
import { Spin } from "antd";

interface Props extends SelectProps {
  fetchOptions: (
    search: string,
    limit: number,
    offset: number
  ) => Promise<{ results: DefaultOptionType[]; count: number }>;
  debounceTimeout?: number;
  limit?: number;
}

const AppAsyncSelect: React.FC<Props> = ({
  fetchOptions,
  debounceTimeout = 300,
  limit = 20,
  ...restProps
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<DefaultOptionType[]>([]);
  const [total, setTotal] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState<string>("");

  const initialDataLoad = async () => {
    if (data.length) return;
    await fetchData();
  };

  const fetchData = async (search: string = "") => {
    setIsLoading(true);
    try {
      const response = await fetchOptions(search, limit, currentPage * limit);
      setData((prevData) => [...prevData, ...response.results]);
      setTotal(response.count);
      setCurrentPage((prevPage) => prevPage + 1);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (value: string) => {
    debounceFetchData(value);
    setSearchValue(value);
  };

  const debounceFetchData = useDebouncedCallback((value: string) => {
    setData([]);
    setCurrentPage(0);
    fetchData(value);
  }, debounceTimeout);

  const handlePopupScroll = (e: React.UIEvent) => {
    const target = e.target as HTMLDivElement;
    if (
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      total !== null &&
      data.length < total
    ) {
      fetchData(searchValue);
    }
  };

  return (
    <AppSelect
      showSearch
      filterOption={false}
      onSearch={handleSearch}
      loading={isLoading}
      onPopupScroll={handlePopupScroll}
      onDropdownVisibleChange={initialDataLoad}
      options={data}
      notFoundContent={isLoading ? <Spin size="small" /> : null}
      {...restProps}
    />
  );
};

export default AppAsyncSelect;
