import { useResolvedPath } from 'react-router-dom'

import { AppSwitch, DataView } from 'components'

import { MerchantsDetail } from 'services/api/MerchantsApi.models'

import styles from './view.module.scss'
import { User } from 'models/user.models'
import CashoutCommission from 'common/Cashout/CashoutCommission'
import { Alert } from 'antd'
import clsx from 'clsx'

interface Props {
	data: MerchantsDetail
	id?: string
}

const DataColumn = (data: MerchantsDetail) => [
	[
		{
			label: 'Ф.И.О. владельца мерчанта',
			dataIndex: ['owner.last_name', 'owner.first_name', 'owner.middle_name'],
		},
		{
			label: 'Номер телефона',
			dataIndex: 'owner.phone_number',
		},
		{
			label: 'E-mail',
			dataIndex: 'owner.email',
		},
		{
			label: 'Категория бизнеса',
			dataIndex: 'category.name',
		},
		{
			label: 'ИНН организации',
			dataIndex: 'inn',
		},
		{
			label: 'Логин',
			dataIndex: 'owner.login',
		},
		{
			label: 'РБС ID',
			dataIndex: 'rbs_id',
		},
	],

	[
		{
			label: 'Комисcия по QR Мбанк',
			dataIndex: 'mbank_qr_commission',
			prefix: '%',
		},
		{
			label: 'Комиcсия по QR другие',
			dataIndex: 'other_qr_commission',
			prefix: '%',
		},
		{
			slot: (
				<>
					<div
						className={clsx(!data.is_cash_out_available && styles.qrCashout)}
					>
						<AppSwitch
							variant='primary'
							disabled
							isChecked={data.is_cash_out_available}
							label='Выдача наличных QR MBANK'
						/>
						{!data.is_cash_out_available && (
							<Alert
								message='У вас отключена выдача наличных QR MBANK'
								type='warning'
								showIcon
								className={styles.qrCashoutDisclaimer}
							/>
						)}
					</div>
					<div style={{ margin: '12px 0' }} />
					<CashoutCommission
						disabled
						is_cash_enabled={data?.is_cash_enabled}
						cash_out_commission_percent={data?.cash_out_commission_percent}
						cash_out_commission_int={data?.cash_out_commission_int}
					/>
				</>
			),
		},
	],
]

const View: React.FC<Props> = ({ data }) => {
	return (
		<div className={styles.wrapper}>
			<h3 className={styles.title}>О мерчанте</h3>

			<DataView
				className={styles.merchant}
				// @ts-ignore
				columns={DataColumn(data)}
				data={data}
			/>
		</div>
	)
}

export default View
