import { DefaultOptionType, SelectProps } from "antd/es/select";
import AppAsyncSelect from "components/AppAsyncSelect";
import { MerchantDashboardApi } from "services/api/MerchantDashboard/MainApi";

interface Props extends SelectProps {
  companyId: number | string;
}

const CashRegisterSelect = (props: Props) => {
  const { companyId, ...restProps } = props;
  const fetchCashRegisterOptions = async (
    search: string,
    limit: number,
    offset: number
  ): Promise<{ results: DefaultOptionType[]; count: number }> => {
    const response = await MerchantDashboardApi.getCashRegister({
      query: search,
      limit,
      offset,
      company: String(companyId),
    });
    return {
      results: response.data.results.map((item: any) => ({
        label: item?.name,
        value: item.id,
      })),
      count: response.data.count,
    };
  };

  return (
    <AppAsyncSelect
      fetchOptions={fetchCashRegisterOptions}
      placeholder="Выберите ккм"
      {...restProps}
    />
  );
};

export default CashRegisterSelect;
