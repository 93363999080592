import { useDeleteReportMutation } from 'services/api/Reports/ReportsApi'
import { ReportStatusEnum } from 'services/api/Reports/ReportsApi.model'
import { downloadFile, downloadFileFromUrl } from 'utils'

import DeleteBtn from './components/DeleteBtn'
import DownloadBtn from './components/DownloadBtn'
import StopBtn from './components/StopBtn'
import styles from './styles.module.scss'
import useNotification from 'hooks/useNotification'

interface ActionsProps {
    id: number
    status: ReportStatusEnum
    fileLink: string
    name: string
}

const Actions = (props: ActionsProps) => {
    const { id, status, fileLink, name } = props
    const [deleteReportRequest] = useDeleteReportMutation()
    const { showSuccessNotification, showInfoNotification } = useNotification()

    const downloadReport = () => {
        downloadFileFromUrl(fileLink, name)
    }
    const stopGeneratingReport = () => {
        deleteReportRequest(id)
            .unwrap()
            .then(() => {
                showInfoNotification({
                    message: 'Отчет не сгенерирован',
                    description:
                        'Вы отменили генерацию отчета, но вы можете создать новый в любое время',
                })
            })
    }
    const deleteReport = async () => {
        deleteReportRequest(id)
            .unwrap()
            .then(() => {
                showSuccessNotification({
                    message: 'Отчет удален',
                    description:
                        'Отчет успешно удален, теперь вы можете сгенерировать новый',
                })
            })
    }

    switch (status) {
        case ReportStatusEnum.COMPLETED:
            return (
                <div className={styles.actions}>
                    <DownloadBtn
                        className={styles.btn}
                        onClick={downloadReport}
                    />
                    <DeleteBtn className={styles.btn} onClick={deleteReport} />
                </div>
            )
        case ReportStatusEnum.PROCESSING:
            return (
                <div className={styles.actions}>
                    <StopBtn
                        className={styles.btn}
                        onClick={stopGeneratingReport}
                    />
                </div>
            )

        case ReportStatusEnum.ERROR:
            return (
                <div className={styles.actions}>
                    <DeleteBtn className={styles.btn} onClick={deleteReport} />
                </div>
            )
        default:
            return null
    }
}

interface Props {
    id: number
    status: ReportStatusEnum
    fileLink: string
    name: string
}

const ReportActions = (props: Props) => {
    const { id, status, fileLink, name } = props
    return (
        <div className={styles.wrapper}>
            <Actions id={id} status={status} fileLink={fileLink} name={name} />
        </div>
    )
}

export default ReportActions
