import React from 'react';
import {IconProps} from "../types";

const Duplicate:React.FC<IconProps> = ({ className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className={className}>
            <g clipPath="url(#clip0_452_21001)">
                <path
                    d="M17.629 2.72667L15.7257 0.760833C15.4919 0.520805 15.2126 0.329867 14.9041 0.199218C14.5955 0.0685687 14.264 0.000838693 13.929 0L9.99984 0C9.03967 0.0012121 8.10927 0.333408 7.36546 0.940598C6.62165 1.54779 6.10992 2.39284 5.9165 3.33333H5.83317C4.72851 3.33466 3.66947 3.77407 2.88835 4.55518C2.10724 5.3363 1.66783 6.39534 1.6665 7.5V15.8333C1.66783 16.938 2.10724 17.997 2.88835 18.7782C3.66947 19.5593 4.72851 19.9987 5.83317 20H10.8332C11.9378 19.9987 12.9969 19.5593 13.778 18.7782C14.5591 17.997 14.9985 16.938 14.9998 15.8333V15.75C15.9403 15.5566 16.7854 15.0449 17.3926 14.301C17.9998 13.5572 18.332 12.6268 18.3332 11.6667V4.46667C18.3344 3.81701 18.0817 3.1926 17.629 2.72667ZM10.8332 18.3333H5.83317C5.17013 18.3333 4.53424 18.0699 4.0654 17.6011C3.59656 17.1323 3.33317 16.4964 3.33317 15.8333V7.5C3.33317 6.83696 3.59656 6.20107 4.0654 5.73223C4.53424 5.26339 5.17013 5 5.83317 5V11.6667C5.83449 12.7713 6.27391 13.8304 7.05502 14.6115C7.83614 15.3926 8.89517 15.832 9.99984 15.8333H13.3332C13.3332 16.4964 13.0698 17.1323 12.6009 17.6011C12.1321 18.0699 11.4962 18.3333 10.8332 18.3333ZM14.1665 14.1667H9.99984C9.3368 14.1667 8.70091 13.9033 8.23207 13.4344C7.76323 12.9656 7.49984 12.3297 7.49984 11.6667V4.16667C7.49984 3.50363 7.76323 2.86774 8.23207 2.3989C8.70091 1.93006 9.3368 1.66667 9.99984 1.66667H13.3332V3.33333C13.3332 3.77536 13.5088 4.19928 13.8213 4.51184C14.1339 4.82441 14.5578 5 14.9998 5H16.6665V11.6667C16.6665 12.3297 16.4031 12.9656 15.9343 13.4344C15.4654 13.9033 14.8295 14.1667 14.1665 14.1667Z"
                    fill="#0092A1" />
            </g>
            <defs>
                <clipPath id="clip0_452_21001">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default React.memo(Duplicate);
