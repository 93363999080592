import React from 'react';

interface Props {
    className?: string;
}
const ArrowSimple:React.FC<Props> = ({ className }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            className={className}
        >
            <path
                d="M12.5 15L7.5 10L12.5 5"
                stroke="#8B96B1"
                strokeWidth="1.6"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default React.memo(ArrowSimple);
