import React, {useMemo} from "react";

import {Table as AntTable} from 'antd';
import {uniqueId} from 'lodash';
import clsx from "clsx";

import styles from "./AppTable.module.scss";

type GetComponentProps<DataType> = (data: DataType, index?: number) => React.HTMLAttributes<any> | React.TdHTMLAttributes<any>;

interface Props {
    header?: React.ReactNode[];
    body?: React.ReactNode[][];
    columns?: any[],
    data?: any[],
    onChange?: (
        pagination: any,
        filters: any,
        sorter: any,
        extra: any
    ) => void;
    pagination?: any;
    onRow?: (data: GetComponentProps<any>, index?: number) => React.HTMLAttributes<any> | React.TdHTMLAttributes<any>;
    loading?: boolean;
    locale?: Object;
    className?: string;
}


const AppTable: React.FC<Props> = (props) => {

    const {
        columns,
        data,
        onChange,
        pagination = false,
        onRow,
        loading,
        locale,
        className,
        ...restProps
    } = props;

    return (
        <div className={clsx(styles.container, className)}>

            <AntTable
                {...restProps}
                columns={columns}
                dataSource={data}
                onChange={onChange}
                className={clsx(styles.table, onRow && styles.clickable)}
                pagination={pagination}
                onRow={onRow}
                loading={loading}
                //@ts-ignore
                showTotal={(total: string, range: string[]) => `${range[0]}-${range[1]} из  ${total}`}

                //@ts-ignore
                rowKey={(record: { id: number | string }) => record.id}
                locale={locale}
            />

        </div>
    );
};

export default AppTable;
