import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { AppStatusIndicator, AppTable } from "components";

import { STATUS_TEXT, TYPE_STATUS } from "constants/TypeStatus";
import {
  TYPE_TRANSACTION,
  TypeTransactionEnum,
} from "constants/transaction.model";

import { displayDate } from "utils";

import { useTableData } from "hooks";

import HeaderTransaction from "shared/common/reports";

import { QueryParamsModel } from "models/QueryParams.model";
import { TransactionItem } from "services/api/TransactionsApi.module";
import { TransactionsApi } from "services/api/TransactionsApi";

import styles from "./transactions-common.module.scss";
import { useAppSelector } from "hooks/useRedux";

const tableColumns = [
  {
    dataIndex: "created_at",
    title: "Время проведения",
    render: (value: string) => displayDate(value),
  },
  {
    dataIndex: "branch_id",
    title: "ID Торговой точки",
  },
  {
    dataIndex: "branch_name",
    title: "Торговая точка",
  },
  {
    dataIndex: "id",
    title: "№",
  },
  {
    dataIndex: "status",
    title: "Статус",
    render: (value: TYPE_STATUS) => (
      <div className={styles.status}>
        {<AppStatusIndicator status={value || ""} />}

        <span className={styles.name}>
          {STATUS_TEXT.find((item) => value === item.key)?.name}
        </span>
      </div>
    ),
  },

  {
    dataIndex: "transaction_type",
    title: "Способ оплаты",
    render: (value: TypeTransactionEnum) =>
      TYPE_TRANSACTION[value] || "Не определен тип",
  },

  {
    dataIndex: "sum",
    title: "Сумма",
    sorter: {
      compare: (a: TransactionItem, b: TransactionItem) => b.sum - a.sum,
      multiple: 3,
    },
  },
];

interface Props {
  params?: QueryParamsModel;
}

const TransactionsCommon: React.FC<Props> = ({ params }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const transactionsFilters = useAppSelector(
    (store) => store.transactionsFilters
  );

  const lteDate = transactionsFilters.endDate;
  const gteDate = transactionsFilters.startDate;
  const status = transactionsFilters.status;
  const cashier = transactionsFilters.cashier?.value;
  const branches = transactionsFilters.branch?.value;
  const transaction_type = transactionsFilters.type?.value;
  const sum = transactionsFilters.sum;
  const fiscalized = transactionsFilters.isFiscalized?.value;
  const cash_register = transactionsFilters.cashRegister?.value;

  const combineParams = {
    created_at__lte: lteDate,
    created_at__gte: gteDate,
    status: status,
    transaction_type,
    cashier,
    branches,
    sum,
    fiscalized,
    cash_register,
    ...params,
  };

  const {
    loading,
    tableData,
    handleTableChange,
    pagination,
    reloadData,
    // @ts-ignore
  } = useTableData(TransactionsApi.getTransactions as any, {
    ...combineParams,
  });

  const refreshData = () => {
    reloadData();
  };

  // const queryParams = useMemo(
  // 	() => Object.keys(params).map(key => `${key}=${params[key]}&`),
  // 	[params]
  // )

  const _params =
    /* @ts-ignore */
    params && Object.keys(params).map((key) => `${key}=${params[key]}&`);

  /* @ts-ignore */
  const totalSum = new Intl.NumberFormat().format(
    /* @ts-ignore */
    Number(tableData?.total_sum || 0)
  );

  useEffect(() => {
    if (!status) {
      searchParams.set("status", "paid");
      setSearchParams(searchParams, { replace: true });
    }
  });

  return (
    <div className={styles.container}>
      <HeaderTransaction
        refresh={refreshData}
        counters={tableData?.counters}
        params={params}
        totalSum={totalSum}
      />

      <div className={styles.table}>
        <AppTable
          columns={tableColumns}
          data={tableData.results}
          pagination={pagination}
          onChange={handleTableChange}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default TransactionsCommon;
