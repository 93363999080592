import { AppInput } from "components";
import { SearchIcon } from "components/icon";
import { DEFAULT_DEBOUNCE_DELAY } from "constants/common";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { setSum } from "store/rtk/slices/filters/transactions-filter.slice";
import { useDebouncedCallback } from "use-debounce";

interface Props {
  disabled?: boolean;
  className?: string;
}

const FilterBySum = (props: Props) => {
  const { disabled, className } = props;
  const dispatch = useAppDispatch();

  const sum = useAppSelector((store) => store.transactionsFilters.sum);

  const handleSumChange = useDebouncedCallback((value: string) => {
    if (value === "") {
      dispatch(setSum(null));
      return;
    }
    dispatch(setSum(Number(value)));
  }, DEFAULT_DEBOUNCE_DELAY);

  return (
    <AppInput
      placeholder="Поиск по сумме"
      icon={<SearchIcon />}
      onChange={(e) => handleSumChange(e.currentTarget.value)}
      className={className}
      disabled={disabled}
      defaultValue={sum ?? undefined}
    />
  );
};

export default FilterBySum;
