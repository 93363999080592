import React, { useMemo } from "react";

import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { uniqueId, get } from "lodash";

import { store } from "store";

import { RouterConstants } from "constants/routerConstants";

import { ArrowLong, ArrowSimple } from "components/icon";

import Item from "./components/Item/Item";

import styles from "./AppBreadСrumbs.module.scss";

interface Routes {
  [key: string]: {
    title?: string;
    entityKey?: string;
    fieldKey?: string;
    dependEntity?: string;
    routes?: Routes;
  };
}

interface Props {
  links?: {
    title: string;
    link: string;
  }[];
  routes?: Routes;
}

const convertToFlat = (root = "/", routeItems: any) => {
  let result = {};

  Object.keys(routeItems).forEach((routeKey) => {
    const { routes } = routeItems[routeKey];

    const { title, entityKey, fieldKey } = routeItems[routeKey];
    const pathKey = `${root}${routeKey}/`;

    if (title) {
      // @ts-ignore
      result[pathKey] = title;
    }

    if (entityKey && fieldKey) {
      // @ts-ignore
      const entity = store?.[entityKey];
      const isLoading = get(toJS(entity), "isLoading", false);
      if (isLoading) {
        // @ts-ignore
        return (result[pathKey] = "Загрузка...");
      }

      // @ts-ignore
      result[pathKey] = entity ? get(toJS(entity), fieldKey, "") : "";
    }

    if (routes) {
      const nestedRoutes = convertToFlat(pathKey, routes);
      result = { ...result, ...nestedRoutes };
    }
  });

  return result;
};

const AppBreadСrumbs: React.FC<Props> = observer(({ links, routes }) => {
  const navigate = useNavigate();
  const paths = convertToFlat("/", routes);
  const location = useLocation();

  const hasNestedRoutes = useMemo(() => !!Object.keys(paths).length, [paths]);

  const arrPath = useMemo(
    () => location.pathname.split("/").filter((str) => !!str),
    [location.pathname]
  );

  // const backNav = () => {
  //   if (arrPath.length > 3) {
  //     navigate(`/${arrPath[0]}/${arrPath[1]}/`);
  //   } else {
  //     navigate("/main/merchants/");
  //   }
  // };
  const backNav = () => {
    navigate(-1)
}

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div className={styles.backNav} onClick={backNav}>
           <div className={styles.icon}>
               <ArrowLong />
           </div>

           <span>Назад</span>
        </div>

        <NavLink to={`${RouterConstants.MERCHANT_USER}dashboard`} className={styles.link}>
          Главная
        </NavLink>

        {hasNestedRoutes && <ArrowSimple className={styles.separatorIcon} />}

        {Object.keys(paths).map((key) => (
          <Item
            key={uniqueId("breadKey")}
            // @ts-ignore
            routeItem={{ path: key, title: paths[key] }}
          />
        ))}
      </div>
    </div>
  );
});

export default AppBreadСrumbs;
