import React from 'react'
import { AppWrapperIcon } from 'components'
import { useForm } from 'react-hook-form'
import { TYPES } from 'constants/TypeResults'
import { ResetPasswordInterface } from 'services/api/MerchantsApi.models'
import { AppInput, AppButton } from 'components'
import { store } from 'store'
import { SIZE_INPUT } from 'constants/ComponentSize'

import styles from './resset-password.module.scss'

interface Props {
	nameCompany: string
	resetPasswordHandler(data: ResetPasswordInterface): void
}

export const ResetPassword: React.FC<Props> = ({
	nameCompany,
	resetPasswordHandler,
}) => {
	const { modalStore } = store

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<ResetPasswordInterface>()

	const [confirm, setConfirm] = React.useState<boolean>(false)
	const [formData, setFormData] = React.useState<ResetPasswordInterface>()

	const resetPassword = (data?: ResetPasswordInterface) => {
		if (!confirm) {
			setConfirm(true)
			setFormData(data)
			return
		}

		if (formData) {
			resetPasswordHandler(formData)
		}
	}

	const closeForm = (e: React.MouseEvent) => {
		e.stopPropagation()

		modalStore.resetData()
	}

	if (confirm) {
		return (
			<div className={styles.wrapperConfirm}>
				<AppWrapperIcon type={TYPES.warning} />

				<h3>Вы действительно хотите сбросить пароль?</h3>

				<div className={styles.buttons}>
					<div className={styles.button}>
						<AppButton onClick={() => resetPassword()}>Сбросить</AppButton>
					</div>

					<div className={styles.button}>
						<AppButton variant='white' onClick={closeForm}>
							Отмена
						</AppButton>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<AppWrapperIcon type={TYPES.warning} />

				<h3>Сброс пароля</h3>
			</div>

			<form className={styles.form} onSubmit={handleSubmit(resetPassword)}>
				<div className={styles.addText}>
					<p>{nameCompany}</p>
				</div>

				<div className={styles.line}>
					<AppInput
						label='Новый пароль'
						{...register('password1', {
							required: 'Введите пароль' as string,
						})}
						placeholder='Введите новый пароль'
						error={errors.password1}
						sizeInput={SIZE_INPUT.NORMAL}
					/>
				</div>

				<div className={styles.line}>
					<AppInput
						label='Подтвердите новый пароль'
						{...register('password2', {
							required: 'Введите пароль' as string,
						})}
						placeholder='Введите новый пароль еще раз'
						error={errors.password2}
						sizeInput={SIZE_INPUT.NORMAL}
					/>
				</div>

				<div className={styles.buttons}>
					<div className={styles.button}>
						<AppButton type='submit'>Сбросить</AppButton>
					</div>

					<div className={styles.button}>
						<AppButton variant='white' onClick={closeForm}>
							Отмена
						</AppButton>
					</div>
				</div>
			</form>
		</div>
	)
}
