import React from 'react'

import { CashierDetail } from 'services/api/CashierApi.models'

import { Route, Routes } from 'react-router-dom'
import View from './components/View'
import Edit from './components/Create'
// import Edit from "./components/Edit";

interface DetailProps {
	data: CashierDetail
	getCashier: () => void
}

const Detail: React.FC<DetailProps> = ({ data }) => {
	return (
		<Routes>
			<Route path='/edit/' element={<Edit data={data} />} />
			<Route path='/' element={<View data={data} />} />
		</Routes>
	)
}

export default Detail
