import React from 'react'

import { Route, Routes } from 'react-router'
import { observer } from 'mobx-react-lite'

import { store } from 'store'
import Table from './components/Table'
import Create from './components/Create'

const Cashiers = observer(() => {
	const { user } = store

	const canCreate = !user.actions.readOnly

	return (
		<Routes>
			<Route path='/' element={<Table canCreate={canCreate} />} />

			{canCreate && <Route path='/create/' element={<Create />} />}
		</Routes>
	)
})

export default Cashiers
