import { clientApi } from "./clientApi";
import { Pagination } from "models/pagination.models";
import {
  MerchantsList,
  MerchantsDetail,
  PreCreateMerchant,
  CreateMerchant,
  ResetPasswordInterface,
  TransitAccount,
  RkoAccountData,
  RKOBalance,
  TransitBalance,
} from "./MerchantsApi.models";

export interface GetMerchants extends Pagination {
  query?: string;
}

export interface MerchantCategory {
  id: number;
  name: string;
  code: string;
}

export interface PropsPreCreate {
  inn: string;
  rbs_id: number;
}

export interface ResetMerchants extends ResetPasswordInterface {
  id: string;
}

export const MerchantsApi = {
  getMerchants(params?: GetMerchants) {
    return clientApi.get<MerchantsList>("crm/crm_merchants/", { params });
  },
  getMerchantDetail(id: string) {
    return clientApi.get<MerchantsDetail>(`crm/crm_merchants/${id}/`);
  },
  preCreate(data: PropsPreCreate) {
    return clientApi.post<PreCreateMerchant>(
      `crm/crm_merchants/pre_create/`,
      data
    );
  },
  createMerchant(data: CreateMerchant) {
    return clientApi.post(`crm/crm_merchants/`, data);
  },
  editMerchant(id: number | string, data: CreateMerchant) {
    return clientApi.put(`crm/crm_merchants/${id}/`, data);
  },
  updateMerchantField(id: number, data: object) {
    return clientApi.patch(`crm/crm_merchants/${id}/`, data);
  },
  resetPassword({ id, ...data }: ResetMerchants) {
    return clientApi.post(`crm/crm_merchants/${id}/reset_password/`, data);
  },
  updateIcon(id: string | number, data: object) {
    return clientApi.post(`crm/crm_merchants/${id}/update_icon/`, data);
  },
  getMerchantCategories(params?: MerchantCategory[]) {
    return clientApi.get<MerchantsList>("crm/crm_merchants/categories/", {
      params,
    });
  },
  getMerchantTransitAccounts(id: number | string) {
    return clientApi.get<TransitAccount[]>(
      `crm/crm_merchants/${id}/transit_account/`
    );
  },
  getMerchantRkoAccounts(id: number | string) {
    return clientApi.get<RkoAccountData>(
      `crm/crm_merchants/${id}/rko_account/`
    );
  },
  getMerchantMessages(params: { merchantId: number }) {
    return clientApi.get<RkoAccountData>(`/crm/crm_merchant_comment/`, {
      params,
    });
  },
  createMerchantComment(body: { comment: string; merchant: number }) {
    return clientApi.post(`/crm/crm_merchant_comment/create_comment/`, body);
  },
  getMerchantBalanc({ id }: { id: number }) {
    return clientApi.get<TransitBalance>(
      `/crm/crm_merchants/${id}/main_transit_data/`
    );
  },
  getRkotBalance({ id }: { id: number }) {
    return clientApi.get<RKOBalance>(`/crm/crm_merchants/${id}/main_rko_data/`);
  },
  togglePrintKKM({ id }: { id: number }) {
    return clientApi.post<MerchantsDetail>(`/crm/crm_merchants/${id}/kkm-check/`);
  },
};
