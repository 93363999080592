const shiftDetail = {
  ":shiftId": {
    entityKey: "shiftStore",
    fieldKey: "detailInfo.id",
    routes: {
      transactions: {
        title: "Транзакции",
      },
    },
  },
};


const merchantBreadCrumbs =  {
  "merchant-user": {
    dashboard: ""
  }
}



const cashierDetail = {
  ":cashierId": {
    entityKey: "cashierStore",
    fieldKey: "detailInfo.user.first_name",
    routes: {
      detail: {
        title: "Данные",
        routes: {
          edit: {
            title: "Редактирование",
          },
        },
      },
      transactions: {
        title: "Транзакции",
      },
      shifts: {
        title: "Смены",
        routes: {
          ...shiftDetail,
        },
      },
    },
  },
};

const branchDetail = {
  ":branchId": {
    entityKey: "branchStore",
    fieldKey: "detailInfo.name",
    routes: {
      cashiers: {
        title: "Кассиры",
        routes: {
          create: {
            title: "Создание",
          },
          ...cashierDetail,
        },
      },
      detail: {
        title: "Данные",
        routes: {
          edit: {
            title: "Редактирование",
          },
        },
      },
      transactions: {
        title: "Транзакции",
      },
      shifts: {
        title: "Смены",
        routes: {
          ...shiftDetail,
        },
      },
      cash_register: {
        title: "ККМ",
      },
    },
  },
};

const merchantDetail = {
  ":merchantId": {
    entityKey: "merchantStore",
    fieldKey: "detailInfo.name",
    routes: {
      cashiers: {
        title: "Кассиры",
        routes: {
          create: {
            title: "Создание",
          },
          ...cashierDetail,
        },
      },
      detail: {
        title: "Данные",
        routes: {
          edit: {
            title: "Редактирование",
          },
        },
      },
      branches: {
        title: "Торговые точки",
        routes: {
          create: {
            title: "Создание",
          },
          ...branchDetail,
        },
      },
      reports: {
        title: "Отчеты",
      },
      shifts: {
        title: "Смены",
        routes: {
          ...shiftDetail,
        },
      },
      cash_register: {
        title: "ККМ",
      },
    },
  },
};

const branchDetailAsia = {
  brancheId: {
    title: "Кассиры по данному",
  },
};

const breadcrumbs = {
  main: {
    title: "",
    routes: {
      monitoring: {
        title: "",
        routes: {
          merchants: {
            title: "Мерчанты",
            routes: {
              create: {
                title: "Создание",
              },
              ...merchantDetail,
            },
          },
          branches: {
            title: "Торговые точки",
          },
          cashiers: {
            title: "Кассиры",
          },
        },
      },
    },
  },
  analytics: {
    title: "Модуль отчетов",
    routes: {
      payment: {
        title: "Общий",
      },
      "top-partners": {
        title: "ТОP партнеры",
      },
    },
  },
  asia: {
    title: "Азия",
    routes: {
      reports: {
        title: "Транзакции",
      },
      branches: {
        title: "Отделения",
        ...branchDetailAsia,
      },
      cashier: {
        title: "Кассиры",
      },
    },
  },
  reports: {
    title: "Аналитика"
  }
};

export default breadcrumbs;
