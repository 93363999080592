import React from "react";

import { useForm, useWatch, Controller } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { AppInput, AppCheckbox, AppButton, AppPasswordInput } from "components";
import { SIZE_INPUT } from "constants/ComponentSize";
import { UUID } from "constants/UUID";
import styles from "./AuthUser.module.scss";

interface AuthUser {
  device_uuid: string;
  login: string;
  password: string;
  saveUser?: boolean;
}

const COUNT_SYMBOL_LOGIN = 6;

interface Props {
  onSubmit(data: AuthUser): void;
}

const AuthUser: React.FC<Props> = ({ onSubmit }) => {
  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<AuthUser>({
    defaultValues: {
      device_uuid: UUID,
      saveUser: false,
    },
  });

  const saveUser = useWatch({ control, name: "saveUser" });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.fields}>
        <div className={styles.field}>
          <Controller
            control={control}
            name="login"
            rules={{
              required: "Введите логин",
              minLength: {
                value: COUNT_SYMBOL_LOGIN,
                message: "Логин состоит из 6 символов",
              },
              maxLength: {
                value: COUNT_SYMBOL_LOGIN,
                message: "Логин состоит из 6 символов",
              },
            }}
            render={() => {
              return (
                <AppInput
                  label="Логин"
                  sizeInput={SIZE_INPUT.NORMAL}
                  {...register("login", {
                    required: "Введите логин" as string,
                  })}
                  error={errors.login}
                />
              );
            }}
          />
        </div>

        <div className={styles.field}>
          <AppPasswordInput
            label="Пароль"
            {...register("password", {
              required: "Введите пароль" as string,
            })}
            error={errors.password}
          />
        </div>
      </div>

      <div className={styles.footer}>
        <div className={styles.checkbox}>
          <AppCheckbox
            name="saveUser"
            label="Запомнить меня"
            checked={saveUser}
            onClick={() => setValue("saveUser", !saveUser)}
          />
        </div>

        <div className={styles.button}>
          <AppButton type="submit">Войти</AppButton>
        </div>
      </div>
    </form>
  );
};

export default AuthUser;
