import { notification } from "antd";
import clsx from "clsx";
import { AppButton, AppDatePicker, AppFilterDate } from "components";
import { DownloadIcon, RefreshIcon } from "components/icon";
import { SIZE_BUTTON } from "constants/ComponentSize";
import { KEYS_DATE } from "constants/FilterDate";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { Counters } from "models/counters.model";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useBuildReportMutation } from "services/api/Reports/ReportsApi";
import { store } from "store";
import { API_DATE_FORMAT } from "utils";
import FilterByBranch from "./components/filters/FilterByBranch";
import FilterByCashier from "./components/filters/FilterByCashier";
import FilterByCashRegister from "./components/filters/FilterByCashRegister";
import FilterByFiscalized from "./components/filters/FilterByFiscalized";
import FilterByStatus from "./components/filters/FilterByStatus";
import FilterBySum from "./components/filters/FilterBySum";
import FilterByTransactionType from "./components/filters/FilterByTransactionType";
import SearchById from "./components/filters/SearchById";
import styles from "./header.module.scss";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { setDateRange } from "store/rtk/slices/filters/transactions-filter.slice";
import { QueryParamsModel } from "models/QueryParams.model";

const toQueryParams = (params: { [key: string]: any }) => {
  const queryParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      queryParams.append(key, value);
    }
  });

  return queryParams.toString();
};

export const FILTER_DATE = [
  {
    key: KEYS_DATE.TODAY,
    name: "День",
    param: dayjs().format(API_DATE_FORMAT),
  },
  {
    key: KEYS_DATE.THREE_DAYS,
    name: "3 дня",
    param: dayjs().subtract(2, "days").format(API_DATE_FORMAT),
  },
  {
    key: KEYS_DATE.WEEK,
    name: "Неделя",
    param: dayjs().subtract(6, "days").format(API_DATE_FORMAT),
  },
  {
    key: KEYS_DATE.TWO_WEEK,
    name: "2 недели",
    param: dayjs().subtract(13, "days").format(API_DATE_FORMAT),
  },
  {
    key: KEYS_DATE.MONTH,
    name: "Месяц",
    param: dayjs().startOf("month").format(API_DATE_FORMAT),
  },
];

interface Props {
  counters?: Counters;
  params?: QueryParamsModel;
  totalSum: number | string;
  merchantID?: string | number;
  refresh?: () => void;
  tableDataLoading?: boolean;
}

const HeaderTransaction: React.FC<Props> = observer(
  ({ counters, totalSum, merchantID, params, refresh, tableDataLoading }) => {
    const { user } = store;

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [api, contextHolder] = notification.useNotification();
    const [buildReport] = useBuildReportMutation();
    const dispatch = useAppDispatch();

    const transactionsFilters = useAppSelector(
      (store) => store.transactionsFilters
    );

    const gteDate = transactionsFilters.startDate;
    const lteDate = transactionsFilters.endDate;
    const [loading, setLoading] = useState(false);

    const companyId = user?.getUser()?.company_id;
    const today = dayjs().format(API_DATE_FORMAT);

    const openNotification = () => {
      api.info({
        message: `Внимание!`,
        description:
          "Началось скачивание отчетов! Это может занять некотрое время",
      });
    };

    const resetCurrentPage = () => {
      const currentPage = searchParams.get("current_page");
      if (currentPage === "1") return;
      searchParams.set("currentPage", "1");
      setSearchParams(searchParams);
    };

    const daysHandler = (item: {
      key: string;
      name: string;
      param: string;
    }) => {
      dispatch(setDateRange({ startDate: item.param, endDate: today }));
      resetCurrentPage();
    };

    const refreshData = () => {
      refresh && refresh();
    };

    const periodHandler = (values: [Date, Date]) => {
      const startDate = dayjs(values[0]).format(API_DATE_FORMAT);
      const endDate = dayjs(values[1]).format(API_DATE_FORMAT);
      dispatch(setDateRange({ startDate, endDate }));
      resetCurrentPage();
    };

    const filterValue = useMemo(
      () => FILTER_DATE.find((it) => it.param === gteDate)?.key,
      [gteDate]
    );

    const downloadFIle = async () => {
      const today = dayjs(gteDate);
      const threeMonthsAgo = dayjs(lteDate).subtract(3, "month");

      const isMoreThanThreeMonths = today.isBefore(threeMonthsAgo);

      const combineParams = {
        created_at__lte: transactionsFilters.endDate,
        created_at__gte: transactionsFilters.startDate,
        status: transactionsFilters.status,
        transaction_type: transactionsFilters.type?.value,
        cashier: transactionsFilters.cashier?.value,
        branches: transactionsFilters.branch?.value,
        cash_register: transactionsFilters.cashRegister?.value,
        sum: transactionsFilters.sum,
        id: transactionsFilters.transactionId,
        fiscalized: transactionsFilters.isFiscalized?.value,
        ...params,
      };

      let currentQuery = toQueryParams(combineParams)

      currentQuery = currentQuery.replace("status=all", "");

      if (isMoreThanThreeMonths) {
        notification.error({
          message: "Период не должен превышать 3-x месяцев",
        });

        return;
      }

      setLoading(true);

      try {
        await buildReport(
          `${
            currentQuery
              ? `?${currentQuery}&company=${companyId}`
              : `?company=${companyId}`
          }`
        ).unwrap();
        navigate(
          `/merchant-user/dashboard/reports-list/?active-tab=TRANSACTION_REPORT`
        );
        openNotification();
      } catch (e) {
      } finally {
        setLoading(false);
      }
    };

    const disabled = loading || tableDataLoading;
    function disabledDate(current: any) {
      return current && current > moment().endOf("day");
    }

    return (
      <>
        {contextHolder}

        <div className={styles.container}>
          <div className={styles.header}>
            <div className={clsx([styles.filter, disabled && styles.disabled])}>
              <AppFilterDate
                filter={FILTER_DATE}
                current={filterValue || undefined}
                onChange={daysHandler}
                disabled={tableDataLoading}
              />
              <div style={{ marginBottom: "20px" }} />
              <div className={styles.filterRow}>
                <AppButton
                  onClick={refreshData}
                  iconRight={
                    <RefreshIcon
                      color={"#fff"}
                      className={styles.refreshIcon}
                    />
                  }
                  disabled={tableDataLoading}
                  className={styles.refresh}
                >
                  Обновить
                </AppButton>
                {user.user?.user_type !== "CASHIER" && (
                  <FilterByCashier
                    placeholder="Поиск по кассирам"
                    disabled={tableDataLoading}
                    className={styles.select}
                  />
                )}
                {user.user?.user_type !== "CASHIER" && (
                  <FilterByBranch
                    placeholder="Поиск по торговым точкам"
                    disabled={tableDataLoading}
                    className={styles.input}
                  />
                )}
                {user.user?.user_type !== "CASHIER" && (
                  <SearchById disabled={disabled} className={styles.input} />
                )}
              </div>
            </div>

            <div className={styles.filters}>
              <AppDatePicker
                disabledDate={disabledDate}
                value={gteDate ? [gteDate, lteDate] : null}
                onChange={periodHandler}
                disabled={disabled || tableDataLoading}
                allowClear={false}
              />
              <FilterBySum disabled={disabled} className={styles.input} />
            </div>
          </div>

          <div className={styles.statuses}>
            <FilterByStatus
              totalSum={totalSum}
              counters={counters}
              disabled={disabled}
            />

            <div className={styles.selects}>
              <FilterByTransactionType
                disabled={disabled}
                className={styles.selectField}
              />
            </div>
          </div>

          <div className={styles.buttons}>
            <div className={styles.leftBlock}>
              <FilterByFiscalized
                className={styles.selectField}
                disabled={disabled}
              />
              {companyId && (
                <FilterByCashRegister
                  placeholder="Поиск по ккм"
                  companyId={companyId}
                  className={styles.selectField}
                  disabled={disabled}
                />
              )}
            </div>

            <AppButton
              iconLeft={<DownloadIcon />}
              size={SIZE_BUTTON.SMALL}
              className={styles.export}
              onClick={downloadFIle}
              disabled={!counters?.all || !gteDate || !lteDate || loading}
            >
              Сформировать отчет
            </AppButton>
          </div>
        </div>
      </>
    );
  }
);

export default HeaderTransaction;
