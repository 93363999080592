import {makeAutoObservable} from 'mobx';

import {MerchantsDetail, PreCreateMerchant} from "services/api/MerchantsApi.models";
import {MerchantsApi} from "services/api/MerchantsApi";

class MerchantStore {
    detailInfo: MerchantsDetail | null = null;
    isLoading = false;

    createData: PreCreateMerchant | null = null;
    step = 1;

    constructor() {
        makeAutoObservable(this);
    }

    setLoading(val: boolean) {
        this.isLoading = val;
    }

    setDetail(data: MerchantsDetail | null) {
        this.detailInfo = data;
    }

    getDetailInfo() {
        return this.detailInfo;
    }

    async getAsyncDetail(id: string | number | undefined) {
        if (!id) return ;
        this.setDetail(null);

        if (!Number.isInteger(+id)) {
            return
        }

        this.setLoading(true);

        try {
            const {data} = await MerchantsApi.getMerchantDetail(id as string);

            this.setDetail(data);
        } catch (error) {
        }

        this.setLoading(false);
    }

    setStep(num: number) {
        this.step = num;
    }

    setCreateData(data: PreCreateMerchant | null) {
        this.createData = data;
    }

    getCreateData() {
        return this.createData;
    }

    clearState() {
        this.setDetail(null);
        this.setCreateData(null);
        this.setStep(1);
    }
}

export default MerchantStore;
