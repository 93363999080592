export type DevicesType =
  | "MOBILE_APP"
  | "SMARTPOS_MBANK"
  | "SMARTPOS_PAYMOB"
  | "MACHINE";

export enum EDevices {
  MOBILE_APP = "MOBILE_APP",
  SMARTPOS_MBANK = "SMARTPOS_MBANK",
  SMARTPOS_PAYMOB = "SMARTPOS_PAYMOB",
  MACHINE = "MACHINE",
}
