import styles from "./HadbleValueCard.module.scss";
import { useState } from "react";
import EyesIcon from "components/icon/EyesIcon";
import AppButton from "components/AppButton";
import { RefreshIcon } from "components/icon";

interface Props {
  value: string | number;
  title: string;
  onRefresh?(): void;
}

const HadbleValueCard = ({ value = 1230, title, onRefresh }: Props) => {
  const [isShow, setIsShow] = useState<boolean>(false);
  return (
    <article className={styles.container}>
      <div>
        <p>{title}</p>

        <h3>{isShow ? value : "****"}</h3>
      </div>

      <div className={styles.buttons}>
        <AppButton
          className={styles.button}
          variant="outline"
          onClick={() => onRefresh && onRefresh()}
        >
          <RefreshIcon />
        </AppButton>

        <AppButton
          className={styles.button}
          variant="outline"
          onClick={() => setIsShow(!isShow)}
        >
          <EyesIcon show={isShow} className={styles.icon} />
        </AppButton>
      </div>
    </article>
  );
};

export default HadbleValueCard;
