import clsx from "clsx";
import React from "react";

import { FieldError } from "react-hook-form";
import { AppFieldWrapper } from "../AppFieldWrapper";
import { SIZE_INPUT } from "constants/ComponentSize";

import styles from "./AppInput.module.scss";

export interface AppInputProps extends React.HTMLProps<HTMLInputElement> {
  placeholder?: string;
  label?: string;
  error?: FieldError;
  sizeInput?: SIZE_INPUT;
  icon?: React.ReactNode;
  className?: string;
}

const AppInput = React.forwardRef<HTMLInputElement, AppInputProps>(
  (
    {
      placeholder,
      label,
      children,
      error,
      icon,
      sizeInput = SIZE_INPUT.SMALL,
        className,
      ...props
    },
    ref
  ) => {
    const variantSize = {
      small: styles.small,
      normal: styles.normal,
    };

    return (
      <div className={styles.container}>
        <AppFieldWrapper label={label}>
          <input
              {...props}
              ref={ref}
            className={clsx(styles.input, variantSize[sizeInput], className)}
            placeholder={placeholder}
          />
        </AppFieldWrapper>
        {children}
        <span className={styles.icon}>{icon}</span>
        <div className={styles.error}>{error?.message}</div>
      </div>
    );
  }
);

AppInput.displayName = "AppInput";

export default AppInput;
