const UserIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_696_3898)">
        <path
          d="M10 10C10.9889 10 11.9556 9.70676 12.7779 9.15735C13.6001 8.60794 14.241 7.82705 14.6194 6.91342C14.9978 5.99979 15.0969 4.99446 14.9039 4.02455C14.711 3.05465 14.2348 2.16373 13.5355 1.46447C12.8363 0.765206 11.9454 0.289002 10.9755 0.0960758C10.0055 -0.0968503 9.00021 0.00216643 8.08658 0.380605C7.17295 0.759043 6.39206 1.39991 5.84265 2.22215C5.29324 3.0444 5 4.0111 5 5C5.00132 6.32568 5.52853 7.59668 6.46593 8.53407C7.40332 9.47147 8.67432 9.99868 10 10ZM10 1.66667C10.6593 1.66667 11.3037 1.86217 11.8519 2.22844C12.4001 2.59471 12.8273 3.1153 13.0796 3.72439C13.3319 4.33348 13.3979 5.0037 13.2693 5.6503C13.1407 6.29691 12.8232 6.89085 12.357 7.35703C11.8908 7.8232 11.2969 8.14067 10.6503 8.26929C10.0037 8.3979 9.33348 8.33189 8.72439 8.0796C8.1153 7.82731 7.59471 7.40007 7.22843 6.8519C6.86216 6.30374 6.66667 5.65927 6.66667 5C6.66667 4.11595 7.01786 3.2681 7.64298 2.64298C8.2681 2.01786 9.11594 1.66667 10 1.66667Z"
          fill="#7893B0"
        />
        <path
          d="M10 11.667C8.01155 11.6692 6.10518 12.4601 4.69914 13.8661C3.29309 15.2722 2.50221 17.1785 2.5 19.167C2.5 19.388 2.5878 19.6 2.74408 19.7562C2.90036 19.9125 3.11232 20.0003 3.33333 20.0003C3.55435 20.0003 3.76631 19.9125 3.92259 19.7562C4.07887 19.6 4.16667 19.388 4.16667 19.167C4.16667 17.6199 4.78125 16.1362 5.87521 15.0422C6.96917 13.9482 8.4529 13.3337 10 13.3337C11.5471 13.3337 13.0308 13.9482 14.1248 15.0422C15.2188 16.1362 15.8333 17.6199 15.8333 19.167C15.8333 19.388 15.9211 19.6 16.0774 19.7562C16.2337 19.9125 16.4457 20.0003 16.6667 20.0003C16.8877 20.0003 17.0996 19.9125 17.2559 19.7562C17.4122 19.6 17.5 19.388 17.5 19.167C17.4978 17.1785 16.7069 15.2722 15.3009 13.8661C13.8948 12.4601 11.9884 11.6692 10 11.667Z"
          fill="#7893B0"
        />
      </g>
      <defs>
        <clipPath id="clip0_696_3898">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UserIcon;
