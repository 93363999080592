import AppDatePicker from 'components/AppDatePicker'
import { useSearchParams } from 'react-router-dom'

import dayjs from 'dayjs'
import { API_DATE_FORMAT } from 'utils'

import s from './shift-filter.module.scss'

interface IProps {
	tableDataLoading?: boolean
}

const DEFAULT_DATE_GTE = dayjs().format(API_DATE_FORMAT)
const DEFAULT_DATE_LTE = dayjs().format(API_DATE_FORMAT)

export default function ShiftFilter({tableDataLoading}: IProps) {
	const [searchParams, setSearchParams] = useSearchParams()

	const gteDate = searchParams.get('start_gte') || DEFAULT_DATE_GTE
	const lteDate = searchParams.get('start_lte') || DEFAULT_DATE_LTE

	const periodHandler = (values: [Date, Date] | null) => {
		if (values) {
			searchParams.set(
				'start_gte',
				values ? dayjs(values[0]).format(API_DATE_FORMAT) : ''
			)
			searchParams.set(
				'start_lte',
				values ? dayjs(values[1]).format(API_DATE_FORMAT) : ''
			)
		} else {
			searchParams.delete('start_gte')
			searchParams.delete('start_lte')
		}

		setSearchParams(searchParams, {replace: true})
	}

	function disabledDate(current: any) {
		return current && current > dayjs().endOf('day')
	}
	return (
		<section className={s.header}>
			<AppDatePicker
				disabledDate={disabledDate}
				value={gteDate ? [gteDate, lteDate] : null}
				onChange={periodHandler}
				disabled={tableDataLoading}
				allowClear={false}
			/>
		</section>
	)
}
