import { makeAutoObservable } from 'mobx'

class QueryFilterParams {
	filters: {
		[key: string]: any
	} = {}

	constructor() {
		makeAutoObservable(this)
	}

	setFilter(filter: { key: string; value: any }) {
		this.filters[filter.key] = filter.value
	}
}

export default QueryFilterParams
