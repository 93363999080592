import { clientApi } from "./clientApi";
import { Pagination } from "models/pagination.models";
import {
  BranchLocalitiesItem,
  Localities,
  CreateBranch,
  BranchDetail,
  CashierInBranch,
  EditBranch,
} from "./BranchesApi.models";

interface Props extends Pagination {
  company?: string;
  branches__in?: string;
  query?: string;
}

export const BranchesApi = {
  getListBranches(params?: Props) {
    return clientApi.get(`crm/crm_branches/`, { params });
  },
  getBranchLocalities(params?: Localities) {
    return clientApi.get<BranchLocalitiesItem[]>(
      `crm/crm_branches/localities/`,
      { params }
    );
  },
  getDetailBranch(id: string | number) {
    return clientApi.get<BranchDetail>(`crm/crm_branches/${id}/`);
  },
  getCashierInBranch(id: string | number) {
    return clientApi.get<CashierInBranch[]>(
      `/crm/crm_branches/${id}/cashiers/`
    );
  },
  editBranch({ id, ...data }: EditBranch) {
    return clientApi.put(`/crm/crm_branches/${id}/`, data);
  },
  createBranch(data: CreateBranch) {
    return clientApi.post(`crm/crm_branches/`, data);
  },
  updateBranchField(id: number | string, data: any) {
    return clientApi.patch(`crm/crm_branches/${id}/`, data);
  },
  addCashierBranch(id: number | string, data: any) {
    return clientApi.patch(`crm/crm_branches/${id}/cashiers/`, data);
  },
  generateStaticQr(id: number) {
    return clientApi.post(`crm/crm_branches/${id}/generate_static_qr/`)
  },
  createNFTtoApp(id: number | string) {
    return clientApi.post(`/crm/crm_branches/${id}/push_nfc_link/`)
  }
};
