import React from 'react';
import {IconProps} from "../types";

const ArrowLong: React.FC<IconProps> = ({className}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            className={className}
        >
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M9.53033 4.28033C9.82322 3.98744 9.82322 3.51256 9.53033 3.21967C9.23744 2.92678 8.76256 2.92678 8.46967 3.21967L3.21967 8.46967C2.92678 8.76256 2.92678 9.23744 3.21967 9.53033L8.46967 14.7803C8.76256 15.0732 9.23744 15.0732 9.53033 14.7803C9.82322 14.4874 9.82322 14.0126 9.53033 13.7197L5.56066 9.75H14.25C14.6642 9.75 15 9.41421 15 9C15 8.58579 14.6642 8.25 14.25 8.25H5.56066L9.53033 4.28033Z"
                  fill="#0092A1" />
        </svg>
    );
};

export default React.memo(ArrowLong);