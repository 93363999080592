import { AppModal, AppResultModal } from 'components'
import { ConfigProvider } from 'antd'
import ru_RU from 'antd/es/locale/ru_RU'

import './styles/globals.scss'
import HashRouting from 'router/Hashrouteer'

function WidgetApp() {
	return (
		<html className='mkassa-arm-widget'>
			<body>
				<div id='root-arm'>
					<ConfigProvider locale={ru_RU}>
						<HashRouting />
						<AppModal />
						<AppResultModal />
					</ConfigProvider>
				</div>
			</body>
		</html>
	)
}

export default WidgetApp
