export const KG_REGIONS = [
    {
        option: 'Жалал-Абад',
        name: '40.9332,72.9815',
        g_name: 'Jalal-Abad Region',
        code: '41703000000000',
    },
    {
        option: 'Бишкек',
        name: '42.8746,74.5698',
        g_name: 'Bishkek City',
        code: '41711000000000',
    },
    {
        option: 'Ош',
        name: '40.5140,72.8161',
        g_name: 'Osh City',
        code: '41721000000000',
    },
    {
        option: 'Баткен',
        name: '40.0548,70.8209',
        g_name: 'Batken Region',
        code: '41705000000000',
    },
    {
        option: 'Талас',
        name: '42.5318,72.2305',
        g_name: 'Talas Region',
        code: '41707000000000',
    },
    {
        option: 'Нарын',
        name: '41.4274,75.9841',
        g_name: 'Naryn Region',
        code: '41704000000000',
    },
    {
        option: 'Чуй',
        name: '42.5655,74.4057',
        g_name: 'Chüy Region',
        code: '41708000000000',
    },
    {
        option: 'Иссык-Куль',
        name: '42.3883,77.2865',
        g_name: 'Issyk-Kul Region',
        code: '41702000000000',
    },
    {
        option: 'Ошская область',
        name: '40.5140,72.8161',
        g_name: 'Osh Region',
        code: '41706000000000',
    },
]
