import React from "react";

import { QRCode, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { uniqueId } from "lodash";
import clsx from "clsx";

import { store } from "store";

import {
  AppsIcon,
  DownloadIcon,
  PencilIcon,
  PlusIcon,
  RefreshIcon,
} from "components/icon";

import { AppButton, AppInput, ClipBoardCopy } from "components";

import { SIZE_BUTTON } from "../../constants/ComponentSize";

import styles from "./QrGenerator.module.scss";
import { BranchesApi } from "services/api/BranchesApi";
import { TYPES } from "constants/TypeResults";

interface QrGenerator {
  value: string;
  nfcValue?: string;
  onGenerate: () => void;
  loading?: boolean;
  disabled?: boolean;
  branchId: number | string;
}

const id = uniqueId("qrId");

const QrGenerator: React.FC<QrGenerator> = observer((props) => {
  const {
    value: qrValue,
    nfcValue,
    onGenerate,
    loading,
    disabled,
    branchId,
  } = props;

  const { modalStore, resultRequestStore } = store;

  const downloadQRCode = () => {
    const canvas = document
      .getElementById(id)
      ?.querySelector<HTMLCanvasElement>("canvas");
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement("a");
      a.download = "QRCode.png";
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const addNFCtoApp = async () => {
    try {
      await BranchesApi.createNFTtoApp(branchId);
      resultRequestStore.setResultRequest({
        type: TYPES.success,
        code: "200",
        description: "Вы добавили NFT В приложение",
      });
    } catch (error) {}
  };


  const onOpenModal = () => {
    modalStore.openModal({
      body: (
        <Spin spinning={loading}>
          <div className={styles.modalContent}>
            <div className={styles.icon}>
              <AppsIcon />
            </div>

            <h5 className={styles.title}>
              {qrValue ? "QR код" : "Добавление QR кода"}
            </h5>

            {qrValue && (
              <>
                <div className={styles.qr}>
                  <QRCode
                    value={qrValue}
                    size={200}
                    bordered={false}
                    status={!qrValue ? "expired" : "active"}
                  />
                </div>

                <div className={styles.qr_hiden} id={id}>
                  <QRCode
                    value={qrValue}
                    size={900}
                    bordered={false}
                    status={!qrValue ? "expired" : "active"}
                  />
                </div>

                <div className={styles.input}>
                  <AppInput
                    label={"Ссылка на QR код"}
                    className={styles.field}
                    value={qrValue}
                    disabled
                  />

                  <ClipBoardCopy
                    text={qrValue}
                    disabled={!qrValue}
                    className={styles.copy}
                  />
                </div>

                {nfcValue && (
                  <div className={styles.input}>
                    <AppInput
                      label={"Ссылка для NFC"}
                      className={styles.field}
                      value={nfcValue}
                      disabled
                    />

                    <ClipBoardCopy
                      text={nfcValue}
                      disabled={!nfcValue}
                      className={styles.copy}
                    />
                  </div>
                )}
              </>
            )}

            <div className={clsx(styles.buttons, qrValue && styles.hasValue)}>
              {!disabled && (
                <AppButton
                  size={SIZE_BUTTON.SMALL}
                  variant={qrValue ? "lightBlue" : "siren"}
                  iconLeft={qrValue ? <RefreshIcon /> : null}
                  onClick={() => {
                    onGenerate();
                    modalStore.closeModal();
                  }}
                >
                  Новый QR
                </AppButton>
              )}

              {!qrValue && (
                <AppButton
                  size={SIZE_BUTTON.SMALL}
                  variant="white"
                  onClick={() => modalStore.closeModal()}
                >
                  Отмена
                </AppButton>
              )}

              {qrValue && (
                <AppButton
                  size={SIZE_BUTTON.SMALL}
                  iconLeft={<DownloadIcon />}
                  onClick={downloadQRCode}
                >
                  Скачать QR
                </AppButton>
              )}
              <AppButton
                size={SIZE_BUTTON.SMALL}
                iconLeft={<PlusIcon />}
                onClick={addNFCtoApp}
              >
                Добавить NFC в приложение
              </AppButton>
            </div>
          </div>
        </Spin>
      ),
    });
  };

  return (
    <div className={styles.wrapper} onClick={onOpenModal}>
      <QRCode value={qrValue || ""} size={90} bordered={false} />

      {!qrValue && (
        <div className={styles.emptyLabel}>Сгенерировать QR код</div>
      )}

      {!disabled && (
        <button className={styles.editQr}>
          <PencilIcon />
        </button>
      )}
    </div>
  );
});

export default QrGenerator;
