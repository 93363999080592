import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import { AppSwitch, DataView } from 'components'
import { DEFAULT_THEME } from 'constants/Theme'
import { BranchDetail as BranchDetailInterface } from 'services/api/BranchesApi.models'

import React from 'react'
import { useResolvedPath } from 'react-router-dom'

import styles from './view.module.scss'
import CashoutCommission from 'common/Cashout/CashoutCommission'

const defaultOption = {
	panControl: true,
	ClickableIcons: false,
	styles: DEFAULT_THEME,
}

const DataColumn = (data: BranchDetailInterface) => [
	[
		{
			dataIndex: 'merchant.name',
			label: 'Мерчант',
		},
		{
			dataIndex: 'region.name',
			label: 'Регион',
		},
		{
			dataIndex: 'rayon.name',
			label: 'Район',
		},
		{
			dataIndex: 'ail_aimag.name',
			label: 'Айыл аймак',
		},
		{
			dataIndex: 'punkt.name',
			label: 'Населенный пункт',
		},
		{
			dataIndex: 'address',
			label: 'Адрес',
		},
		{
			dataIndex: 'house',
			label: 'Номер дома',
		},
	],
	[
		{
			dataIndex: 'cashier_count',
			label: 'Кассиры',
		},
		{
			dataIndex: 'cash_register_count',
			label: 'Кассы',
		},
		{
			slot: (
				<>
					<div className={styles.actionItem}>
						<AppSwitch
							variant='primary'
							label='Выдача наличных QR MBANK'
							isChecked={data?.is_cash_out_available}
							onChange={() => {}}
						/>
					</div>
					<CashoutCommission
						disabled
						is_cash_enabled={data?.is_cash_enabled}
						// @ts-ignore
						cash_out_commission_percent={data?.cash_out_commission_percent}
						// @ts-ignore
						cash_out_commission_int={data?.cash_out_commission_int}
					/>
				</>
			),
		},
	],
]

interface DetailProps {
	data: BranchDetailInterface
}

const View: React.FC<DetailProps> = ({ data }) => {
	const pathname = useResolvedPath('').pathname

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'AIzaSyA--ZDK5A3ldrQ0EizoguDci2PknGXxfRE',
	})

	return (
		<div className={styles.wrapper}>
			<h3 className={styles.title}>Данные торговой точки</h3>

			<div className={styles.data}>
				<DataView
					className={styles.personal}
					// @ts-ignore
					columns={DataColumn(data)}
					data={data}
					// edtLink={`${pathname}edit/`}
				/>
			</div>

			<div className={styles.map}>
				{isLoaded && (
					<GoogleMap
						zoom={12}
						mapContainerStyle={{
							width: '100%',
							height: '300px',
						}}
						center={{
							lat: Number(data?.lat || 0),
							lng: Number(data?.lon || 0),
						}}
						options={defaultOption}
					>
						<Marker
							position={{
								lat: Number(data?.lat || 0),
								lng: Number(data?.lon || 0),
							}}
						/>
					</GoogleMap>
				)}
			</div>
		</div>
	)
}

export default View
