import { notification } from 'antd'
import AppIcon from 'common/AppIcon'

const DEFAULT_SUCCESS_MSG = 'Успешно'
const DEFAULT_SUCCESS_DESCRIPTION = 'Операция успешно выполнена'

const DEFAULT_ERROR_MSG = 'ОШИБКА'
const DEFAULT_ERROR_DESCRIPTION =
    'Сервер не может обработать запрос к сайту, на странице которого вы находитесь'

interface NotificationProps {
    message?: string
    description?: string
}

interface InfoNotificationProps {
    message: string
    description: string
}

const Message = ({ message }: { message: string }) => {
    return (
        <div
            style={{
                color: '#FFFFFF',
                fontWeight: 700,
                fontSize: 18,
            }}
        >
            {message}
        </div>
    )
}

const Description = ({ description }: { description: string }) => {
    return <div style={{ color: '#FFFFFF', opacity: 0.8 }}>{description}</div>
}

const CloseIcon = () => {
    return (
        <AppIcon
            id="close"
            style={{ color: '#FFFFFF' }}
            width={16}
            height={16}
        />
    )
}

const useNotification = () => {
    const showSuccessNotification = ({
        message = DEFAULT_SUCCESS_MSG,
        description = DEFAULT_SUCCESS_DESCRIPTION,
    }: NotificationProps) => {
        notification.error({
            message: <Message message={message} />,
            placement: 'top',
            description: <Description description={description} />,
            icon: <AppIcon id="info-icon" />,
            closeIcon: <CloseIcon />,
            style: {
                background: '#1AA953',
            },
        })
    }

    const showErrorNotification = (
        message: string = DEFAULT_ERROR_MSG,
        description: string = DEFAULT_ERROR_DESCRIPTION,
    ) => {
        notification.error({
            message: <Message message={message} />,
            placement: 'top',
            description: <Description description={description} />,
            icon: <AppIcon id="info-icon" />,
            closeIcon: <CloseIcon />,
            style: {
                background: '#D65745',
            },
        })
    }

    const showInfoNotification = ({
        message,
        description,
    }: InfoNotificationProps) => {
        notification.error({
            message: <Message message={message} />,
            placement: 'top',
            description: <Description description={description} />,
            icon: <AppIcon id="info-icon" />,
            closeIcon: <CloseIcon />,
            style: {
                background: '#297FCE',
            },
        })
    }

    return {
        showSuccessNotification,
        showErrorNotification,
        showInfoNotification,
    }
}

export default useNotification
