import { AppButton, AppDatePicker, AppFilterDate, AppTable } from 'components'
import s from './transaction-detail.module.scss'
import { useTableData } from 'hooks'
import { TransactionsApi } from 'services/api/TransactionsApi'
import { useEffect, useMemo, useState } from 'react'
import {
	ITransactionDetail,
	ITransactionDetailBranch,
} from 'services/api/TransactionsApi.module'
import ListDefault from 'shared/components/Lists/ListDefault'
import ListOutline from 'shared/components/Lists/ListOutline'
import { FILTER_DATE } from '../header'
import { useNavigate, useResolvedPath, useSearchParams } from 'react-router-dom'
import { API_DATE_FORMAT, downloadFilePost } from 'utils'
import dayjs from 'dayjs'
import { DownloadIcon } from 'components/icon'
import { notification } from 'antd'
import { baseURL } from 'services/api/clientApi'
import moment from 'moment'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { useBuildDetailTransactionReportMutation } from 'services/api/Reports/ReportsApi'
import useNotification from 'hooks/useNotification'
import { ReportType } from 'services/api/Reports/ReportsApi.model'

const TransactionDetail = observer(() => {
	const { merchantStore } = store
	const merchantDetail = merchantStore.getDetailInfo()


	const navigate = useNavigate()
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [searchParams, setSearchParams] = useSearchParams()
	const [transactionDetail, setTransactionDetail] =
		useState<null | ITransactionDetail>(null)

	const { pathname } = useResolvedPath('')

	const [notifications] = notification.useNotification()

	const [buildReport] = useBuildDetailTransactionReportMutation()
    const { showSuccessNotification } = useNotification()

	const gteDate = searchParams.get('start_date')
	const lteDate = searchParams.get('end_date')

	useEffect(() => {
		if (
			!searchParams.get("start_date") &&
			!searchParams.get("end_date")
		  ) {	
			setSearchParams((prev) => {
			  prev.set("start_date", dayjs().format(API_DATE_FORMAT));
			  prev.set("end_date", dayjs().format(API_DATE_FORMAT));
			  return prev;
			}, {replace: true});
		  }
	}, [])

	const mergeRequests = (params: any) => {
		return TransactionsApi.getTransactionDetailBranches(params).then(res => {
			getTransactionDetail()
			return res
		})
	}

	const { loading, tableData, handleTableChange, pagination } = useTableData(
		mergeRequests as any,
		{
			end_date: lteDate || '',
			start_date: gteDate || '',
		}
	)

	const getTransactionDetail = async () => {
		try {
			const { data } = await TransactionsApi.getTransactionDetail({
				params: { end_date: lteDate || '', start_date: gteDate || '' },
			})

			setTransactionDetail(data)
		} catch (error) {}
	}

	const tableColumns = [
		{
			dataIndex: 'id',
			title: '№',
		},
		{
			dataIndex: 'name',
			title: 'Торговая точка',
		},
		{
			dataIndex: 'address',
			title: 'Адрес',
		},
		{
			dataIndex: 'is_active',
			title: 'Статус',
			render: (value: boolean) => (
				<>{value ? <span>Активный</span> : <span>Неактивный</span>}</>
			),
		},
		{
			title: 'Сумма платежей',
			render: (value: ITransactionDetailBranch['branches'][0]) => (
				<ListDefault
					list={[
						{
							key: 'Всего:',
							value: value?.payment_total_sum || 0,
						},
						{
							key: 'Картой:',
							value: value.card_payment_total_sum || 0,
						},
						{
							key: 'QR:',
							value: value.qr_payment_total_sum || 0,
						},
					]}
				/>
			),
		},
		{
			title: 'Сумма возмещения',
			render: (value: ITransactionDetailBranch['branches'][0]) => (
				<ListDefault
					list={[
						{
							key: 'Всего:',
							value: value?.refund_total_sum || 0,
						},
						{
							key: 'Картой:',
							value: value.card_refund_total_sum,
						},
						{
							key: 'QR:',
							value: value.qr_refund_total_sum,
						},
						{
							key: 'Комиссия:',
							value: value.bank_commission,
						},
					]}
				/>
			),
		},
	]

	const daysHandler = (item: { key: string; name: string; param: string }) => {
		searchParams.set('start_date', item.param)
		searchParams.set('end_date', dayjs().format(API_DATE_FORMAT))
		setSearchParams(searchParams, {replace: true})
	}

	const periodHandler = (values: [Date, Date] | null) => {
		if (values) {
			searchParams.set(
				'start_date',
				values ? dayjs(values[0]).format(API_DATE_FORMAT) : ''
			)
			searchParams.set(
				'end_date',
				values ? dayjs(values[1]).format(API_DATE_FORMAT) : ''
			)
		} else {
			searchParams.delete('start_date')
			searchParams.delete('end_date')
		}

		setSearchParams(searchParams, {replace: true})
	}

	const openNotification = () => {
		notifications.info({
			message: `Внимание!`,
			description:
				'Началось скачивание отчетов! Это может занять некотрое время',
		})
	}

	const downloadReport = async () => {
		try {
			setIsLoading(true)
			await buildReport(
                `${
                    searchParams
                        ? `?${searchParams}&user_id=${merchantDetail?.owner?.id}`
                        : `?user_id=${merchantDetail?.owner?.id}`
                }`,
            ).unwrap()
            navigate(
                `/merchant-user/dashboard/reports-list/?active-tab=${ReportType.DETAILED_REPORT}`,
            )
            showSuccessNotification({
                message: 'Отчет формируется',
                description:
                    "Формирование отчета займет некоторое время. Посмотреть статус вы можете во вкладке 'Отчеты'",
            })
		} catch (e) {
		} finally {
			setIsLoading(false)
		}
	}

	const PAYMENT_COUNTERS = [
		{
			key: 'Сумма платежей:',
			value: transactionDetail?.payment_total_sum || 0,
		},
		{
			key: 'QR:',
			value: transactionDetail?.payment_qr_sum || 0,
		},
		{
			key: 'Картой:',
			value: transactionDetail?.refund_card_sum || 0,
		},
	]
	const PAYMENT_REFOUND = [
		{
			key: 'Сумма возмещения:',
			value: transactionDetail?.refund_total_sum || 0,
		},
		{
			key: 'QR:',
			value: transactionDetail?.refund_qr_sum || 0,
		},
		{
			key: 'Картой:',
			value: transactionDetail?.refund_card_sum || 0,
		},
		{
			key: 'Комиссия:',
			value: transactionDetail?.bank_commission || 0,
		},
	]

	const filterValue = useMemo(
		() => FILTER_DATE.find(it => it.param === gteDate)?.key,
		[gteDate]
	)

	const showDetail = (
		record: { id: number },
		e: React.MouseEvent<any, MouseEvent>
	) => {
		if (e.metaKey || e.ctrlKey) {
			window.open(`${pathname}${record.id}/?start_date=${gteDate}&end_date=${lteDate}`)
			return
		}
		navigate(`${pathname}${record.id}/?start_date=${gteDate}&end_date=${lteDate}`)
	}

	function disabledDate(current: any) {
		return current && current > moment().endOf('day')
	}
	return (
		<section className={clsx(s.container, 'box_mkassa')}>
			<div className={s.controls}>
				<AppFilterDate
					filter={FILTER_DATE}
					current={filterValue || undefined}
					onChange={daysHandler}
					disabled={loading}
				/>
				<div className={s.left}>
					<AppDatePicker
						disabledDate={disabledDate}
						value={gteDate ? [gteDate, lteDate] : null}
						onChange={periodHandler}
						disabled={loading}
						allowClear={false}
					/>
					<AppButton
						disabled={!gteDate || !lteDate }
						onClick={downloadReport}
						iconLeft={<DownloadIcon />}
					>
						Сформировать отчет
					</AppButton>
				</div>
			</div>
			<div className={s.counters}>
				<ListOutline list={PAYMENT_COUNTERS} />
				<ListOutline variant='warning' list={PAYMENT_REFOUND} />
			</div>

			<div className={s.table}>
				<AppTable
					columns={tableColumns}
					data={tableData.branches}
					pagination={pagination}
					onChange={handleTableChange}
					loading={loading}
					onRow={(record: any) => {
						return {
							onClick: e => {
								showDetail(record, e)
							},
						}
					}}
				/>
			</div>
		</section>
	)
})

export default TransactionDetail
