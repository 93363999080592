import React, { useMemo } from "react";

import { DatePicker } from "antd";
import dayjs from "dayjs";

import styles from "./AppDatePicker.module.scss";

import { API_DATE_FORMAT } from "utils";

const { RangePicker } = DatePicker;

export interface DatePickerProps {
  // value?: Date | null | [Date, Date];
  value?: any;
  icon?: React.ReactNode;
  inputSize?: "small" | "medium";
  onchange?: (date: any) => void;
  onChange?: (date: any) => void;
  placeholder?: string;
  showRange?: boolean;
  disabled?: boolean;
  disabledDate?: any;
  allowClear?: boolean;
}

const AppDatePicker: React.FC<DatePickerProps> = ({
  icon,
  value,
  inputSize = "medium",
  onChange,
  placeholder = "Выберите дату",
  showRange,
  disabled,
  disabledDate,
  allowClear = true,
  ...props
}) => {
  const getValue = useMemo(() => {
    if (Array.isArray(value)) {
      const start = dayjs(value[0], API_DATE_FORMAT);
      const end = dayjs(value[1], API_DATE_FORMAT);

      if (start.isValid() && end.isValid()) {
        return [start, end];
      }

      return null;
    }

    return value;
  }, [value]);

  return (
    <div className={styles.container}>
      <RangePicker
        disabledDate={disabledDate}
        format="DD-MM-YYYY"
        // @ts-ignore
        value={getValue}
        onChange={onChange}
        onBlur={() => {}}
        onFocus={() => {}}
        disabled={disabled}
        allowClear={allowClear}
      />
    </div>
  );
};

export default AppDatePicker;
