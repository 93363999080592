import { useResolvedPath } from 'react-router-dom'

import { DataView } from 'components'

import { CashierDetail } from 'services/api/CashierApi.models'

import styles from './cashier-info.module.scss'

const ViewColumns = [
	[
		{
			label: 'Владелец',
			dataIndex: ['user.last_name', 'user.first_name', 'user.middle_name'],
		},
		{
			label: 'Торговая точка',
			dataIndex: 'branches',
			type: 'options',
			optionKey: 'name',
		},
		{
			label: 'ИНН',
			dataIndex: 'user.inn',
		},
		{
			label: 'Логин',
			dataIndex: 'user.login',
		},
		{
			label: 'Номер телефона',
			dataIndex: 'user.phone_number',
		},
	],
	[
		{
			label: 'Мерчант',
			dataIndex: 'merchant.name',
		},
		{
			label: 'Тип устройства',
			dataIndex: 'mpos_type',
			type: 'option',
			optionValues: {
				MOBILE_APP: 'Mobile app',
				SMARTPOS_MBANK: 'SmartPos MBank',
				SMARTPOS_PAYMOB: 'SmartPos Paymob',
			},
		},
		{
			label: 'Серийный номер',
			dataIndex: 'mpos_serial_number',
		},
		{
			label: 'J номер',
			dataIndex: 'mpos_j',
		},
	],
]

interface DetailProps {
	data: CashierDetail
}

export default function CashierInfo({ data }: DetailProps) {
	const pathname = useResolvedPath('').pathname
	const isMobApp = data.mpos_type === 'MOBILE_APP'

	return (
		<>
			<div className={styles.wrapper}>
				<DataView
					title='Данные кассира'
					columns={ViewColumns}
					data={data}
					// edtLink={isMobApp ? `${pathname}edit/` : ''}
				/>
			</div>
		</>
	)
}
