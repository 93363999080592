import React from 'react'

import { TYPE_STATUS } from 'constants/TypeStatus'

import { ShiftsItem } from 'services/api/ShiftsApi.module'

import { displayDate } from 'utils'

import { AppStatusIndicator, AppTextInformation, AppTitle } from 'components'

import styles from './header.module.scss'

interface HeaderProps {
	data: ShiftsItem
}

const Header: React.FC<HeaderProps> = ({ data }) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.head}>
				<AppTitle title={`Смена № ${data.id}`} />

				<div className={styles.status}>
					<span className={styles.indicator}>
						<AppStatusIndicator
							status={data.is_open ? TYPE_STATUS.SUCCESS : TYPE_STATUS.ERROR}
						/>
					</span>

					<span>{data.is_open ? 'Открытая смена' : 'Закрытая смена'}</span>
				</div>
			</div>

			<div className={styles.info}>
				<AppTextInformation
					information={{
						title: 'Начало смены',
						subTitle: displayDate(data.started_at),
					}}
					asColumn
				/>

				<AppTextInformation
					information={{
						title: 'Закрытие смены',
						subTitle: displayDate(data?.end_at),
					}}
					asColumn
				/>

				<AppTextInformation
					information={{ title: 'Кассир', subTitle: data.cashier_name }}
					asColumn
				/>

				<AppTextInformation
					information={{ title: 'Номер кассира', subTitle: data.cashier_phone }}
					asColumn
				/>
			</div>
		</div>
	)
}

export default Header
