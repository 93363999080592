import React, { useMemo, useState } from 'react'

import { useNavigate, useResolvedPath } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { useTableData } from 'hooks'
import cloneDeep from 'lodash.clonedeep'

import { store } from 'store'

import { AppButton, AppMenu, AppTable } from 'components'
import { AddIcon, ArrowDownFillSmall, UserPlusIcon } from 'components/icon'

import { SIZE_BUTTON } from 'constants/ComponentSize'

import { CashierApi } from 'services/api/CashierApi'
import { CashierItem } from 'services/api/CashierApi.models'
import { BranchesApi } from 'services/api/BranchesApi'

import styles from './table.module.scss'
import { Dropdown } from 'antd'
import { EDevices } from 'models/common.model'
import CashiersList from './components/CashiersList'

interface User {
	last_name: string
	first_name: string
	middle_name: string
}

enum ActionTypes {
	remove = 'remove',
}

const ColOptions = [
	{
		label: 'Удалить кассира из торговой точки',
		key: ActionTypes.remove,
	},
]

interface CashiersProps {
	branchId: number
	canCreate: boolean
}
const tableColumns = [
	{
		dataIndex: 'id',
		title: '№',
	},
	{
		dataIndex: 'user',
		title: 'Кассир',
		render: (value: User) =>
			`${value?.last_name || ''} ${value?.first_name || ''} ${
				value?.middle_name || ''
			}`,
	},
	{
		dataIndex: 'branches',
		title: 'Торговая точка',
		render: (value: [{ name: string; id: number }]) =>
			value.map(item => (
				<span key={item.id}>
					{item.name}
					<br />
				</span>
			)),
	},
	{
		dataIndex: 'login',
		title: 'Логин',
		render: (value: string, row: CashierItem) => row.user.login,
	},
	{
		dataIndex: 'inn',
		title: 'ИНН',
		render: (value: string, row: CashierItem) => row.user.inn,
	},
	{
		dataIndex: 'phone_number',
		title: 'Номер телефона',
		render: (value: string, row: CashierItem) => row.user.phone_number,
	},
	{
		dataIndex: 'transaction_count',
		title: 'Транзакции',
	},
]

const Table: React.FC<CashiersProps> = observer(props => {
	const { branchId, canCreate } = props

	const navigate = useNavigate()
	const { modalStore } = store
	const pathname = useResolvedPath('').pathname

	const [devicedFilter, setDevicesFilter] = useState<any>(null)

	const {
		loading,
		tableData,
		handleTableChange,
		pagination,
		reloadData,
		// @ts-ignore
	} = useTableData(CashierApi.getCashier as any, {
		branches: branchId,
	})

	const showDetail = (
		record: CashierItem,
		e: React.MouseEvent<any, MouseEvent>
	) => {
		if (e.metaKey || e.ctrlKey) {
			window.open(`/merchant-user/dashboard/cashier/${record.id}/detail/`)
			return
		}

		navigate(`/merchant-user/dashboard/cashier/${record.id}/detail/`)
	}

	const onOpenModal = () => {
		modalStore.openModal({
			className: styles.modalWrapper,
			body: (
				<CashiersList
					branchId={branchId}
					onClose={() => modalStore.closeModal()}
					reloadTable={reloadData}
				/>
			),
		})
	}

	const onCreate = () => {
		navigate(`${pathname}create/`)
	}

	const actionHandler = async (key: string, record: CashierItem) => {
		switch (key) {
			case ActionTypes.remove:
				await BranchesApi.updateBranchField(branchId, { cashiers: [record.id] })
				reloadData()
				break
			default:
				break
		}
	}

	const devicesOptions = [
		{ id: EDevices.MOBILE_APP, name: 'Mobile app' },
		{ id: EDevices.SMARTPOS_MBANK, name: 'SmartPOS MBank' },
		{ id: EDevices.SMARTPOS_PAYMOB, name: 'SmartPOS Paymob' },
		{ id: '', name: 'Все' },
	]

	const transitAccountItems = useMemo(
		() =>
			devicesOptions.map(item => ({
				label: (
					<div className={styles.popupItem}>
						<div>{item.name}</div>
					</div>
				),
				key: item.id,
			})),
		[]
	)

	const handleMenuClick = (value: any) => {
		const currentOb = devicesOptions.find(item => item.id === value.key)
		// @ts-ignore
		setDevicesFilter(currentOb)
	}

	const mergeColumns = useMemo(() => {
		return cloneDeep([
			...tableColumns,

			{
				dataIndex: 'mpos_type',

				title: () => (
					<Dropdown
						menu={{
							items: transitAccountItems,
							onClick: handleMenuClick,
						}}
						trigger={['click']}
					>
						<div>
							{devicedFilter?.name || 'Все'} <ArrowDownFillSmall />
						</div>
					</Dropdown>
				),
				render: (value: boolean) => value,
			},
			{
				dataIndex: '',
				title: '',
				render: (value: string, record: CashierItem) => (
					<AppMenu
						items={ColOptions}
						onClick={key => actionHandler(key, record)}
						disabled={!canCreate}
					/>
				),
			},
		])
	}, [tableColumns, canCreate])

	return (
		<div className={styles.container}>
			{canCreate && (
				<div className={styles.filters}>
					{/* <div className={styles.buttons}> */}
						{/* <AppButton
							size={SIZE_BUTTON.SMALL}
							onClick={onCreate}
							variant='outline'
							iconLeft={<UserPlusIcon />}
							className={styles.button}
						>
							Создать кассира
						</AppButton> */}
{/* 
						<AppButton
							size={SIZE_BUTTON.SMALL}
							onClick={onOpenModal}
							iconLeft={<AddIcon className={styles.icon} />}
							className={styles.button}
						>
							Добавить кассира
						</AppButton> */}
					{/* </div> */}
				</div>
			)}

			<div className={styles.content}>
				<AppTable
					columns={mergeColumns}
					data={tableData.results}
					pagination={pagination}
					onChange={handleTableChange}
					onRow={(record: any) => {
						return {
							onClick: e => {
								showDetail(record, e)
							},
						}
					}}
					loading={loading}
				/>
			</div>
		</div>
	)
})

export default Table
