import { Segmented } from 'antd'

import { useMemo } from 'react'

import styles from './styles.module.scss'
import { ReportType } from 'services/api/Reports/ReportsApi.model'

interface ITabProps {
    count: number
    label: string
}

const Tab = (props: ITabProps) => {
    const { count, label } = props

    return (
        <div className={styles.tab}>
            <div>{label}</div>
            <div className={styles.tabCount}>{count}</div>
        </div>
    )
}

interface IProps {
    onChange: (value: ReportType) => void
    transactionCount: number | undefined
    detailTransactionCount: number | undefined
    selectedTab: ReportType
}

const Tabs = (props: IProps) => {
    const { onChange, transactionCount, detailTransactionCount, selectedTab } = props

    const options = useMemo(() => {
        return [
            {
                label: <Tab label="Детальная выписка" count={detailTransactionCount || 0} />,
                value: ReportType.DETAILED_REPORT,
            },
            {
                label: <Tab label="По транзакциям" count={transactionCount || 0} />,
                value: ReportType.TRANSACTION_REPORT,
            },
        ]
    }, [transactionCount, detailTransactionCount])

    return (
        <Segmented
            options={options}
            onChange={onChange}
            defaultValue={selectedTab}
            className={styles.segmented}
        />
    )
}

export default Tabs
