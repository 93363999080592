import React from 'react';

import {EmptyData} from "../icon";

import {AppButton} from "../index";

import styles from './NoData.module.scss';

const NoData = (props: any) => {
    const { label = 'Нет данных', onRefresh, hideRefresh } = props;

    const onUpdate = () => {
        window.location.reload();
    }
    return (
        <div className={styles.wrapper}>
            <EmptyData />

            <p className={styles.label}>{label}</p>

            {!hideRefresh && (
                <AppButton className={styles.button} onClick={onRefresh || onUpdate}>Обновить</AppButton>
            )}
        </div>
    );
};

export default NoData;
