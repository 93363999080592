import { MKASSA_ARM_TOKEN } from './constants'
import { Props } from './index'

export const setSessionStorage = ({ key = MKASSA_ARM_TOKEN, value }: Props) => {
	sessionStorage.setItem(key, value)
}

export const getSessionStorage = (key = MKASSA_ARM_TOKEN) => {
	return sessionStorage.getItem(key)
}

export const deleteSessionStorage = (key = MKASSA_ARM_TOKEN) => {
	sessionStorage.removeItem(key)
}
