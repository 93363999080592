import { SelectProps } from "antd";
import { DefaultOptionType } from "antd/es/select";
import CashierSelect from "common/cashier/CashierSelect";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { setCashier } from "store/rtk/slices/filters/transactions-filter.slice";

const FilterByCashier = (props: SelectProps) => {
  const dispatch = useAppDispatch();

  const cashier = useAppSelector((store) => store.transactionsFilters.cashier);

  const handleChange = (_: string, option: DefaultOptionType) => {
    dispatch(setCashier(option || null));
  };

  return (
    <CashierSelect
      allowClear
      onChange={handleChange}
      defaultValue={cashier}
      {...props}
    />
  );
};

export default FilterByCashier;
