import {FormEvent} from 'react'

import {makeAutoObservable} from 'mobx'
import {COUNT_ON_PAGE} from "../../constants/CountOnPage";

interface Counters {
    branch_count?: number;
    cashier_count?: number;
    company_count?: number;
}

export class HeaderFiltersStore {
    search: string = '';
    page: number = 0;
    pageCount: number = COUNT_ON_PAGE[1].option;
    limit: number = COUNT_ON_PAGE[1].option;

    counters: Counters = {}

    constructor() {
        makeAutoObservable(this, {}, {autoBind: true})
    }

    searchHandler(e: FormEvent<HTMLInputElement>) {
        this.search = e.currentTarget.value;
    }

    getSearchValue() {
        return this.search;
    }

    clearState() {
        this.search = '';
    }

    setCounters(values: { branch_count?: number, cashier_count?: number, company_count?: number }) {
        this.counters.branch_count = values?.branch_count;
        this.counters.cashier_count = values?.cashier_count;
        this.counters.company_count = values?.company_count;
    }

    getCounters() {
        return {
            branch_count: this.counters.branch_count,
            cashier_count: this.counters.cashier_count,
            company_count: this.counters.company_count,
        }
    }
}
