import React from 'react';

import { Button, message } from 'antd';
import clsx from 'clsx';

import { Duplicate } from 'components/icon';

import styles from './ClipBoardCopy.module.scss';

const showSuccess = () => {
  message.open(
    {
      type: 'info',
      content: 'Скопировано',
      // @ts-ignore
      maxCount: 1,
      key: 'clipBoardId',
    });
}

const unsecuredCopyToClipboard = (text: string) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand('copy');
    showSuccess();
  } catch (err) {
  }
  document.body.removeChild(textArea)
};

interface ClipBoardCopy {
  children?: React.ReactNode;
  text: string;
  className?: string;
  disabled?: boolean;
}

const ClipBoardCopy:React.FC<ClipBoardCopy> = (props) => {
  const {
    children,
    text,
    className,
    disabled,
  } = props;

  const onClick = () => {
    if (text) {
      if (window.isSecureContext && navigator.clipboard) {
        navigator.clipboard.writeText(text);
      } else {
        unsecuredCopyToClipboard(text);
      }
    }
    showSuccess();
  }

  return (
    <Button
      className={clsx(styles.wrapper, className)}
      disabled={disabled}
      onClick={onClick}
    >
      {children || <Duplicate />}
    </Button>
  );
};

export default React.memo(ClipBoardCopy);
