import React from 'react'
import { useParams } from 'react-router'
import { useNavigate, useResolvedPath, useSearchParams } from 'react-router-dom'

import { useTableData } from 'hooks'

import { AppTable } from 'components'

import { displayDate } from 'utils'

import { ShiftsApi } from 'services/api/ShiftsApi'
import { ShiftsItem } from 'services/api/ShiftsApi.module'

import styles from './Shifts.module.scss'
import { Transaction } from 'services/api/Asia/AsiaApi.models'
import SortTitle from 'components/Sort/SortTitle'

interface Props {
	id?: string
}

const tableColumns = [
	{
		dataIndex: 'id',
		title: '№ Смены',
	},
	{
		dataIndex: 'cashier_name',
		title: 'Кассир',
	},
	{
		dataIndex: 'is_open',
		title: 'Открыт',
		render: (value: boolean) => (value ? 'Да' : 'Нет'),
	},
	{
		dataIndex: 'started_at',
		title: 'Открытие смены',
		render: (value: string) => displayDate(value),
	},
	{
		dataIndex: 'end_at',
		title: 'Закрытие смены',
		render: (value: string) => displayDate(value),
	},
	{
		dataIndex: 'transaction_sum',
		title: () => (
			<>
				<SortTitle
					paramKey='order_by'
					value='transaction_sum'
					title='Сумм'
				/>
			</>
		),
	},

	{
		dataIndex: 'transaction_count',
		title: () => (
			<>
				<SortTitle
					paramKey='order_by'
					value='transaction_count'
					title='Количество успешных транзакций'
				/>
			</>
		),
	},
]

const Shifts: React.FC<Props> = () => {
	const [searchParams] = useSearchParams()
	const order_by = searchParams.get('order_by')

	const { branchId } = useParams()
	const navigate = useNavigate()
	const pathname = useResolvedPath('').pathname

	const {
		loading,
		tableData,
		handleTableChange,
		pagination,
		// @ts-ignore
	} = useTableData(ShiftsApi.getShifts as any, {
		branches: branchId,
		order_by,
	})

	const showDetail = (
		record: ShiftsItem,
		e: React.MouseEvent<any, MouseEvent>
	) => {
		if (e.metaKey || e.ctrlKey) {
			window.open(
				`/merchant-user/dashboard/shifts/${record.id}/transactions/?search_by_sum=`
			)

			return
		}
		navigate(
			`/merchant-user/dashboard/shifts/${record.id}/transactions/?search_by_sum=`
		)
	}
	return (
		<div className={styles.container}>
			<div className={styles.table}>
				<AppTable
					columns={tableColumns}
					data={tableData?.results}
					pagination={pagination}
					onChange={handleTableChange}
					loading={loading}
					onRow={(record: any) => {
						return {
							onClick: e => {
								showDetail(record, e)
							},
						}
					}}
				/>
			</div>
		</div>
	)
}

export default Shifts
