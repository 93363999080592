import { AppTable } from 'components'
import { Report } from 'services/api/Reports/ReportsApi.model'

import { REPORTS_TABLE_COLUMNS } from './columns'

interface IProps {
    reports: Report[] | undefined
    onChange?: (pagination: any) => void
    pagination?: any
    loading?: boolean
    className?: string
}

const Table = (props: IProps) => {
    const { reports, pagination, onChange, loading, className } = props

    return (
        <AppTable
            data={reports}
            pagination={pagination}
            onChange={onChange}
            loading={loading}
            className={className}
            columns={REPORTS_TABLE_COLUMNS}
        />
    )
}

export default Table
