import { QueryParamsModel } from "models/QueryParams.model";

import { clientApi } from "../clientApi";
import { CashoutBranch, CashoutListCounters } from "./types";

export const CashoutApi = {
  createCashoutBranch({ body, id }: { body: CashoutBranch; id: number }) {
    return clientApi.put<CashoutBranch>(
      `crm/crm_branches/${id}/cash-out/`,
      body
    );
  },
  createCashoutMerchant({ body, id }: { body: CashoutBranch; id: number }) {
    return clientApi.put<CashoutBranch>(
      `crm/crm_merchants/${id}/cash-out/`,
      body
    );
  },
  getCashoutList(params: QueryParamsModel) {
    return clientApi.get<CashoutBranch>(`crm/cash-out/`, { params });
  },
  getCashoutListCounters(params?: QueryParamsModel) {
    return clientApi.get<CashoutListCounters>(
      "/crm/cash-out/cash-out-counts/",
      { params }
    );
  },
};
