import { observer } from "mobx-react-lite";
import { store } from "store";
import { AppWrapperIcon } from "components";
import clsx from "clsx";

import styles from "./AppResultModal.module.scss";

const AppResultModal = observer(() => {
  const { resultRequestStore } = store;

  const variantResult = {
    ERROR: styles.error,
    WARNING: styles.warning,
    SUCCESS: styles.success,
    INFO: styles.info,
  };

  if (!resultRequestStore.visible) {
    return null;
  }

  return (
    <div
      className={styles.wrapper}
      onClick={resultRequestStore.removeResultRequest}
    >
      <div className={styles.content} onClick={(e) => e.stopPropagation()}>
        {resultRequestStore.type && (
          <AppWrapperIcon type={resultRequestStore.type} />
        )}

        <p
          className={clsx(
            resultRequestStore?.type && variantResult[resultRequestStore?.type]
          )}
        >
          {resultRequestStore.code}
        </p>

        <span>{resultRequestStore.description || "Неизвестная ошибка"}</span>
      </div>
    </div>
  );
});

export default AppResultModal;
