import React from 'react'
import { IconProps } from '../types'

const RefreshIcon: React.FC<IconProps> = ({ className, color = '#0092A1' }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='18'
			height='18'
			viewBox='0 0 18 18'
			fill='none'
			className={className}
		>
			<g clipPath='url(#clip0_3165_3779)'>
				<path
					d='M17.213 9C17.0296 8.99766 16.8519 9.06387 16.7147 9.18567C16.5775 9.30746 16.4907 9.47607 16.4713 9.6585C16.3239 11.3639 15.5982 12.9677 14.4144 14.204C13.2305 15.4404 11.6597 16.235 9.96231 16.4562C8.26493 16.6774 6.54293 16.3118 5.08181 15.4201C3.62069 14.5284 2.5082 13.1641 1.92878 11.5534C1.34936 9.94271 1.33781 8.18238 1.89605 6.56423C2.45428 4.94609 3.54878 3.56732 4.99807 2.65652C6.44737 1.74571 8.16442 1.35758 9.86456 1.55646C11.5647 1.75534 13.1458 2.52929 14.3458 3.75H12.2818C12.0829 3.75 11.8921 3.82902 11.7514 3.96967C11.6108 4.11032 11.5318 4.30109 11.5318 4.5C11.5318 4.69891 11.6108 4.88968 11.7514 5.03033C11.8921 5.17098 12.0829 5.25 12.2818 5.25H15.2818C15.6796 5.25 16.0611 5.09196 16.3424 4.81066C16.6237 4.52936 16.7818 4.14782 16.7818 3.75V0.75C16.7818 0.551088 16.7028 0.360322 16.5621 0.21967C16.4214 0.0790176 16.2307 0 16.0318 0C15.8329 0 15.6421 0.0790176 15.5014 0.21967C15.3608 0.360322 15.2818 0.551088 15.2818 0.75V2.56125C13.82 1.13703 11.9174 0.252213 9.88632 0.0520993C7.85528 -0.148014 5.81655 0.348471 4.10494 1.46002C2.39332 2.57158 1.11056 4.23213 0.467296 6.16898C-0.175964 8.10583 -0.141483 10.2039 0.565076 12.1185C1.27164 14.0332 2.60827 15.6507 4.35549 16.7054C6.10271 17.7601 8.15665 18.1893 10.18 17.9226C12.2034 17.6558 14.0759 16.709 15.4901 15.2375C16.9043 13.766 17.7761 11.8574 17.9623 9.825C17.972 9.72062 17.9598 9.61537 17.9266 9.51594C17.8934 9.41651 17.8398 9.32509 17.7693 9.24749C17.6989 9.16989 17.613 9.1078 17.5172 9.0652C17.4214 9.02259 17.3178 9.00038 17.213 9Z'
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id='clip0_3165_3779'>
					<rect width='18' height='18' fill='white' />
				</clipPath>
			</defs>
		</svg>
	)
}

export default React.memo(RefreshIcon)
