import AppDatePicker from 'components/AppDatePicker'
import dayjs from 'dayjs'
import moment from 'moment'
import { API_DATE_FORMAT } from 'utils'

import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'

interface Props {
	format?: string
	disabled?: boolean
	allowClear?: boolean
}

const AppDatePickerParams = ({ format = API_DATE_FORMAT, disabled, allowClear }: Props) => {
	const [searchParams, setSearchParams] = useSearchParams()

	useEffect(() => {
		if (
			!searchParams.get("start_date") &&
			!searchParams.get("end_date")
		  ) {	
			setSearchParams((prev) => {
			  prev.set("start_date", dayjs().format(format));
			  prev.set("end_date", dayjs().format(format));
			  return prev;
			}, {replace: true});
		  }
	}, [])

	const periodHandler = (values: [Date, Date] | null) => {
		if (values) {
			searchParams.set(
				'start_date',
				values ? dayjs(values[0]).startOf("day").format(format) : ''
			)
			searchParams.set(
				'end_date',
				values ? dayjs(values[1]).endOf('day').format(format) : ''
			)
		} else {
			searchParams.delete('start_date')
			searchParams.delete('end_date')
		}

		setSearchParams(searchParams, {replace: true})
	}

	function disabledDate(current: any) {
		return current && current > moment().endOf('day')
	}

	const gteDate = searchParams.get('start_date')
	const lteDate = searchParams.get('end_date')
	return (
		<AppDatePicker
			disabledDate={disabledDate}
			value={gteDate ? [gteDate, lteDate] : null}
			onChange={periodHandler}
			disabled={disabled}
			allowClear={allowClear}
		/>
	)
}

export default AppDatePickerParams
