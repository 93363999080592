import {useEffect, useState} from "react";
import {matchRoutes, useLocation} from "react-router";

export const useDebounce = (value: string | number, delay: number = 700) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            return () => {
                clearTimeout(handler);
            };
        },
        // eslint-disable-next-line
        [value],
    );

    return debouncedValue;
};

export const useCurrentPath = (routes: { path: string }[]) => {
    const location = useLocation()
    // @ts-ignore
    const [{ route }] = matchRoutes(routes, location)

    return route.path
}