import React from "react";

import {
    AppSwitch,
} from "components";

import {observer} from "mobx-react-lite";

import {store} from "store";

import {CashierDetail} from "services/api/CashierApi.models";
import {CashierApi} from "services/api/CashierApi";

import styles from "./Header.module.scss";

interface Props {
    data: CashierDetail;
}

const Header: React.FC<Props> = observer(({data}) => {
    const {cashierStore, user} = store;

    const isEditable = !user.actions.readOnly;

    const userData = data.user;

    const toggleStatus = async () => {
        await CashierApi.editCashierField(data.id, {is_active: !userData?.is_active})

        await cashierStore.getAsyncDetail(data.id);
    }

    return (
        <div className={styles.wrapper}>

            <div className={styles.header}>
                <h3 className={styles.title}>
                    {userData?.last_name || ""}{' '}{userData?.first_name || ""}{' '}
                    {userData?.middle_name || ""}
                </h3>

                <AppSwitch
                    label={data.user?.is_active ? 'Активный' : 'Неактивный'}
                    onChange={toggleStatus}
                    isChecked={data.user?.is_active}
                    disabled={!isEditable}
                />
            </div>

        </div>
    );
});

export default Header;
