import dayjs from 'dayjs'

import ReportActions from '../ReportActions'
import ReportStatus from '../ReportStatus'
import { Report, ReportStatusEnum } from 'services/api/Reports/ReportsApi.model'
import { API_DATE_FORMAT_HOUR } from 'utils'

export const REPORTS_TABLE_COLUMNS = [
    {
        dataIndex: 'id',
        title: '№',
    },
    {
        dataIndex: 'name',
        title: 'Название отчета',
    },
    {
        dataIndex: 'status',
        title: 'Статус',
        render: (status: ReportStatusEnum) => <ReportStatus status={status} />,
    },
    {
        dataIndex: 'created_at',
        title: 'Дата создания',
        render: (created_at: string) =>
            dayjs(created_at).format(API_DATE_FORMAT_HOUR),
    },
    {
        dataIndex: 'id',
        title: '',
        render: (id: number, report: Report) => (
            <ReportActions id={id} status={report.status} fileLink={report.file_link} name={report.name} />
        ),
    },
]
