import styles from './main-header.module.scss'
import UserInfo from 'shared/components/UserInfo'
import { store } from 'store'

const MerchantHeader = () => {
	const { user } = store
	const userData = user.getUser()

	if (!userData) return null
	return (
		<div className={styles.wrapper}>
			<div className={styles.userInfo}>
				<UserInfo userName={userData.first_name} data={userData} />
			</div>
		</div>
	)
}

export default MerchantHeader
