import {clientApi} from "./clientApi";
import {Pagination} from "models/pagination.models";

interface PropsGetShifts extends Pagination {
    cashier?: string;
    company?: string;
}

export const ShiftsApi = {
    getShifts(params: PropsGetShifts) {
        return clientApi.get(`crm/crm_shifts/`, {params});
    },

    getShiftDetail(id: string | number, params?: PropsGetShifts) {
        return clientApi.get(`crm/crm_shifts/${id}/`, {params})
    }
};
