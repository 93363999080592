import {
	Navigate,
	Route,
	Routes,
	useNavigate,
	useResolvedPath,
} from 'react-router'
import { CashierRoutes } from './routes'
import { Main } from './components/Main'
import { getInStorage } from 'services/storage'
import { MKASSA_ARM_TOKEN } from 'services/storage/constants'
import { store } from 'store'
import { useEffect } from 'react'
import { AuthApi } from 'services/api/AuthApi'
import { observer } from 'mobx-react-lite'
import ShiftDetail from 'pages/MerchantDashboard/components/Dashboard/components/Shift/ShiftDetail'
import CashierDashboardLayout from './common/CashierDashboardLayout'
import { LoadingArea } from 'components'
import { ARM_ROUTES } from 'router/routes'

const CashierDashboard = observer(() => {
	const navigate = useNavigate()
	const { pathname } = useResolvedPath('')
	const { user } = store
	const token = getInStorage(MKASSA_ARM_TOKEN)
	const userData = user?.getUser()
	const isCashier = userData?.user_type === 'CASHIER'

	useEffect(() => {
		const getUser = async () => {
			const { data } = await AuthApi.getUser()

			user.setUser(data)
		}
		if (token) {
			getUser()
		} else {
			navigate('/auth/')
		}
	}, [])

	if (!userData) {
		return (
			<LoadingArea spin loading={true}>
				<div style={{ width: '100vw', height: '100vh' }}> Минуту</div>
			</LoadingArea>
		)
	}

	if (!isCashier) {
		return <Navigate to={ARM_ROUTES.auth} />
	}

	return (
		<Routes>
			<Route element={<CashierDashboardLayout />}>
				<Route element={<Main />} path={`${CashierRoutes.main}/*`} />
				<Route
					element={<ShiftDetail />}
					path={`${CashierRoutes.shiftDetail(':shiftId')}/*`}
				/>
				<Route path={'*'} element={<Navigate to={`${pathname}main/`} />} />
			</Route>
		</Routes>
	)
})

export default CashierDashboard
