import React from "react";
import {
  useNavigate,
  useResolvedPath,
  useSearchParams,
} from "react-router-dom";

import { useTableData } from "hooks";

import { AppTable } from "components";

import { API_DATE_FORMAT, displayDate } from "utils";

import { ShiftsItem } from "services/api/ShiftsApi.module";

import styles from "./shift.module.scss";
import { MerchantDashboardApi } from "services/api/MerchantDashboard/MainApi";
import SortTitle from "components/Sort/SortTitle";
import ShiftFilter from "shared/common/shift/shift-filter";
import dayjs from "dayjs";
import { useAppDispatch } from "hooks/useRedux";
import { resetFilters, setDateRange } from "store/rtk/slices/filters/transactions-filter.slice";

interface Props {
  id?: string;
}

const tableColumns = [
  {
    dataIndex: "id",
    title: "№",
  },
  {
    dataIndex: "cashier_name",
    title: "Кассир",
  },
  {
    dataIndex: "is_open",
    title: "Открыт",
    render: (value: boolean) => (value ? "Да" : "Нет"),
  },
  {
    dataIndex: "started_at",
    title: "Открытие",
    render: (value: string) => displayDate(value),
  },
  {
    dataIndex: "end_at",
    title: "Закрытие",
    render: (value: string) => displayDate(value),
  },

  {
    dataIndex: "transaction_sum",
    title: () => (
      <>
        <SortTitle paramKey="order_by" value="transaction_sum" title="Сумма" />
      </>
    ),
  },

  {
    dataIndex: "transaction_count",
    title: () => (
      <>
        <SortTitle
          paramKey="order_by"
          value="transaction_count"
          title="Количество успешных транзакций"
        />
      </>
    ),
  },
];

const DEFAULT_DATE_GTE = dayjs().format(API_DATE_FORMAT);
const DEFAULT_DATE_LTE = dayjs().format(API_DATE_FORMAT);

const Shifts: React.FC<Props> = ({ id }) => {
  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch()

  const gteDate = searchParams.get("start_gte") || DEFAULT_DATE_GTE;
  const lteDate = searchParams.get("start_lte") || DEFAULT_DATE_LTE;
  const order_by = searchParams.get("order_by");
  const navigate = useNavigate();
  const pathname = useResolvedPath("").pathname;

  const { loading, tableData, handleTableChange, pagination } = useTableData(
    MerchantDashboardApi.getShifts as any,
    {
      company: id,
      order_by,
      start_lte: lteDate,
      start_gte: gteDate,
    }
  );

  const showDetail = (
    record: ShiftsItem,
    e: React.MouseEvent<any, MouseEvent>
  ) => {
    const date_gte = record.started_at
      ? dayjs(record.started_at).format(API_DATE_FORMAT)
      : dayjs().format(API_DATE_FORMAT);
    const date_lte = record.end_at
      ? dayjs(record.end_at).format(API_DATE_FORMAT)
      : dayjs().format(API_DATE_FORMAT);

    dispatch(resetFilters());
    dispatch(setDateRange({ startDate: date_gte, endDate: date_lte }));
    if (e.metaKey || e.ctrlKey) {
      window.open(
        `${pathname}/${record.id}/`
      );
      return;
    }
    navigate(
      `${pathname}${record.id}/`
    );
  };

  return (
    <div className={styles.container}>
      <ShiftFilter tableDataLoading={loading} />
      <div className={styles.table}>
        <AppTable
          columns={tableColumns}
          data={tableData?.results}
          pagination={pagination}
          onChange={handleTableChange}
          loading={loading}
          onRow={(record: any) => {
            return {
              onClick: (e) => {
                showDetail(record, e);
              },
            };
          }}
        />
      </div>
    </div>
  );
};

export default Shifts;
