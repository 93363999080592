import React from 'react'
import { useParams } from 'react-router'
import { Navigate, Route, Routes, useResolvedPath } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { store } from 'store'
import { AppBreadСrumbs } from 'components'

import { LoadingArea } from 'components'

import styles from './shift.module.scss'
import Header from './common/Header'
import Transactions from '../../Transactions'
import MERCHANT_DASHBOARD_BREADCRUBMS from 'pages/MerchantDashboard/merchBreadCrumbs'

const ShiftDetail = observer(() => {
	const { shiftId, branchId, cashierId } = useParams()

	const { shiftStore, branchStore, cashierStore } = store
	const pathname = useResolvedPath('').pathname

	React.useEffect(() => {
		shiftStore.getAsyncDetail(shiftId)
	}, [shiftId])

	React.useEffect(() => {
		if (branchId) {
			branchStore.getAsyncDetail(branchId)
		}
	}, [branchId])

	React.useEffect(() => {
		if (cashierId) {
			cashierStore.getAsyncDetail(cashierId)
		}
	}, [cashierId])

	const detail = shiftStore.getDetailInfo()

	if (!detail) return null

	return (
		<div className={styles.wrapper}>
			<div style={{ margin: '20px 0' }}>
				<AppBreadСrumbs links={[]} routes={MERCHANT_DASHBOARD_BREADCRUBMS} />
			</div>

			<LoadingArea loading={shiftStore.isLoading}>
				<Header data={detail} />

				<div className={styles.content}>
					<Routes>
						<Route
							path={`/transactions/*`}
							element={
								<Transactions hideCashierName params={{ shift: shiftId }} />
							}
						/>

						<Route
							path='*'
							element={<Navigate to={`${pathname}transactions/`} replace />}
						/>
					</Routes>
				</div>
			</LoadingArea>
		</div>
	)
})

export default ShiftDetail
