import { API_DATE_FORMAT } from "utils";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DefaultOptionType } from "antd/es/select";
import { TYPE_STATUS } from "constants/TypeStatus";
import dayjs from "dayjs";

export interface TransactionsFiltersState {
  sum: number | null;
  startDate: string;
  endDate: string;
  cashier: DefaultOptionType | null;
  branch: DefaultOptionType | null;
  cashRegister: DefaultOptionType | null;
  transactionId: string | null;
  status: TYPE_STATUS | null;
  isFiscalized: DefaultOptionType | null;
  type: DefaultOptionType | null;
}

const initialState: TransactionsFiltersState = {
  sum: null,
  startDate: dayjs().format(API_DATE_FORMAT),
  endDate: dayjs().format(API_DATE_FORMAT),
  cashier: null,
  branch: null,
  transactionId: null,
  status: TYPE_STATUS.SUCCESS,
  cashRegister: null,
  isFiscalized: null,
  type: null,
};

export const _transactionsFiltersSlice = createSlice({
  name: "transactionsFilters",
  initialState,
  reducers: {
    setSum(state, action: PayloadAction<number | null>) {
      state.sum = action.payload;
    },
    setDateRange(
      state,
      action: PayloadAction<{ startDate: string; endDate: string }>
    ) {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    setCashier(state, action: PayloadAction<DefaultOptionType | null>) {
      if (action.payload === null) {
        state.cashier = null;
        return;
      }
      state.cashier = {
        label: action.payload?.label,
        value: action.payload?.value,
      };
    },
    setBranch(state, action: PayloadAction<DefaultOptionType | null>) {
      if (action.payload === null) {
        state.branch = null;
        return;
      }
      state.branch = {
        label: action.payload?.label,
        value: action.payload?.value,
      };
    },
    setCashRegister(state, action: PayloadAction<DefaultOptionType | null>) {
      if (action.payload === null) {
        state.cashRegister = null;
        return;
      }
      state.cashRegister = {
        label: action.payload?.label,
        value: action.payload?.value,
      };
    },
    setTransactionId(state, action: PayloadAction<string | null>) {
      state.transactionId = action.payload;
    },
    setStatus(state, action: PayloadAction<TYPE_STATUS>) {
      if (action.payload === TYPE_STATUS.ALL) {
        state.status = null;
        return;
      }
      state.status = action.payload;
    },
    setIsFiscalized(state, action: PayloadAction<DefaultOptionType | null>) {
      if (action.payload === null) {
        state.isFiscalized = null;
        return;
      }
      state.isFiscalized = {
        label: action.payload?.label,
        value: action.payload?.value,
      };
    },
    setType(state, action: PayloadAction<DefaultOptionType | null>) {
      if (action.payload === null) {
        state.type = null;
        return;
      }
      state.type = {
        label: action.payload?.label,
        value: action.payload?.value,
      };
    },
    resetFilters() {
      return initialState; // Reset to initial state
    },
  },
});

export const {
  setSum,
  setDateRange,
  setCashier,
  setBranch,
  setCashRegister,
  setTransactionId,
  setStatus,
  setIsFiscalized,
  setType,
  resetFilters,
} = _transactionsFiltersSlice.actions;

export const transactionsFiltersReducer = _transactionsFiltersSlice.reducer;
