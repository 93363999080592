import { AppButton } from 'components'
import clsx from 'clsx'
import styles from "./styles.module.scss"
import AppIcon from 'common/AppIcon'

interface Props {
    onClick: () => void
    className?: string
}

const DeleteBtn = (props: Props) => {
    const { onClick, className } = props
    return (
        <AppButton
            className={clsx(className, styles.deleteBtn)}
            variant="outline"
            iconLeft={<AppIcon id="delete" width={16} height={16} />}
            onClick={onClick}
        >
            Удалить
        </AppButton>
    )
}

export default DeleteBtn
