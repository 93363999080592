import React from "react";

import {Dropdown} from "antd";
import type {MenuProps} from 'antd';

import {PointsIcon} from "components/icon";

import styles from "./AppMenu.module.scss";
import clsx from "clsx";

interface AppMenuProps {
    items: MenuProps['items'];
    onClick?: (key: string) => void;
    disabled?: boolean;
}

const AppMenu: React.FC<AppMenuProps> = (props) => {
    const {
        items,
        onClick,
        disabled
    } = props;

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        e.domEvent.stopPropagation();
        onClick && onClick(e.key)
    };

    return (
        <div className={styles.wrapper}>
            <Dropdown
                menu={{
                    items,
                    onClick: handleMenuClick
                }}
                trigger={['click']}
                overlayClassName={styles.dropdown}
                disabled={disabled}
            >
                <div onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                }} className={clsx(styles.container, disabled && styles.readOnly)}>
                    <PointsIcon />
                </div>
            </Dropdown>
        </div>
    );
};

export default AppMenu;
