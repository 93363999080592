import clsx from 'clsx'

import React from 'react'

import styles from './AppSwitch.module.scss'

type SwitchVariant = 'primary' | 'default'

interface Props {
	onChange?: (isChecked: boolean) => void
	isChecked: boolean
	label?: string
	'data-on'?: string
	'data-off'?: string
	description?: string
	disabled?: boolean
	variant?: SwitchVariant
}

const switchVariant: Record<SwitchVariant, string> = {
	primary: styles.primary,
	default: styles.default,
}

const AppSwitch: React.FC<Props> = props => {
	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		props.onChange && props.onChange(event.target.checked)
	}

	return (
		<label
			className={clsx(
				styles.switch,
				switchVariant[props?.variant || 'default'],
				props.disabled && styles.disabled
			)}
		>
			<p>{props?.label}</p>
			<input
				type='checkbox'
				role='switch'
				data-on={props['data-on']}
				checked={props.isChecked}
				data-off={props['data-off']}
				onChange={onChange}
				aria-checked={props.isChecked}
				disabled={props.disabled}
			/>
		</label>
	)
}

export default AppSwitch
