import clsx from 'clsx'
import AppInput from 'components/AppInput'
import AppRadio from 'components/AppRadio'
import { CashoutBranch } from 'services/api/Cashout/types'

import { useForm, useWatch } from 'react-hook-form'

import s from './style.module.scss'

interface Form extends CashoutBranch {
	commissionType: 'PROCENT' | 'MONEY'
}

interface Props {
	is_cash_enabled?: boolean
	cash_out_commission_percent?: number
	cash_out_commission_int?: number
	disabled?: boolean
	setValue?: (type: string) => void
}

const CashoutCommission = ({
	is_cash_enabled,
	cash_out_commission_percent,
	cash_out_commission_int,
	disabled,
}: Props) => {
	const {
		register,
		control,
		setValue: set,
	} = useForm<Form>({
		defaultValues: {
			is_cash_out_available: is_cash_enabled,
			cash_out_commission_percent: cash_out_commission_percent,
			cash_out_commission_int: cash_out_commission_int,
			commissionType: !!cash_out_commission_percent ? 'PROCENT' : 'MONEY',
		},
	})

	const commissionType = useWatch({
		control,
		name: 'commissionType',
	})

	const isProcent = commissionType === 'PROCENT'
	const isMoney = commissionType === 'MONEY'

	const onChangeRadio = (type: Form['commissionType']) => {
		set('commissionType', type)
	}

	return (
		<div className={clsx(s.container, disabled && s.disabled)}>
			<h4>Комиссия за выдачу наличных QR MBANK</h4>

			<div className={s.list}>
				<div className={clsx(s.inputItem, !isMoney && s.disabled)}>
					<div className={s.label} onClick={() => onChangeRadio('MONEY')}>
						<AppRadio
							label='В сомах'
							checked={isMoney}
							value={commissionType}
							onChange={() => onChangeRadio('MONEY')}
						/>
					</div>

					<AppInput
						{...register('cash_out_commission_int', {
							required: isMoney ? 'Обязательное поле' : false,
						})}
						placeholder='C'
						disabled={isProcent}
					/>
				</div>
				<div className={clsx(s.inputItem, !isProcent && s.disabled)}>
					<AppRadio
						label='В
                        процентах'
						checked={isProcent}
						value={commissionType}
						onChange={() => onChangeRadio('PROCENT')}
					/>
					<AppInput
						{...register('cash_out_commission_percent', {
							required: isProcent ? 'Обязательное поле' : false,
						})}
						placeholder='%'
						disabled={isMoney}
					/>
				</div>
			</div>
		</div>
	)
}

export default CashoutCommission
