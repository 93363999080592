import { EyeCloseIcon, EyeOpenIcon } from 'components/icon'
import { useState } from 'react'
import styles from './hadble-values-card.module.scss'

interface IAction {
	icon: React.ReactNode
}

interface ListItem {
	key: string
	value: string
	is_hidble?: boolean

	actions: IAction[]
}

interface Props {
	list: ListItem[]
}

export default function HadbleValuesCard({ list }: Props) {
	const [show, setShow] = useState<boolean>(false)

	const renderVisibleIcon = show ? <EyeOpenIcon /> : <EyeCloseIcon />

	const hidbleValue = (value: string) => (show ? value : '******')

	return (
		<ul className={styles.container}>
			{list?.map((item, index) => (
				<li key={index}>
					<article>
						<div className={styles.text}>
							<h4>{item.key}</h4>
							<p>
								{item.value
									? item.is_hidble === true
										? hidbleValue(item.value)
										: item.value
									: 'Не установленно'}
							</p>
						</div>
						<div className={styles.icons}>
							{item.is_hidble && (
								<span onClick={() => setShow(!show)} className={styles.btn}>
									{renderVisibleIcon}
								</span>
							)}
							{item?.actions?.map((item, i) => (
								<span key={i}>{item.icon} </span>
							))}
						</div>
					</article>
				</li>
			))}
		</ul>
	)
}
