import { makeAutoObservable } from "mobx";

import { TYPES as TypeResult } from "constants/TypeResults";

interface Props {
  code?: string;
  description?: string;
  type?: TypeResult;
}

export class ResultRequest {
  code: null | string = null;
  description: null | string = null;
  visible: boolean = false;
  type: null | TypeResult = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setResultRequest(data: Props) {
    this.visible = true;
    this.description = data.description || null;
    this.code = data.code || null;
    this.type = data.type || null;
  }

  removeResultRequest() {
    this.visible = false;
    this.description = null;
    this.code = null;
    this.type = null;
  }
}
