import React, { ReactNode } from "react";
import { useLocation } from "react-router";

import { Menu } from "antd";

import styles from "./AppTabs.module.scss";

interface Menuitem {
  label: ReactNode;
  key: string;
  icon?: ReactNode;
  disabled?: boolean;
  className?: string;
}

interface Props {
  tabs?: string[];
  items?: Menuitem[];
}

const AppTabs: React.FC<Props> = (props) => {
  const { items } = props;

  const { pathname } = useLocation();

  return (
    <div className={styles.wrapper}>
      <Menu
        mode="horizontal"
        className={styles.menu}
        selectedKeys={[pathname]}
        items={items}
      />
    </div>
  );
};

export default AppTabs;
