const ArrowDownFillSmall = () => {
  return (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.47995 5.15995L7.47995 1.15995C7.77661 0.764409 7.49438 0.199951 6.99995 0.199951L0.999954 0.199951C0.505527 0.19995 0.223297 0.764409 0.519953 1.15995L3.51995 5.15995C3.75995 5.47995 4.23995 5.47995 4.47995 5.15995Z"
        fill="#8B96B1"
      />
    </svg>
  );
};

export default ArrowDownFillSmall;
