import { displayTimeFromNow } from "utils";
import { Link } from "react-router-dom";
import { LABEL_URL_REGEX } from "constants/Notifications";
import styles from "./styles.module.scss";
import clsx from "clsx";

interface ITextProps {
  text: string;
  date: string;
}

const Text = (props: ITextProps) => {
  const { text, date } = props;

  const match = text.match(LABEL_URL_REGEX);

  if (!match) {
    return (
      <div className={styles.text}>
        {text}
        <div className={styles.date}>{displayTimeFromNow(date)}</div>
      </div>
    );
  }

  const label = match[1].trim();
  const url = match[2].trim();

  const preText = text.substring(0, match.index);
  const postText = text.substring(match.index! + match[0].length) + " ";

  return (
    <div className={styles.text}>
      {preText}
      <Link to={url}>{label}</Link>
      {postText}
      <div className={styles.date}>{displayTimeFromNow(date)}</div>
    </div>
  );
};

interface Props {
  title: string;
  text: string;
  date: string;
  markAsRead: () => void;
  className?: string;
}

const NotificationItem = (props: Props) => {
  const { title, text, date, markAsRead, className } = props;

  return (
    <div className={clsx(styles.wrapper, className)}>
      <div className={styles.header}>
        <h4 className={styles.title}>{title}</h4>
        <div className={styles.markAsRead} onClick={markAsRead}>
          Прочитанно
        </div>
      </div>
      <Text text={text} date={date} />
    </div>
  );
};

export default NotificationItem;
