import { DefaultOptionType, SelectProps } from "antd/es/select";
import { AppSelect } from "components";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { setIsFiscalized } from "store/rtk/slices/filters/transactions-filter.slice";

const OPTIONS: DefaultOptionType[] = [
  { value: '', label: "Все" },
  { value: "true", label: "Фискализированные" },
  { value: "false", label: "Не фискализированные" },
];

const FilterByFiscalized = (props: SelectProps) => {
  const dispatch = useAppDispatch();

  const isFiscalized = useAppSelector(
    (store) => store.transactionsFilters.isFiscalized
  );

  const handleChange = (
    _: "true" | "false" | null,
    option: DefaultOptionType
  ) => {
    dispatch(setIsFiscalized(option || null));
  };

  return (
    <AppSelect
      allowClear
      placeholder="Фискализация"
      options={OPTIONS}
      defaultValue={isFiscalized}
      onChange={handleChange}
      {...props}
    />
  );
};

export default FilterByFiscalized;
