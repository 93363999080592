import React from 'react';

import styles from './AppTextInformation.module.scss'
import clsx from "clsx";

interface Props {
    information?: {
        title?: string,
        subTitle?: string
    }
    asColumn?: boolean;
}

const AppTextInformation: React.FC<Props> = (
    {
        information,
        asColumn,
    }
) => {
    return (
        <div className={clsx(
            styles.container,
            asColumn && styles.column,
        )}>
            <span>{information?.title}</span>

            <p>{information?.subTitle}</p>
        </div>
    );
};

export default AppTextInformation;