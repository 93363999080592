import { useSearchParams } from "react-router-dom";

import { AppButton, AppStatusIndicator, AppTable } from "components";

import { STATUS_TEXT, TYPE_STATUS } from "constants/TypeStatus";
import {
  TYPE_TRANSACTION,
  TypeTransactionEnum,
} from "constants/transaction.model";

import { displayDate } from "utils";

import { useTableData } from "hooks";

import { MerchantDashboardApi } from "services/api/MerchantDashboard/MainApi";

import styles from "./transactions.module.scss";
import { QueryParamsModel } from "models/QueryParams.model";
import SortTitle from "components/Sort/SortTitle";
import { formatInts } from "utils/format-ints";
import HeaderTransaction from "shared/common/reports";
import { observer } from "mobx-react-lite";
import { store } from "store";
import { TransactionItem } from "services/api/TransactionsApi.module";
import ModalCheck from "components/Modals/ModalCheck";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useEffect } from "react";
import { resetFilters } from "store/rtk/slices/filters/transactions-filter.slice";

interface Props {
  params?: QueryParamsModel;
  hideCashierName?: boolean;
  hideBranchName?: boolean;
}

// Создать один компомент в котором будем передовать инклуд файлс вместо состояний hideBranchName hideCashierName
const Transactions: React.FC<Props> = observer(
  ({ params, hideCashierName, hideBranchName }: Props) => {
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();

    const transactionsFilters = useAppSelector(
      (store) => store.transactionsFilters
    );

    const order_by = searchParams.get("order_by");

    const { modalStore } = store;

    const combineParams = {
      created_at__lte: transactionsFilters.endDate,
      created_at__gte: transactionsFilters.startDate,
      status: transactionsFilters.status,
      transaction_type: transactionsFilters.type?.value,
      cashier: transactionsFilters.cashier?.value,
      branches: transactionsFilters.branch?.value,
      cash_register: transactionsFilters.cashRegister?.value,
      order_by,
      sum: transactionsFilters.sum,
      id: transactionsFilters.transactionId,
      fiscalized: transactionsFilters.isFiscalized?.value,
      ...params,
    };

    const {
      loading,
      tableData,
      handleTableChange,
      pagination,
      reloadData,
      // @ts-ignore
    } = useTableData(MerchantDashboardApi.getTransactions as any, {
      ...combineParams,
    });

    /* @ts-ignore */
    const totalSum = new Intl.NumberFormat().format(
      /* @ts-ignore */
      Number(tableData?.total_sum || 0)
    );

    const showCheck = (transactionId: string) => {
      modalStore.openModal({
        body: <ModalCheck transactionId={transactionId} />,
      });
    };

    const tableColumns = [
      {
        dataIndex: "created_at",
        title: "Время проведения",
        render: (value: string) => displayDate(value),
      },
      {
        dataIndex: "cashier_name",
        title: "Кассир",
      },
      {
        dataIndex: "transaction_type",
        title: "Способ оплаты",
        render: (value: TypeTransactionEnum) =>
          TYPE_TRANSACTION[value] || "Не определен тип",
      },
      {
        dataIndex: "status",
        title: "Статус",
        render: (value: TYPE_STATUS) => (
          <div className={styles.status}>
            {<AppStatusIndicator status={value || ""} />}

            <span className={styles.name}>
              {STATUS_TEXT.find((item) => value === item.key)?.name}
            </span>
          </div>
        ),
      },
      {
        dataIndex: "id",
        title: "№",
      },
      {
        dataIndex: "branch_id",
        title: "ID Торговой точки",
      },

      {
        dataIndex: "branch_name",
        title: "Торговая точка",
      },
      {
        dataIndex: "sum",
        title: () => (
          <>
            <SortTitle
              paramKey="order_by"
              value="transaction_sum"
              title="Сумма"
            />
          </>
        ),

        render: (value: number) => formatInts(value),
      },
      {
        dataIndex: "is_fiscalized",
        title: "Фискальный чек",
        render: (value: boolean) => (value ? "Да" : "Нет"),
      },
      {
        dataIndex: "check",
        title: "Чек",
        render: (value: string, item: TransactionItem) =>
          item.is_fiscalized ? (
            <AppButton onClick={() => showCheck(item.id)}>Чек</AppButton>
          ) : null,
      },
    ];

    let combineCol = [...tableColumns];

    if (hideCashierName) {
      combineCol = combineCol.filter(
        (item) => item.dataIndex !== "cashier_name"
      );
    }

    if (hideBranchName) {
      combineCol = combineCol.filter(
        (item) => item.dataIndex !== "branch_name"
      );
    }

    useEffect(() => {
      return () => {
        dispatch(resetFilters());
      };
    }, []);

    return (
      <div className={styles.container}>
        <HeaderTransaction
          counters={tableData?.counters}
          totalSum={totalSum}
          params={params}
          refresh={reloadData}
          tableDataLoading={loading}
        />

        <div className={styles.table}>
          <AppTable
            columns={combineCol}
            data={tableData.results}
            pagination={pagination}
            onChange={handleTableChange}
            loading={loading}
          />
        </div>
      </div>
    );
  }
);

export default Transactions;
