import { notification } from "antd";
import { getInStorage } from "../services/storage";
import { MKASSA_ARM_TOKEN } from "services/storage/constants";

const BASE_URL = `${window.location.protocol}//${window.location.host}`;

export const downloadFile = (url: string, fileName: string) => {
  const ARM_TOKEN = getInStorage(MKASSA_ARM_TOKEN);

  let anchor = document.createElement("a");
  document.body.appendChild(anchor);

  let headers = new Headers();

  headers.append("Authorization", `Bearer ${ARM_TOKEN}`);

  return fetch(url, { headers })
    .then((response) => {
      if (!response.ok) {
        throw new Error("HTTP error!");
      }
      return response.blob();
    })
    .then((blobby) => {
      let objectUrl = window.URL.createObjectURL(blobby);

      anchor.href = objectUrl;
      anchor.download = fileName;
      anchor.click();

      window.URL.revokeObjectURL(objectUrl);
    })
    .catch((err) => {
      notification.error({
        message: "Ошибка загрузки файла!",
      });
    });
};

export const downloadFileFromUrl = (url: string, fileName: string) => {
  // const anchor = document.createElement('a')
  // anchor.href = `${BASE_URL}${url}`
  // anchor.download = fileName
  // document.body.appendChild(anchor)
  // anchor.click()
  // document.body.removeChild(anchor)
  window.open(`${BASE_URL}${url}`);
};

export const downloadFileParam = (
  url: string,
  param: any,
  fileName: string
) => {
  const token = getInStorage(MKASSA_ARM_TOKEN);

  let anchor = document.createElement("a");
  document.body.appendChild(anchor);

  let headers = new Headers();
  headers.append("Authorization", `Bearer ${token}`);

  return fetch(url, { headers })
    .then((response) => {
      if (!response.ok) {
        throw new Error("HTTP error!");
      }
      return response.blob();
    })
    .then((blobby) => {
      let objectUrl = window.URL.createObjectURL(blobby);

      anchor.href = objectUrl;
      anchor.download = fileName;
      anchor.click();

      window.URL.revokeObjectURL(objectUrl);
    })
    .catch((e) => {
      notification.error({
        message: "Ошибка загрузки файла!",
      });
    });
};

export const downloadFilePost = (url: string, fileName: string) => {
  const token = getInStorage(MKASSA_ARM_TOKEN);

  let anchor = document.createElement("a");
  document.body.appendChild(anchor);

  let headers = new Headers();
  headers.append("Authorization", `Bearer ${token}`);

  return fetch(url, { headers, method: "POST" })
    .then((response) => {
      if (!response.ok) {
        throw new Error("HTTP error!");
      }
      return response.blob();
    })
    .then((blobby) => {
      let objectUrl = window.URL.createObjectURL(blobby);

      anchor.href = objectUrl;
      anchor.download = fileName;
      anchor.click();

      window.URL.revokeObjectURL(objectUrl);
    })
    .catch((e) => {
      notification.error({
        message: "Ошибка загрузки файла!",
      });
    });
};
