import { useGetReportsQuery } from 'services/api/Reports/ReportsApi'
import { Report, ReportType } from 'services/api/Reports/ReportsApi.model'

import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import Table from './components/Table'
import Tabs from './components/Tabs'
import styles from './styles.module.scss'

const FETCHING_INTERVAL = 3 * 1000

interface IProps {
    ownerId: number | undefined
}

const ReportsList = (props: IProps) => {
    const { ownerId } = props

    const { data: reports, isLoading } = useGetReportsQuery(Number(ownerId), {
        pollingInterval: FETCHING_INTERVAL,
    })
    const [searchParams, setSearchParams] = useSearchParams()

    const activeTab =
        (searchParams.get('active-tab') as ReportType) ||
        ReportType.DETAILED_REPORT

    const changeTab = (value: ReportType) => {
        searchParams.set('active-tab', value)
        setSearchParams(searchParams, { replace: true })
    }

    const filteredReports = useMemo(() => {
        return reports?.results.filter(
            (el: Report) => el.report_type === activeTab,
        )
    }, [activeTab, reports])

    const transactionCount = useMemo(() => {
        return reports?.results.filter(
            (el: Report) => el.report_type === ReportType.TRANSACTION_REPORT,
        ).length
    }, [reports])

    const detailTransactionCount = useMemo(() => {
        return reports?.results.filter(
            (el: Report) => el.report_type === ReportType.DETAILED_REPORT,
        ).length
    }, [reports])

    return (
        <>
            <Tabs
                onChange={changeTab}
                transactionCount={transactionCount}
                detailTransactionCount={detailTransactionCount}
                selectedTab={activeTab}
            />
            <Table
                className={styles.table}
                reports={filteredReports}
                loading={isLoading}
            />
        </>
    )
}

export default ReportsList
