export enum TYPE_STATUS {
	ALL = 'all',
	PROCESSING = 'inited',
	WAITING = 'waiting',
	ERROR = 'failed',
	REFUSED = 'canceled',
	SUCCESS = 'paid',
	REVERSAL_BY_CARD = 'reversal_by_card',
	UNKNOWN = 'unknown',

	QR_SCANNED = 'qr_scanned',
	ROLL_BACKED = 'rollbacked',
	ROLL_BACK = 'rollback',
	FOR_ROLLBACK = 'for_rollback',
	ROLLBACK_CASH = 'rollback_cash',
	ROLLBACK_CARD = 'rollback_card',
	FINAL_PAID = "final_paid",
	FINAL_COMMITTED = "final_committed",
}

export const STATUS_TEXT = [
	{
			name: 'Все',
			key: TYPE_STATUS.ALL,
			count: 0,
	},
	{
			name: 'В обработке',
			key: TYPE_STATUS.PROCESSING,
			count: 0,
	},
	{
			name: 'Ожидает',
			key: TYPE_STATUS.WAITING,
			count: 0,
	},
	{
			name: 'Ошибка',
			key: TYPE_STATUS.ERROR,
			count: 0,
	},
	{
			name: 'Отказано',
			key: TYPE_STATUS.REFUSED,
			count: 0,
	},
	{
			name: 'Успешно',
			key: TYPE_STATUS.SUCCESS,
			count: 0,
	},
	{
			name: 'Возвращено',
			key: TYPE_STATUS.ROLL_BACK,
			count: 0,
	},
	{
			name: 'Возвращено наличными',
			key: TYPE_STATUS.ROLLBACK_CASH,
			count: 0,
	},
	{
			name: 'Возвращено на карту',
			key: TYPE_STATUS.ROLLBACK_CARD,
			count: 0,
	},
	// {
	// 		name: 'Финально оплачено',
	// 		key: TYPE_STATUS.FINAL_PAID,
	// 		count: 0,
	// },
	// {
	// 		name: 'Коммитет',
	// 		key: TYPE_STATUS.FINAL_COMMITTED,
	// 		count: 0,
	// },
]

export const STATUS_TEXT_ASIA = [
	{
			name: 'Все',
			key: TYPE_STATUS.ALL,
			count: 0,
	},
	{
			name: 'В обработке',
			key: TYPE_STATUS.PROCESSING,
			count: 0,
	},
	{
			name: 'Ожидает',
			key: TYPE_STATUS.WAITING,
			count: 0,
	},
	{
			name: 'Ошибка',
			key: TYPE_STATUS.ERROR,
			count: 0,
	},
	{
			name: 'Отказано',
			key: TYPE_STATUS.REFUSED,
			count: 0,
	},
	{
			name: 'Успешно',
			key: TYPE_STATUS.SUCCESS,
			count: 0,
	},
	{
			name: 'QR отсканирован',
			key: TYPE_STATUS.QR_SCANNED,
			count: 0,
	},
	{
			name: 'Идет на возврат',
			key: TYPE_STATUS.FOR_ROLLBACK,
			count: 0,
	},
	{
			name: 'Отменен банком',
			key: TYPE_STATUS.ROLL_BACKED,
			count: 0,
	},
	{
			name: 'Неизвестно',
			key: TYPE_STATUS.UNKNOWN,
			count: 0,
	},
	{
			name: 'Возврат картой',
			key: TYPE_STATUS.REVERSAL_BY_CARD,
			count: 0,
	},
]
