import React, { useEffect, useRef, useState } from 'react'
import { TYPES } from 'constants/TypeResults'

import { observer } from 'mobx-react-lite'

import { Spin, Tooltip, Modal } from 'antd'

import { store } from 'store'

import { AppTitle, AppLoading, AppSwitch, AppMenu, AppTable } from 'components'

import {
	MerchantsDetail,
	ResetPasswordInterface,
	RkoAccount,
	TransitAccount,
} from 'services/api/MerchantsApi.models'

import { LockIcon, PencilIcon, RefreshIcon } from 'components/icon'

import styles from './header.module.scss'
import { displayDate } from 'utils'
import HadbleValuesCard from 'components/Cards/HadbleValuesCard'
import ListMoney from 'components/icon/ListMoney'

import { MerchantDashboardApi } from 'services/api/MerchantDashboard/MainApi'
import { ResetPassword } from '../RessetPassword'

enum ACTIONS_KEY {
	SHOW_ON_MAP = 'showOnMap',
	EDIT = 'edit',
	RESET_PASSWORD = 'resetPassword',
	CHANGE_PASSWORD = 'changePassword',
}

const Actions = [
	{
		label: 'Сбросить пароль',
		key: ACTIONS_KEY.RESET_PASSWORD,
		icon: <LockIcon />,
	},
]

interface Props {
	data: MerchantsDetail
}

const Header: React.FC<Props> = observer(({ data }) => {
	const merchantId = data?.id

	const fileInput = useRef<HTMLElement | undefined>()

	const { modalStore, resultRequestStore, merchantStore, user } = store

	const [transitAccounts, setTransitAccounts] = useState<TransitAccount[]>([])
	const [rkoAccounts, setRkoAccounts] = useState<RkoAccount[]>([])

	const isEditable = !user.actions.readOnly

	const loadTransitAccounts = async () => {
		const data =
			merchantId &&
			(await MerchantDashboardApi.getMerchantTransitAccounts(merchantId))
		// @ts-ignore
		setTransitAccounts(data.data)
	}

	const loadRkoAccounts = async () => {
		const data =
			merchantId &&
			(await MerchantDashboardApi.getMerchantRkoAccounts(merchantId))
		// @ts-ignore
		setRkoAccounts(data.data.rko_accounts)
	}

	useEffect(() => {
		loadTransitAccounts()
		loadRkoAccounts()
	}, [])

	const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files) {
			return
		}

		const formData = new FormData()

		formData.append('icon', e.target.files[0])

		try {
			const res = await MerchantDashboardApi.updateIcon(data.id, formData)

			merchantStore.setDetail(res.data)
		} catch (e) {}
	}

	const onChangeField = (field: string, value: any) => async () => {
		merchantStore.setLoading(true)
		try {
			await MerchantDashboardApi.updateMerchantField(data.id, {
				[field]: value,
			})

			merchantStore.getAsyncDetail(merchantId)
		} catch (e) {}
	}

	const resetHandler = async (data: ResetPasswordInterface) => {
		modalStore.openModal({
			body: <AppLoading />,
		})

		try {
			await MerchantDashboardApi.resetPassword({
				id: String(merchantId),
				...data,
			})

			resultRequestStore.setResultRequest({
				type: TYPES.success,
				code: '200',
				description: 'Вы успешно сбросили пароль',
			})
		} catch (error) {
		} finally {
			modalStore.closeModal()
		}
	}

	const actionHandler = (key: string) => {
		switch (key) {
			case ACTIONS_KEY.RESET_PASSWORD:
				modalStore.openModal({
					body: (
						<ResetPassword
							nameCompany={data?.company_name!}
							resetPasswordHandler={resetHandler}
						/>
					),
				})
				return
		}
	}

	const currentTransitBalance = transitAccounts.find(item => item?.is_main)
	const currentRKOtBalance = rkoAccounts.find(item => item?.is_main)

	const openModalList = (columns: any, _data: any, title: string) => {
		modalStore.openModal({
			body: (
				<div>
					<h3>{title}</h3>

					<AppTable columns={columns} data={_data} />
				</div>
			),
		})
	}

	const refreshDataIcon = (onClick: () => void) => (
		<button onClick={() => onClick()} className={styles.editBtn}>
			<RefreshIcon />
		</button>
	)
	const ListModalIcon = (onClick: () => void) => (
		<Tooltip title='Все счета'>
			<button onClick={() => onClick()} className={styles.editBtn}>
				<ListMoney />
			</button>
			<Modal></Modal>
		</Tooltip>
	)

	const TRANSIT_BALANCE_TABLE = [
		{
			dataIndex: 'bic',
			title: 'БИК',
		},
		{
			dataIndex: 'account',
			title: 'Транзитный счет',
		},
		{
			dataIndex: 'balance',
			title: 'Баланс',
		},
		{
			dataIndex: 'currency',
			title: 'Валюта',
		},
	]

	const TRANSIT_BLOCK = [
		{
			key: 'Основной транзитный счет:',
			value: currentTransitBalance?.account,
		},
		{
			key: 'Баланс ТС',
			value: currentTransitBalance?.balance,
			is_hidble: false,
			actions: [
				{ icon: refreshDataIcon(loadTransitAccounts) },
				{
					icon: ListModalIcon(() =>
						openModalList(
							TRANSIT_BALANCE_TABLE,
							transitAccounts,
							'Баланс транзитных счетов'
						)
					),
				},
			],
		},
	]

	const RKO_BALANCE_TABLE = [
		{
			dataIndex: 'rko_bic',
			title: 'БИК',
		},
		{
			dataIndex: 'rko_account',
			title: 'РКО счет',
		},
		{
			dataIndex: 'rko_account_amount',
			title: 'Баланс',
		},
		{
			dataIndex: 'rko_curr',
			title: 'Валюта',
		},
	]

	const RKO_BLOCK = [
		{
			key: 'Основной РКО счет:',
			value: currentRKOtBalance?.rko_account!,
		},
		{
			key: 'Баланс РКО',
			value: currentRKOtBalance?.rko_account_amount!,
			is_hidble: false,
			actions: [
				{ icon: refreshDataIcon(loadRkoAccounts) },
				{
					icon: ListModalIcon(() =>
						openModalList(
							RKO_BALANCE_TABLE,
							rkoAccounts,
							'Баланс расчётных счетов'
						)
					),
				},
			],
		},
	]

	return (
		<div className={styles.wrapper}>
			<Spin spinning={merchantStore.isLoading}>
				<div className={styles.header}>
					<div className={styles.title}>
						<div className={styles.avatar}>
							{/* {data?.icon ? (
								<Image
									src={data.icon}
									className={styles.image}
									preview={false}
									fallback={MbankLogo}
								/>
							) : (
								<Skeleton.Image active className={styles.image} />
							)} */}

							{isEditable && (
								<>
									<input
										type='file'
										// @ts-ignore
										ref={fileInput}
										hidden
										onChange={handleFileInput}
										accept={'.jpg,.png'}
									/>

									<button
										className={styles.edit}
										onClick={e =>
											fileInput.current && fileInput.current?.click()
										}
									>
										<PencilIcon />
									</button>
								</>
							)}
						</div>

						<div className={styles.name}>
							<AppTitle title={data?.company_name || data.name} />

							<p className={styles.category}>
								{/* @ts-ignore */}
								Категория бизнеса: {data?.category?.name || 'Не выбрано'}
							</p>
							<p className={styles.category}>
								Дата регистрации: {displayDate(data?.created_date)}
							</p>
						</div>
						<div>{/* <p> Дата создания - </p> */}</div>
					</div>

					{isEditable && (
						<div className={styles.menu}>
							<AppMenu items={Actions} onClick={actionHandler} />
						</div>
					)}
				</div>

				<div className={styles.navigators}>
					{/* @ts-ignore */}
					<HadbleValuesCard list={RKO_BLOCK} />
				</div>

				<div className={styles.actionSwitches}>
					<div className={styles.actionItem}>
						<AppSwitch
							label={'Работа по сменам:'}
							// @ts-ignore
							isChecked={data?.use_working_plan}
							// disabled={!isEditable}
							disabled={true}
							onChange={onChangeField(
								'use_working_plan',
								// @ts-ignore
								!data?.use_working_plan
							)}
						/>
					</div>
				</div>
			</Spin>
		</div>
	)
})

export default Header
