import clsx from "clsx";
import React from "react";

import styles from "./AppTitle.module.scss";

interface Props {
  title: string;
}

const AppTitle: React.FC<Props> = ({ title }) => {
  return (
    <div className={styles.container}>
      <h2>{title}</h2>
    </div>
  );
};

export default AppTitle;
