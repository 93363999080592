import {
	BranchLocalitiesItem,
	Localities,
	CreateBranch,
} from './../BranchesApi.models'
import { ResetMerchants } from './../MerchantsApi'
import { Pagination } from 'models/pagination.models'
import { TransactionList } from '../TransactionsApi.module'
import {
	MerchantsDetail,
	RKOBalance,
	RkoAccountData,
	TransitAccount,
	TransitBalance,
} from '../MerchantsApi.models'
import { clientApi } from '../clientApi'

interface AuthUser {
	device_uuid: string
	login: string
	password: string
}
interface Props extends Pagination {
	company?: string
	branches__in?: string
	query?: string
}
export const MerchantDashboardApi = {
	authUser: async (data: AuthUser) => {
		return clientApi.post('users/login/', data)
	},
	getTransactions(params: object) {
		return clientApi.get<TransactionList>(`crm/crm_transactions/`, { params })
	},
	getListBranches(params?: Props) {
		return clientApi.get(`crm/crm_branches/`, { params })
	},
	getCashier(params?: Props) {
		return clientApi.get('crm/crm_cashiers/', { params })
	},
	getCashRegister(params?: Props) {
		return clientApi.get('crm/crm_cash_register/', { params })
	},
	getShifts(params: Props) {
		return clientApi.get(`crm/crm_shifts/`, { params })
	},
	getMerchantTransitAccounts(id: number | string) {
		return clientApi.get<TransitAccount[]>(
			`crm/crm_merchants/${id}/transit_account/`
		)
	},
	getMerchantRkoAccounts(id: number | string) {
		return clientApi.get<RkoAccountData>(`crm/crm_merchants/${id}/rko_account/`)
	},
	getMerchantMessages(params: { merchantId: number }) {
		return clientApi.get<RkoAccountData>(`/crm/crm_merchant_comment/`, {
			params,
		})
	},
	createMerchantComment(body: { comment: string; merchant: number }) {
		return clientApi.post(`/crm/crm_merchant_comment/create_comment/`, body)
	},
	getMerchantBalanc({ id }: { id: number }) {
		return clientApi.get<TransitBalance>(
			`/crm/crm_merchants/${id}/main_transit_data/`
		)
	},
	getRkotBalance({ id }: { id: number }) {
		return clientApi.get<RKOBalance>(`/crm/crm_merchants/${id}/main_rko_data/`)
	},
	togglePrintKKM({ id }: { id: number }) {
		return clientApi.post<MerchantsDetail>(
			`/crm/crm_merchants/${id}/kkm-check/`
		)
	},
	updateIcon(id: string | number, data: object) {
		return clientApi.post(`crm/crm_merchants/${id}/update_icon/`, data)
	},
	updateMerchantField(id: number, data: object) {
		return clientApi.patch(`crm/crm_merchants/${id}/`, data)
	},
	resetPassword({ id, ...data }: ResetMerchants) {
		return clientApi.post(`crm/crm_merchants/${id}/reset_password/`, data)
	},
	getBranchLocalities(params?: Localities) {
		return clientApi.get<BranchLocalitiesItem[]>(
			`crm/crm_branches/localities/`,
			{ params }
		)
	},
	createBranch(data: CreateBranch) {
		return clientApi.post(`crm/crm_branches/`, data)
	},
}
