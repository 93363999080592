const EyeCloseIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0459 6.87888C12.7025 6.84004 12.3537 6.81778 12 6.81225C10.4546 6.81942 8.85266 7.2125 7.33631 7.96571C6.21044 8.54799 5.11371 9.37003 4.16126 10.384C3.69348 10.9017 3.09647 11.6511 3 12.4508C3.0114 13.1435 3.7223 13.9985 4.16126 14.5176C5.0544 15.4918 6.12257 16.2903 7.33631 16.9359C7.37756 16.9569 7.41896 16.9775 7.46045 16.998L6.33437 19.0544L7.86444 20L16.1357 4.94126L14.6629 4L13.0459 6.87888ZM16.5385 7.90596L15.4145 9.94285C15.9316 10.6453 16.2385 11.5115 16.2385 12.4508C16.2385 14.792 14.3407 16.6901 11.9989 16.6901C11.8977 16.6901 11.7995 16.6786 11.7001 16.6717L10.9563 18.0181C11.2993 18.0565 11.6461 18.0845 12 18.0894C13.5468 18.0821 15.1479 17.6845 16.6626 16.9359C17.7885 16.3536 18.8863 15.5316 19.8387 14.5176C20.3065 14 20.9035 13.2505 21 12.4508C20.9886 11.7581 20.2777 10.9031 19.8387 10.384C18.9456 9.40984 17.8763 8.61133 16.6626 7.96568C16.6216 7.94491 16.5797 7.92623 16.5385 7.90596ZM11.9989 8.21156C12.1016 8.21156 12.2035 8.21586 12.3043 8.22305L11.4331 9.80041C10.2104 10.0596 9.29297 11.1475 9.29297 12.4497C9.29297 12.7768 9.35058 13.0899 9.45667 13.3802C9.45679 13.3805 9.45654 13.3811 9.45667 13.3814L8.58324 14.9633C8.06498 14.2603 7.75926 13.3912 7.75926 12.4508C7.75928 10.1096 9.65712 8.21155 11.9989 8.21156ZM14.5334 11.5375L12.5702 15.0943C13.7863 14.8302 14.6972 13.7473 14.6972 12.4497C14.6972 12.1285 14.6359 11.8232 14.5334 11.5375Z"
        fill="#B5C7D9"
      />
    </svg>
  );
};

export default EyeCloseIcon;
