import { User as INTERFACE_USER } from 'models/user.models'
import { UserAuth as AuthUser } from 'models/auth.model'
import { deleteStorage } from 'services/storage'

import { makeAutoObservable } from 'mobx'
import { UserTypes } from 'constants/User'
import { User } from '../UserStore'

export interface UserActions {
	fullAccess: boolean
	readOnly: boolean
}

export class UserMerchant {
	user: INTERFACE_USER | null = null

	actions: UserActions = {
		fullAccess: false,
		readOnly: false,
	}

	constructor() {
		makeAutoObservable(this, {}, { autoBind: true })
	}

	setUserAuth(data: User) {
		// @ts-ignore
		this.user = data
	}

	setUser(userInfo: INTERFACE_USER) {
		this.user = userInfo
		this.actions.fullAccess = userInfo?.user_type === 'CRM_ADMIN'
		this.actions.readOnly = userInfo?.user_type === UserTypes.CRM_READONLY
	}

	getUser() {
		return this.user
	}
	destroy() {
		this.user = null
		this.actions.fullAccess = false
		this.actions.readOnly = false
	}

	resetUser() {
		deleteStorage()
	}
}
