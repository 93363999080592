import clsx from "clsx";
import { PALETTE } from "styles/palette";

import s from "./styles.module.scss";

export interface StatusItem {
  label: string;
  key: string;
  sub?: string;
  active?: boolean;
  color: keyof typeof PALETTE;
  count?: number;
}

interface Props {
  statuses: StatusItem[];

  active?: StatusItem["key"];
  onClick: (status: StatusItem) => void;
}

const AppStatuses = ({ statuses, active = "", onClick }: Props) => {
  return (
    <ul className={s.list}>
      {statuses?.map((i) => {
        return (
          <li
            onClick={() => onClick(i)}
            className={clsx(
              s.li,
              active === i.key && s.active,
              i.active && s.active
            )}
            key={i.key}
          >
            {i.color && (
              <div
                style={{
                  backgroundColor: `${PALETTE[i.color]}`,
                }}
                className={s.w}
              />
            )}
            <div className={s.label}>{i.label}</div>
            {i.count !== undefined && i.count !== null && (
              <div className={s.count}>{i.count}</div>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default AppStatuses;
