import { Route, Routes } from 'react-router'
import { observer } from 'mobx-react-lite'

import { store } from 'store'

import Table from './components/Table'
import CreateBranch from './components/Create'

const Branches = observer(() => {
	const { user } = store

	const canCreate = !user.actions.readOnly
	return (
		<Routes>
			<Route path='/' element={<Table canCreate={canCreate} />} />

			{canCreate && <Route path='/create/' element={<CreateBranch />} />}
		</Routes>
	)
})

export default Branches
