// @ts-nocheck
import * as React from "react";
import ReactInputMask from "react-input-mask";
import { KG_PHONE_MAX_LENGTH } from "constants/NumberLength";
import { clearPhone } from "helpers/clearPhone";
import AppInput, { AppInputProps } from "components/AppInput";

export type PhoneEvent = {
  phone: string;
  formattedPhone: string;
  isValid: boolean;
};

interface Props {
  value?: string;
  onChangePhone?: (event: PhoneEvent) => void;
}

const AppInputPhone: React.FC<Props & AppInputProps> = ({
  value,
  onChangePhone,
  disabled,
  ...props
}) => {
  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const phone = event?.currentTarget.value;
    const formattedPhone = `+${clearPhone(phone)}`;
    const isValid = formattedPhone.length === KG_PHONE_MAX_LENGTH;
    onChangePhone({ phone, formattedPhone, isValid });
  };
  return (
    <ReactInputMask
      formatChars={{
        "1": "[0-9]",
        a: "[A-Za-z]",
        "*": "[A-Za-z0-9]",
      }}
      // alwaysShowMask
      mask="+996111111111"
      value={value}
      onChange={onChangeText}
      disabled={disabled}
    >
      {
        /*@ts-nocheck*/ (inputProps: any) => (
          <AppInput type="tel" {...inputProps} {...props} disabled={disabled} />
        )
      }
    </ReactInputMask>
  );
};

export default AppInputPhone;
