import React from "react";
import { FieldError } from "react-hook-form";

import { AppFieldWrapper } from "../../AppFieldWrapper";

import { Select } from "antd";
import { get, uniqueId } from "lodash";
import clsx from "clsx";

import styles from "./SelectField.module.scss";

import { DebounceSelect } from "./DebounceSelect";
import { SIZE_INPUT } from "../../../constants/ComponentSize";

interface OptionProps {
  id: string | number;
  value: string | number;
}

interface Props {
  value?: any;
  label?: string;
  onSearch?: (value: string) => void;
  options?: any[];
  loading?: boolean;
  onChange?: (value: any) => void;
  error?: FieldError;
  fetchOptions?: (search: string) => Promise<[]>;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  showSearch?: boolean;
  optionLabelKey?: string;
  sizeInput?: SIZE_INPUT;
}

const { Option } = Select;

const SelectField: React.FC<Props> = React.forwardRef((props, ref) => {
  const {
    label,
    onSearch,
    options,
    loading,
    onChange,
    value,
    error,
    fetchOptions,
    placeholder,
    className,
    disabled,
    optionLabelKey = "name",
    sizeInput,
    showSearch = !!onSearch,
    ...restProps
  } = props;

  const variantSize = {
    small: styles.small,
    normal: styles.normal,
  };

  if (fetchOptions) {
    return (
      <AppFieldWrapper label={label}>
        <DebounceSelect
          className={clsx([
            styles.wrapper,
            variantSize[sizeInput || SIZE_INPUT.NORMAL],
            className,
            disabled && styles.disabled,
          ])}
          filterOption={false}
          bordered={false}
          onChange={onChange}
          value={value}
          showSearch={showSearch}
          fetchOptions={fetchOptions}
          optionLabelKey={optionLabelKey}
          placeholder={placeholder}
          disabled={disabled}
          {...restProps}
        />

        <div className={styles.error}>{error?.message}</div>
      </AppFieldWrapper>
    );
  }

  return (
    <AppFieldWrapper label={label}>
      <Select
        // @ts-ignore
        {...restProps}
        className={clsx([
          styles.wrapper,
          variantSize[sizeInput || SIZE_INPUT.NORMAL],
          className,
          disabled && styles.disabled,
        ])}
        labelInValue
        filterOption={false}
        bordered={false}
        onSearch={onSearch}
        loading={loading}
        onChange={onChange}
        value={value}
        showSearch={showSearch}
        placeholder={placeholder}
        disabled={disabled}
      >
        {options?.map((option) => (
          <Option key={uniqueId("optionKey")} value={option?.id}>
            {get(option, optionLabelKey, "-")}
          </Option>
        ))}
      </Select>

      <div className={styles.error}>{error?.message}</div>
    </AppFieldWrapper>
  );
});

export default SelectField;
