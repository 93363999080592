import { AppTabs, LoadingArea } from 'components'
import { Navigate, Route, Routes, useResolvedPath } from 'react-router'
import s from './main.module.scss'
import { store } from 'store'
import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import TransactionsCommon from 'shared/common/reports/transactions-common'
import ShiftsCommon from 'shared/common/shift'
import HeaderCashier from 'shared/common/headers'
import CashierInfo from 'shared/common/cashier'

export const Main = observer(() => {
	const { user, cashierStore } = store
	const pathname = useResolvedPath('').pathname

	const cashierDetail = cashierStore.getDetailInfo()

	const userData = user?.getUser()

	const getCashierData = () => {
		if (!cashierDetail) {
			cashierStore.getAsyncDetail(userData?.cashier_id)
		}
	}

	const tabOptions = [
		{
			label: <NavLink to={`${pathname}detail/`}>Данные кассира</NavLink>,
			key: `${pathname}detail/`,
		},
		{
			label: <NavLink to={`${pathname}transactions/`}>Транзакции</NavLink>,
			key: `${pathname}transactions/`,
		},
		{
			label: (
				<NavLink to={`${pathname}shifts/?cashierId=${userData?.cashier_id}`}>
					Смены
				</NavLink>
			),
			key: `${pathname}shifts/`,
		},
	]

	useEffect(() => {
		getCashierData()
	}, [user, userData])

	if (!cashierDetail) {
		return null
	}
	return (
		<div className={s.wrapper}>
			<LoadingArea loading={false}>
				<HeaderCashier data={cashierDetail} />
				<div className={s.tabs}>
					<AppTabs items={tabOptions} />
				</div>
				<Routes>
					<Route
						path={`/detail/`}
						element={<CashierInfo data={cashierDetail} />}
					/>

					<Route
						path={`/transactions/`}
						element={
							<TransactionsCommon params={{ cashier: userData?.cashier_id }} />
						}
					/>

					<Route path={`/shifts/`} element={<ShiftsCommon hideCashierName />} />
					<Route path={`*`} element={<Navigate to={`${pathname}detail/`} />} />
				</Routes>
			</LoadingArea>
		</div>
	)
})
