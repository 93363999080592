import React from 'react'

import { makeAutoObservable } from 'mobx'

export enum ModalSizes {
  Small,
  Medium,
  Large,
}

export type ModalData = {
  size?: ModalSizes
  body: React.ReactNode
  height?: number
  className?: string
}

export class ModalStore {
  visible: boolean = false
  body: React.ReactNode = null
  size: ModalSizes = ModalSizes.Small
  height: string | number = 'auto'
  className: string = ''
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  resetData() {
    this.visible = false
    this.body = null
    this.size = ModalSizes.Small
  }

  openModal(data: ModalData) {
    this.body = data.body
    this.size = data.size ?? ModalSizes.Medium
    this.visible = true
    this.className = data.className || ''
    this.height = data.height ?? 'auto'
    document.body.style.overflow = 'hidden'
  }

  chaneSize(id: number){
    this.size = id
  }

  closeModal() {
    this.resetData()
    document.body.style.overflow = 'scroll'
  }
}
