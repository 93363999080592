import { useNavigate, useResolvedPath, useSearchParams } from 'react-router-dom'

import { useTableData } from 'hooks'

import { CashierItem } from 'services/api/CashierApi.models'
import { MerchantsItem } from 'services/api/MerchantsApi.models'
import { AppButton, AppInput, AppTable } from 'components'

import styles from './table.module.scss'
import { CashierApi } from 'services/api/CashierApi'
import { store } from 'store'
import SortTitle from 'components/Sort/SortTitle'
import AppTextFiledQuery from 'components/AppTextFiledQuery'
import { SearchIcon, UserPlusIcon } from 'components/icon'
import { SIZE_BUTTON } from 'constants/ComponentSize'

interface Table {
	canCreate: boolean
}

const Table: React.FC<Table> = props => {
	const { canCreate } = props
	const [searchParams] = useSearchParams()
	const order_by = searchParams.get('order_by')
	const searchCashier = searchParams.get('searchCashier')

	const { user } = store
	const userData = user.getUser()
	
	const navigate = useNavigate()
	const pathname = useResolvedPath('').pathname

	const {
		loading,
		tableData,
		handleTableChange,
		pagination,
		// @ts-ignore
	} = useTableData(CashierApi.getCashier as any, {
		company: userData?.company_id,
		order_by,
		query: searchCashier,
	})

	const showDetail = (
		record: MerchantsItem,
		e: React.MouseEvent<any, MouseEvent>
	) => {
		if (e.metaKey || e.ctrlKey) {
			window.open(`${pathname}${record.id}/detail/`)
			return
		}

		navigate(`${pathname}${record.id}/detail/`)
	}

	const tableColumns = [
		{
			dataIndex: 'id',
			title: '№',
		},
		{
			dataIndex: 'cashier',
			title: 'Кассир',
			render: (value: string, record: CashierItem) => record.user.first_name,
		},
		{
			dataIndex: 'store',
			title: 'Торговая точка',
			render: (value: string, record: CashierItem) =>
				record.branches.map(branch => branch.name).join(', ')
		},
		{
			dataIndex: 'login',
			title: 'Логин',
			render: (value: string, record: CashierItem) => record.user.login,
		},
		{
			dataIndex: 'inn',
			title: 'ИНН',
			render: (value: string, record: CashierItem) => record.user.inn,
		},
		{
			dataIndex: 'phone_number',
			title: 'Номер телефона',
			render: (value: string, record: CashierItem) => record.user.phone_number,
		},
		{
			dataIndex: 'transaction_sum',
			title: () => (
				<>
					<SortTitle
						paramKey='order_by'
						value='transaction_sum'
						title='Сумма'
					/>
				</>
			),
		},

		{
			dataIndex: 'transaction_count',
			title: () => (
				<>
					<SortTitle
						paramKey='order_by'
						value='transaction_count'
						title='Количество успешных транзакций'
					/>
				</>
			),
		},
		{
			dataIndex: 'mpos_type',
			title: 'Тип',
			render: (value: boolean) => value,
		},
		{
			dataIndex: 'api_key',
			title: 'API ключ',
		},
	]

	const onCreate = () => {
		navigate('create')
	}

	return (
		<div className={styles.wrapper}>
			<div className={styles.filters}>
				<div className={styles.input}>
					<AppTextFiledQuery
						icon={<SearchIcon />}
						queryKey='searchCashier'
						placeholder='Поиск'
					/>
				</div>
				{userData?.can_create_cashier && <div className={styles.buttons}>
					<AppButton
						size={SIZE_BUTTON.SMALL}
						onClick={onCreate}
						variant='outline'
						iconLeft={<UserPlusIcon />}
						className={styles.button}
					>
						Создать кассира
					</AppButton>
				</div>}
			</div>
			<div className={styles.table}>
				<AppTable
					columns={tableColumns}
					data={tableData?.results}
					pagination={pagination}
					onChange={handleTableChange}
					loading={loading}
					onRow={(record: any) => {
						return {
							onClick: e => {
								showDetail(record, e)
							},
						}
					}}
				/>
			</div>
		</div>
	)
}

export default Table
