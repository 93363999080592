import React from "react";
import { ConfigProvider, Select, SelectProps } from "antd";
import clsx from "clsx";
import { PALETTE } from "styles/palette";
import styles from "./styles.module.scss";

const SelectField: React.FC<SelectProps> = (props) => {
  const { className } = props;

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBorder: PALETTE.$light_blue_3,
          colorPrimaryHover: PALETTE.$siren,
          colorTextPlaceholder: PALETTE.$light_blue_3,
          colorBgContainer: PALETTE.$light_gray_3,
        },
      }}
    >
      <Select className={clsx(className, styles.select)} {...props} />
    </ConfigProvider>
  );
};

export default SelectField;
