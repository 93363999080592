import dayjs from 'dayjs'

export const API_DATE_FORMAT = 'YYYY-MM-DD'
export const API_DATE_FORMAT_HOUR = 'YYYY-MM-DD HH:mm:ss'
export const API_DATE_FORMAT_GLOBAL = 'YYYY-MM-DD[T]HH:mm:ss'
export const API_DATE_FORMAT_CHECK = 'DD.MM.YYYY, HH:mm:ss'

export const displayDate = (
	date: string | Date | undefined,
	format = 'DD-MM-YYYY HH:mm:ss'
) => {
	try {
		if (date) {
			const d = dayjs(date)
			if (d.isValid()) {
				return d.format(format)
			}
		}
	} catch (error) {}

	return '-'
}

export const displayTimeFromNow = (date: string | Date | undefined) => {
    const createdDate = dayjs(date)
    const now = dayjs()
    const diffInMinutes = now.diff(createdDate, 'minute')
    const diffInHours = now.diff(createdDate, 'hour')
    const diffInDays = now.diff(createdDate, 'day')

    if (diffInDays >= 1) {
        return createdDate.format(API_DATE_FORMAT)
    } else if (diffInHours >= 1) {
        return `${diffInHours} ${
            diffInHours === 1 ? 'час назад' : 'часа(ов) назад'
        }`
    } else {
        return `${diffInMinutes} ${
            diffInMinutes === 1 ? 'минута назад' : 'минут назад'
        }`
    }
}
