import { notification } from 'antd'
import { AppButton, AppStatusIndicator, AppTable } from 'components'
import { DownloadIcon } from 'components/icon'
import { useTableData } from 'hooks'
import { observer } from 'mobx-react-lite'
import { CashoutApi } from 'services/api/Cashout'
import { baseURL } from 'services/api/clientApi'
import { API_DATE_FORMAT, API_DATE_FORMAT_GLOBAL, displayDate, downloadFile } from 'utils'

import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import styles from './style.module.scss'
import AppDatePickerParams from 'components/QuryParamsUI/AppDatePickerParams'
import { store } from 'store'
import { CASHOUT_STATUSES } from 'constants/cashout/cashout-statuses'
import { useParams } from 'react-router'
import dayjs from "dayjs"
import CashoutStatusFilter from './components/CashoutStatusFilter'

interface Props {}

const tableColumns = [
	{
		dataIndex: 'created_at',
		title: 'Дата и время операции',
		render: (value: string) => displayDate(value),
	},
	{
		dataIndex: 'acquiring_id',
		title: 'Эквайринг ID',
	},
	{
		dataIndex: 'amount',
		title: 'Сумма',
	},
	{
		dataIndex: 'j_number',
		title: 'J-номер',
	},
	{
		dataIndex: 'serial_number',
		title: 'Cерийный номер',
	},
	{
		dataIndex: 'status',
		title: 'Статус',
		render: (value: string) => (
			<div className={styles.row}>
				<AppStatusIndicator status={value || ''} />
				{CASHOUT_STATUSES[value || '']?.label || ''}
			</div>
		),
	},
	{
		dataIndex: 'branch_name',
		title: 'Торговая точка',
	},
	{
		dataIndex: 'cashier_full_name',
		title: 'Кассир',
	},
	{
		dataIndex: 'fee',
		title: 'Комиссия',
	},
]
const CashOutTable = observer(({}: Props) => {
	const [notifications] = notification.useNotification()
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const { branchId, cashierId } = useParams()

	const { user, globalWindowApp } = store

	const userData = user.getUser()

	const [searchParams, setSearchParams] = useSearchParams()

	const start_date = searchParams.get('start_date')
	const end_date = searchParams.get('end_date')
    const status = searchParams.get('status')

	const { tableData, loading, handleTableChange, pagination } = useTableData(
		CashoutApi.getCashoutList as any,
		{
			created_at__gte: start_date,
			created_at__lte: end_date,
			branch: branchId,
			cashier: cashierId,
            status: status,
		}
	)

	const downloadReport = async () => {
		if (!start_date) {
			notification.error({
				message: 'Выберите период',
			})
			return
		}

		try {
			globalWindowApp.setLoading({
				title: 'Загрузка отчета',
			})
			setIsLoading(true)
			openNotification()
			await downloadFile(
				`${baseURL}/crm/cash-out/excel/?created_at__gte=${start_date}&created_at__lte=${end_date}&company=${userData?.company_id}`,
				`Отчет по выдаче наличных QR MBANK за ${dayjs(start_date).format(API_DATE_FORMAT)} / ${dayjs(start_date).format(API_DATE_FORMAT)}`
			)
		} catch (e) {
		} finally {
			globalWindowApp.stopLoading()
		}
		setIsLoading(false)
	}
	const openNotification = () => {
		notifications.info({
			message: `Внимание!`,
			description:
				'Началось скачивание отчетов! Это может занять некотрое время',
		})
	}

	const onChangeDate = () => {
		searchParams.set('currentPage', '1')
	}

	useEffect(() => {
		onChangeDate()
	}, [start_date, end_date])

	return (
		<>
			<section className={styles.container}>
				<CashoutStatusFilter />
				<div className={styles.filters}>
					<AppButton
						disabled={isLoading || !tableData?.results.length}
						onClick={downloadReport}
						iconLeft={<DownloadIcon />}
					>
						Выгрузить в Excel
					</AppButton>

					<AppDatePickerParams format={API_DATE_FORMAT_GLOBAL} disabled={loading} allowClear={false} />
				</div>

				<AppTable
					loading={loading}
					columns={tableColumns}
					data={tableData?.results}
					pagination={pagination}
					onChange={handleTableChange}
				/>
			</section>
		</>
	)
})
export default CashOutTable
