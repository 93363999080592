import React from 'react'
import { Outlet } from 'react-router'
import MerchantHeader from '../MainHeader'

export default function CashierDashboardLayout() {
	return (
		<div>
			<div style={{ marginTop: '20px' }} />
			<MerchantHeader />

			<main>
				<Outlet />
			</main>
		</div>
	)
}
