import { SelectProps } from "antd";
import { DefaultOptionType } from "antd/es/select";
import CashRegisterSelect from "common/cashRegister/CashRegisterSelect";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { setCashRegister } from "store/rtk/slices/filters/transactions-filter.slice";

interface Props extends SelectProps {
  companyId: number | string;
}

const FilterByCashRegister = (props: Props) => {
  const dispatch = useAppDispatch();

  const cashRegister = useAppSelector(
    (store) => store.transactionsFilters.cashRegister
  );

  const handleChange = (_: string, option: DefaultOptionType) => {
    dispatch(setCashRegister(option || null));
  };

  return (
    <CashRegisterSelect
      allowClear
      onChange={handleChange}
      defaultValue={cashRegister}
      {...props}
    />
  );
};

export default FilterByCashRegister;
