import { DownloadIcon } from 'components/icon'
import { AppButton } from 'components'
import clsx from 'clsx'
import styles from "./styles.module.scss"

interface Props {
    onClick: () => void
    className?: string
}

const DownloadBtn = (props: Props) => {
    const { onClick, className } = props
    return (
        <AppButton className={clsx(className, styles.downloadBtn)} iconLeft={<DownloadIcon />} onClick={onClick} >
            Скачать
        </AppButton>
    )
}

export default DownloadBtn
