const ErrorIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.91635 4.91675C10.9997 -1.16659 20.9997 -1.16659 27.083 4.91675C33.1663 11.0001 33.1663 21.0001 27.083 27.0834C23.9997 30.1668 19.9997 31.6668 15.9997 31.6668C11.9997 31.6668 7.99968 30.1668 4.91635 27.0834C-1.16699 21.0001 -1.16699 11.0001 4.91635 4.91675ZM6.08301 25.9168C11.4997 31.3334 20.4163 31.3334 25.833 25.9168C31.2497 20.5001 31.2497 11.5834 25.833 6.16675C23.083 3.41675 19.4997 2.08342 15.9163 2.08342C12.333 2.08342 8.74968 3.41675 5.99968 6.16675C0.666348 11.5834 0.666346 20.4168 6.08301 25.9168ZM15.4997 13.8334C15.333 14.0001 15.1663 14.2501 15.1663 14.5834V22.5834C15.1663 22.9167 15.2497 23.1667 15.4997 23.3334C15.7497 23.5001 15.9163 23.5834 16.2497 23.5834C16.4997 23.5834 16.7497 23.5001 16.9997 23.3334C17.1663 23.1667 17.333 22.9167 17.333 22.5834V14.5834C17.333 14.2501 17.2497 14.0001 16.9997 13.8334C16.7497 13.6667 16.583 13.5834 16.2497 13.5834C15.9163 13.5834 15.6663 13.6667 15.4997 13.8334ZM15.2497 9.33342C14.9997 9.58342 14.9163 9.83342 14.9163 10.1667C14.9163 10.5001 14.9997 10.8334 15.2497 11.0001C15.4997 11.2501 15.7497 11.3334 16.1663 11.3334C16.583 11.3334 16.833 11.2501 17.083 11.0001C17.333 10.7501 17.4163 10.5001 17.4163 10.1667C17.4163 9.83342 17.333 9.50008 17.083 9.33342C16.833 9.08342 16.4997 9.00008 16.1663 9.00008C15.833 9.00008 15.4997 9.08342 15.2497 9.33342Z"
        fill="#F5222D"
      />
    </svg>
  );
};

export default ErrorIcon;
