import clsx from "clsx";
import styles from "./styles.module.scss";
import { STATUS_TEXT as STATUS, TYPE_STATUS } from "constants/TypeStatus";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { setStatus } from "store/rtk/slices/filters/transactions-filter.slice";
import { AppStatusIndicator } from "components";
import { Counters } from "models/counters.model";

interface Props {
  totalSum: number | string;
  counters?: Counters;
  disabled?: boolean;
  className?: string;
}

const FilterByStatus = (props: Props) => {
  const { totalSum, disabled, counters, className } = props;

  const dispatch = useAppDispatch();

  const activeStatus = useAppSelector(
    (store) => store.transactionsFilters.status
  );

  const handleStatusChange = (status: TYPE_STATUS) => {
    if (disabled) return;
    dispatch(setStatus(status));
  };

  return (
    <>
      {totalSum && (
        <div className={styles.block}>
          <h2>Общая сумма транзакций:</h2>

          <p>{totalSum}</p>
        </div>
      )}
      {STATUS.map((status, index) => (
        <div
          className={clsx(
            styles.status,
            activeStatus === status.key && styles.active,
            !activeStatus && status.key === "all" && styles.active,
            disabled && styles.disabled,
            className
          )}
          key={index}
          onClick={() => handleStatusChange(status.key)}
        >
          <span className={styles.indicator}>
            <AppStatusIndicator status={status.key} />
          </span>

          {status.name}

          <span className={styles.count}>
            {/* @ts-ignore */}
            {counters?.[status.key] || 0}
          </span>
        </div>
      ))}
    </>
  );
};

export default FilterByStatus;
