import { makeAutoObservable } from "mobx";

import {
  MerchantsDetail,
  PreCreateMerchant,
} from "services/api/MerchantsApi.models";
import { MerchantsApi } from "services/api/MerchantsApi";

class MerchantDetailStore {
  detailInfo: MerchantsDetail | null = null;
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(val: boolean) {
    this.isLoading = val;
  }

  setDetail(data: MerchantsDetail | null) {
    this.detailInfo = data;
  }

  getDetailInfo() {
    return this.detailInfo;
  }

  clearState() {
    this.setDetail(null);
  }
  async getAsyncDetail(id: string | number | undefined) {
    if (!id) return ;
    this.setDetail(null);

    if (!Number.isInteger(+id)) {
        return
    }

    this.setLoading(true);

    try {
        const {data} = await MerchantsApi.getMerchantDetail(id as string);

        this.setDetail(data);
    } catch (error) {
    }

    this.setLoading(false);
}
}

export default MerchantDetailStore;
