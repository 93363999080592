import { MKASSA_ARM_TOKEN } from './constants'
import { setLocalStorage, deleteLocalStorage } from './localStorage'
import { setSessionStorage, deleteSessionStorage } from './sessionStorage'

export interface Props {
	key?: string
	value: string
}

interface saveStorage {
	saveLocal: boolean
	key?: string
	value: string
}

export const saveInStorageMerchantUser = ({
	saveLocal,
	value,
	key = MKASSA_ARM_TOKEN,
}: saveStorage) => {
	if (saveLocal) {
		setLocalStorage({ value, key })
		return
	}

	setSessionStorage({ value, key })
}

export const deleteStorageMerchantUser = () => {
	deleteLocalStorage(MKASSA_ARM_TOKEN)
	deleteSessionStorage(MKASSA_ARM_TOKEN)
}

export const deleteStorageCashierUser = () => {
	deleteLocalStorage(MKASSA_ARM_TOKEN)
	deleteSessionStorage(MKASSA_ARM_TOKEN)
}
