import clsx from 'clsx'
import s from './list-outline.module.scss'

interface ListItem {
	key: string
	value: string | number
}

interface Props {
	list: ListItem[]
	variant?: ListColors
}

type ListColors = 'info' | 'warning'

const LIST_VARIANTS: Record<ListColors, string> = {
	info: s.info,
	warning: s.warning,
}

export default function ListOutline({ list, variant = 'info' }: Props) {
	return (
		<ul className={clsx(s.list, LIST_VARIANTS[variant])}>
			{list.map(item => {
				return (
					<li className={s.item} key={item.key}>
						<p className={s.key}>{item.key}</p>
						<p className={s.value}>{item.value}</p>
					</li>
				)
			})}
		</ul>
	)
}
