import { DefaultOptionType, SelectProps } from "antd/es/select";
import AppAsyncSelect from "components/AppAsyncSelect";
import { CashierApi } from "services/api/CashierApi";

const CashierSelect = (props: SelectProps) => {
  const fetchCashierOptions = async (
    search: string,
    limit: number,
    offset: number
  ): Promise<{ results: DefaultOptionType[]; count: number }> => {
    const response = await CashierApi.getCashier({
      query: search,
      limit,
      offset,
    });
    return {
      results: response.data.results.map((item: any) => ({
        label: `${item.user.first_name} - ${item.branches
          .map((branch: any) => branch.name)
          .join(", ")}`,
        value: item.id,
      })),
      count: response.data.count,
    };
  };

  return (
    <AppAsyncSelect
      fetchOptions={fetchCashierOptions}
      placeholder="Выберите кассира"
      {...props}
    />
  );
};

export default CashierSelect;
