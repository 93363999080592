import React from "react";

import { ErrorIcon, SuccessIcon, WarningIcon } from "components/icon";
import { TYPES as TYPE } from "constants/TypeResults";
import styles from "./AppWrapperIcon.module.scss";

import clsx from "clsx";

export const TYPES = [
  {
    type: TYPE.info,
  },
  {
    type: TYPE.error,
    icon: <ErrorIcon />,
  },
  {
    type: TYPE.success,
    icon: <SuccessIcon />,
  },
  {
    type: TYPE.warning,
    icon: <WarningIcon />,
  },
];

interface Props {
  type: TYPE;
  icon?: React.ReactNode;
}

const AppWrapperIcon: React.FC<Props> = ({ type, icon }) => {
  const variantColor = {
    ERROR: styles.error,
    WARNING: styles.warning,
    SUCCESS: styles.success,
    INFO: styles.info,
  };

  const currentIcon = TYPES.filter((itemType) => itemType.type === type)[0]
    ?.icon;
  return (
    <div className={clsx(styles.container, variantColor[type])}>
      {currentIcon ? currentIcon : icon}
    </div>
  );
};

export default AppWrapperIcon;
