import React from "react";

import { FieldError } from "react-hook-form";
import { SIZE_INPUT } from "constants/ComponentSize";
import { AppFieldWrapper } from "../AppFieldWrapper";
import clsx from "clsx";
import { EyeCloseIcon, EyeOpenIcon } from "components/icon";

import styles from "./AppPasswordInput.module.scss";

interface Props extends React.HTMLProps<HTMLInputElement> {
  placeholder?: string;
  label?: string;
  error?: FieldError;
  value?: string;
  sizeInput?: SIZE_INPUT;
  onChangeHandler?(value: string): void;
}

const AppPasswordInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      value,
      onChangeHandler,
      sizeInput = SIZE_INPUT.NORMAL,
      label,
      error,
      ...props
    },
    ref
  ) => {
    const [hidePassword, setHidePassword] = React.useState<boolean>(true);

    const variantSize = {
      small: styles.small,
      normal: styles.normal,
    };

    return (
      <>
        <div className={styles.container}>
          <AppFieldWrapper label={label}>
            <input
              
              className={clsx(styles.input, variantSize[sizeInput])}
              ref={ref}
              type={hidePassword ? "password" : "text"}
              {...props}
            />
          </AppFieldWrapper>

          <span
            className={styles.icon}
            onClick={() => setHidePassword(!hidePassword)}
          >
            {
              hidePassword ? <EyeOpenIcon /> : <EyeCloseIcon /> 
            }
            
          </span>
        </div>

        {error && <p className={styles.error}>{error?.message}</p>}
      </>
    );
  }
);

AppPasswordInput.displayName = "AppPasswordInput";

export default AppPasswordInput;
