import React, {ReactNode} from 'react';

import {Spin} from 'antd';
import clsx from "clsx";

import {NoData, Error} from "../index";

import styles from './LoadingArea.module.scss';

interface LoadingArea {
    loading: boolean;
    spin?: boolean;
    showNoData?: boolean;
    over?: boolean;
    hideRefresh?: boolean;
    children: ReactNode | null | ReactNode[];
    error?: object;
    onRefresh?: () => void;
}

const LoadingArea: React.FC<LoadingArea> = (props): any => {
    const {
        children,
        loading,
        spin,
        showNoData,
        over,

        onRefresh,
        hideRefresh,

        error,
    } = props;

    if (loading && !spin) {
        return <Spin spinning />
    }

    if (loading && spin) {
        return (
            <Spin spinning={loading} className={clsx(over && styles.over)}>
                {children}
            </Spin>
        )
    }

    if (!loading && error) {
        return <Error errorInfo={error} onRefresh={onRefresh} />
    }

    if (showNoData && !loading) {
        return <NoData onRefresh={onRefresh} hideRefresh={hideRefresh} />
    }

    return children || null;
};

export default LoadingArea;
