const shiftDetail = {
  ":shiftId": {
    entityKey: "shiftStore",
    fieldKey: "detailInfo.id",
    routes: {
      transactions: {
        title: "Транзакции",
      },
    },
  },
};

const cashierDetail = {
  ":cashierId": {
    entityKey: "cashierStore",
    // fieldKey: 'detailInfo.user.first_name',
    fieldKey: "detailInfo.user.first_name",
    routes: {
      detail: {
        title: "Данные",
      },
      transactions: {
        title: "Транзакции",
      },
      shifts: {
        title: "Смены",
      },
      cashout: {
        title: "Выдача наличных",
      },
    },
  },
};

const branchDetail = {
  ":branchId": {
    entityKey: "branchStore",
    fieldKey: "detailInfo.name",
    routes: {
      cashiers: {
        title: "Кассиры",
        routes: {
          create: {
            title: "Создание",
          },
          ...cashierDetail,
        },
      },
      detail: {
        title: "Данные",
        routes: {
          edit: {
            title: "Редактирование",
          },
        },
      },
      transactions: {
        title: "Транзакции",
      },
      shifts: {
        title: "Смены",
        routes: {
          ...shiftDetail,
        },
      },
      cash_register: {
        title: "ККМ",
      },
      cashout: {
        title: "Выдача наличных",
      },
    },
  },
};

const transactionDetail = {
  ":transactionDetailId": {
	title: "Детали"
  },
};

const MERCHANT_DASHBOARD_BREADCRUBMS = {
  "merchant-user": {
    routes: {
      dashboard: {
        routes: {
          detail: {
            title: "Общее",
          },
          branches: {
            title: "Торговые точки",
            routes: {
              ...branchDetail,
            },
          },
          cashier: {
            title: "Кассиры",
            routes: { ...cashierDetail },
          },
          cash_register: {
            title: "ККМ",
          },
          reports: {
            title: "Транзакции",
          },
          shifts: {
            title: "Смены",
            routes: {
              ...shiftDetail,
            },
          },
          "transaction-detail": {
            title: "Детальная выписка",
            routes: {
				...transactionDetail
			},
          },
        },
      },
    },
  },
};

export default MERCHANT_DASHBOARD_BREADCRUBMS;
