export enum ReportType {
    DETAILED_REPORT = 'DETAILED_REPORT',
    TRANSACTION_REPORT = 'TRANSACTION_REPORT',
}

export enum ReportStatusEnum {
    COMPLETED = 'completed',
    PROCESSING = 'processing',
    ERROR = 'error',
}

export interface Report {
    id: number
    report_type: ReportType
    status: ReportStatusEnum
    created_at: string
    file_link: string
    name: string
}
