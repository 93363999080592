import { Col, Row } from 'antd'
import clsx from 'clsx'
import { AppButton } from 'components'
import { get, uniqueId } from 'lodash'
import { observer } from 'mobx-react-lite'
import { store } from 'store'
import { displayDate } from 'utils'

import React, { useMemo } from 'react'
import { useNavigate } from 'react-router'

import styles from './DataView.module.scss'

interface Columns {
	label: string
	dataIndex: string | string[]
	type?: string
	optionKey?: string
	optionValues?: object
	format?: string
	prefix?: string

	slot?: React.ReactNode
}

interface DataView {
	columns: Columns[][] | Columns[]
	data: object
	className?: string
	title?: string
	edtLink?: string
}

const DataView: React.FC<DataView> = observer(props => {
	const { columns, data, className, title, edtLink } = props
	const navigate = useNavigate()

	const { user } = store

	const isEditable = !user.actions.readOnly

	const renderValue = (item: Columns) => {
		if (Array.isArray(item.dataIndex)) {
			return item.dataIndex.map(path => get(data, path, '')).join(' ')
		}

		if (item.type === 'options') {
			// @ts-ignore
			return data[item.dataIndex].map(option => (
				<span className={styles.optionValue} key={uniqueId('optionKey')}>
					{get(option, item.optionKey || 'undefined', undefined)}
				</span>
			))
		}

		if (item.type === 'option') {
			const optionVal = get(data, item.dataIndex, undefined)
			// @ts-ignore
			return item.optionValues?.[optionVal]
		}

		if (item.type === 'date') {
			return displayDate(get(data, item.dataIndex, ''), item.format)
		}

		return get(data, item.dataIndex, undefined)
	}

	const renderData = (item: Columns) => {
		if (item?.slot) {
			return (
				<div className={styles.rowItem} key={uniqueId('dataRowKey')}>
					{item?.slot}
				</div>
			)
		}

		return (
			<div className={styles.rowItem} key={uniqueId('dataRowKey')}>
				<p className={styles.label}>{item.label}:</p>

				<p className={styles.value}>
					{renderValue(item) || '-'}

					{item.prefix && ` ${item.prefix}`}
				</p>
			</div>
		)
	}

	const renderCols = useMemo(() => {
		if (Array.isArray(columns[0])) {
			return columns.map((column: any) => (
				<Col span={12} key={uniqueId('colKey')} className={styles.column}>
					{column.map((col: Columns) => renderData(col))}
				</Col>
			))
		}
		return (
			<Col span={12} className={styles.column}>
				{columns.map((col: any) => renderData(col))}
			</Col>
		)
	}, [data, columns])

	const onEdit = () => {
		edtLink && navigate(edtLink)
	}

	return (
		<div className={clsx(styles.wrapper, className)}>
			{title && <h3 className={styles.title}>{title}</h3>}

			<div className={styles.content}>
				<Row gutter={[24, 16]}>{renderCols}</Row>

				{isEditable && edtLink && (
					<Row gutter={[24, 16]}>
						<Col span={12}>
							<div className={styles.buttons}>
								<AppButton onClick={onEdit}>Редактировать</AppButton>
							</div>
						</Col>
					</Row>
				)}
			</div>
		</div>
	)
})

export default DataView
