import React from 'react';

import styles from './FieldWrapper.module.scss';

interface Props {
  label?: string;
}

export const AppFieldWrapper: React.FC<
  Props & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, label }) => {
  return (
    <div className={styles.fieldWrapper}>
      {label ? <p>{label}</p> : null}
      {children}
    </div>
  );
};
