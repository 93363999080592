import React from 'react'
import { Layout, Menu } from 'antd'
import MkassaLogo from 'assets/images/svg/short_logo.svg'
import styles from './AdminLayout.module.scss'

import { HomeIcon, StatisticIcon } from 'components/icon'
import AppHeader from 'components/AppHeader'
import { Outlet, useNavigate } from 'react-router'
import HomeIconLittle from 'components/icon/HomeIconLittle'
import { AsiaIcon } from 'components/icon/AsiaIcon'
import { User as INTERFACE_USER } from 'models/user.models'
import { UserActions } from 'store/modules/UserStore'
import ReportIcon from 'components/icon/Reports'
const { Content, Sider } = Layout

interface AdminLayoutProps {
	user?: INTERFACE_USER | null
	access?: UserActions
}

const AdminLayout: React.FC<AdminLayoutProps> = ({ user, access }) => {
	const navigate = useNavigate()

	const monitoring = {
		key: '/',
		icon: <HomeIconLittle className={styles.iconMenu} />,
		label: 'МКасса',
		path: '/*',
	}

	const asia = {
		key: '/asia/',
		icon: <AsiaIcon className={styles.iconMenu} />,
		label: 'Азия',
		path: '/asia/',
	}

	const analitic = {
		key: '/analytics/',
		icon: <StatisticIcon className={styles.iconMenu} />,
		label: 'Модуль отчетов',
	}
	const reports = {
		key: '/reports/',
		icon: <ReportIcon className={styles.iconMenu} />,
		label: 'Аналитика',
	}

	const menu = []

	// TODO Hard code remove to SUPER_MAN
	if (user?.user_type === 'TYPE_CRM_REPORT') {
		// @ts-ignore
		menu.push(analitic)
		// @ts-ignore
		menu.push(reports)
	}

	if (user?.user_type === 'CRM_SUPER') {
		// @ts-ignore
		menu.push(analitic)
		// @ts-ignore
		menu.push(reports)
	}

	if(["CRM_ADMIN", 'CRM_READONLY', 'CASHIER', 'CRM_SUPER'].includes(user?.user_type!)){
				// @ts-ignore
		menu.push(monitoring)
		// @ts-ignore`
		menu.push(asia)
	}

	
	return (
		<Layout className={styles.wrapper}>
			<Sider
				className={styles.sidebar}
				trigger={null}
				collapsible
				collapsed={true}
				theme='light'
			>
				<div className={styles.main_logo}>
					<img src={MkassaLogo} alt='' />
				</div>
				<div className={styles.menu}>
					<Menu
						className={styles.menuItem}
						theme='light'
						mode='inline'
						defaultSelectedKeys={['1']}
						items={menu}
						onClick={e => navigate(e.key)}
					/>
				</div>
			</Sider>
			<Content
				style={{ marginLeft: 100, height: '100vh' }}
				className={styles.content}
			>
				<AppHeader />
				<Outlet />
			</Content>
		</Layout>
	)
}

export default AdminLayout
