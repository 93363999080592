import { UserIcon } from 'components/icon'
import { ArrowDownFillSmall } from 'components/icon'
import { LogOutIcon } from 'components/icon'
import styles from './user-info.module.scss'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'
import { deleteStorage } from 'services/storage'
import { MerchantsDetail } from 'services/api/MerchantsApi.models'
import {
	deleteStorageCashierUser,
	deleteStorageMerchantUser,
} from 'services/storage/MerchantUserDetail'
import { MerchantRoutes } from 'pages/MerchantDashboard/routes/routes-merchant'
import { User } from 'models/user.models'
import { store } from 'store'

interface Props {
	data: User
}

const UserInfo = observer(({ data }: Props) => {
	const navigate = useNavigate()

	const {user, userMerchant} = store

	const onLogOutUser = () => {
		navigate(MerchantRoutes.login)
		deleteStorage()
		deleteStorageMerchantUser()
		deleteStorageCashierUser()
		user.destroy()
		userMerchant.destroy()
	}

	const ACTIONS = [
		{
			name: 'Выйти',
			onClick: onLogOutUser,
			icon: <LogOutIcon />,
		},
	]
	// @ts-ignore
	const initialsUser = `(${data?.name || data?.company_name}) - ${
		// @ts-ignore
		data?.owner?.first_name || data?.owner || ''
		// @ts-ignore
	} ${data?.owner?.last_name?.[0] || ''}`
	return (
		<div className={styles.container}>
			<div className={styles.icon}>
				<UserIcon />
			</div>

			<div className={styles.user}>
				{initialsUser}
				<span className={styles.arrow}>
					<ArrowDownFillSmall />
				</span>
			</div>

			<div className={styles.addActions}>
				<ul className={styles.action}>
					{ACTIONS.map((item, index) => (
						<li key={index} onClick={item.onClick}>
							<span>{item.icon}</span>

							{item.name}
						</li>
					))}
				</ul>
			</div>
		</div>
	)
})

export default UserInfo
