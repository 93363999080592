export function formatTyinsToSoms(tyins: number | null | undefined): string {
    if (tyins === null || tyins === undefined) {
        return '-'
    }
    const soms = tyins / 100

    const formattedSoms = soms.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

    return `${formattedSoms} сом`
}
