import React from "react";

const LogOutIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1970_23381)">
        <path
          d="M4.66667 14.6667H3.33333C2.8029 14.6667 2.29419 14.456 1.91912 14.0809C1.54405 13.7058 1.33333 13.1971 1.33333 12.6667V3.33333C1.33333 2.8029 1.54405 2.29419 1.91912 1.91912C2.29419 1.54405 2.8029 1.33333 3.33333 1.33333H4.66667C4.84348 1.33333 5.01305 1.2631 5.13807 1.13807C5.2631 1.01305 5.33333 0.843478 5.33333 0.666667C5.33333 0.489856 5.2631 0.320286 5.13807 0.195262C5.01305 0.0702379 4.84348 0 4.66667 0L3.33333 0C2.4496 0.00105857 1.60237 0.352588 0.97748 0.97748C0.352588 1.60237 0.00105857 2.4496 0 3.33333L0 12.6667C0.00105857 13.5504 0.352588 14.3976 0.97748 15.0225C1.60237 15.6474 2.4496 15.9989 3.33333 16H4.66667C4.84348 16 5.01305 15.9298 5.13807 15.8047C5.2631 15.6797 5.33333 15.5101 5.33333 15.3333C5.33333 15.1565 5.2631 14.987 5.13807 14.8619C5.01305 14.7369 4.84348 14.6667 4.66667 14.6667Z"
          fill="#EC1212"
        />
        <path
          d="M6.97466 3.52872L3.91666 6.58605C3.54285 6.96171 3.33301 7.4701 3.33301 8.00005C3.33301 8.53001 3.54285 9.0384 3.91666 9.41405L6.97399 12.4714C7.03549 12.5351 7.10905 12.5858 7.19039 12.6208C7.27172 12.6557 7.3592 12.6741 7.44772 12.6749C7.53624 12.6757 7.62403 12.6588 7.70596 12.6253C7.78789 12.5917 7.86232 12.5422 7.92492 12.4797C7.98751 12.4171 8.03702 12.3426 8.07054 12.2607C8.10406 12.1788 8.12093 12.091 8.12016 12.0025C8.11939 11.9139 8.101 11.8265 8.06606 11.7451C8.03112 11.6638 7.98033 11.5902 7.91666 11.5287L5.05599 8.66672H15.3333C15.5101 8.66672 15.6797 8.59648 15.8047 8.47146C15.9298 8.34643 16 8.17687 16 8.00005C16 7.82324 15.9298 7.65367 15.8047 7.52865C15.6797 7.40363 15.5101 7.33339 15.3333 7.33339H5.05532L7.91732 4.47139C7.981 4.40989 8.03178 4.33633 8.06672 4.25499C8.10166 4.17365 8.12005 4.08617 8.12082 3.99766C8.12159 3.90914 8.10472 3.82135 8.0712 3.73942C8.03768 3.65749 7.98818 3.58305 7.92559 3.52046C7.86299 3.45786 7.78856 3.40836 7.70663 3.37484C7.62469 3.34132 7.53691 3.32445 7.44839 3.32522C7.35987 3.32599 7.27239 3.34438 7.19105 3.37932C7.10972 3.41426 7.03615 3.46505 6.97466 3.52872Z"
          fill="#EC1212"
        />
      </g>
      <defs>
        <clipPath id="clip0_1970_23381">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogOutIcon;
