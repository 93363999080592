import { SelectProps } from "antd";
import { DefaultOptionType } from "antd/es/select";
import BranchSelect from "common/branch/BranchSelect";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { setBranch } from "store/rtk/slices/filters/transactions-filter.slice";

const FilterByBranch = (props: SelectProps) => {
  const dispatch = useAppDispatch();

  const branch = useAppSelector((store) => store.transactionsFilters.branch);

  const handleChange = (_: string, option: DefaultOptionType) => {
    dispatch(setBranch(option || null));
  };

  return (
    <BranchSelect
      allowClear
      onChange={handleChange}
      defaultValue={branch}
      {...props}
    />
  );
};

export default FilterByBranch;
