import { observer } from "mobx-react-lite";
import React from "react";
import { store } from "store";

import styles from "./AppModal.module.scss";
import clsx from "clsx";

const AppModal = observer(() => {
  const { modalStore } = store;

  const onCloseModal = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    modalStore.closeModal();
  };

  if (!modalStore.visible) {
    return null;
  }

  return (
    <div className={clsx(styles.wrapper, modalStore.className)} onClick={onCloseModal}>
      <div className={styles.content} onClick={(e) => e.stopPropagation()}>
        {modalStore.body}
      </div>
    </div>
  );
});

export default AppModal;
