import { Empty, Spin } from 'antd'
import clsx from 'clsx'
import AppQr from 'components/AppQr'
import MbankIconFull from 'components/icon/MbankIconFull'
import dayjs from 'dayjs'
import html2canvas from 'html2canvas'
import { TransactionsApi } from 'services/api/TransactionsApi'
import { TransactionCheck } from 'services/api/TransactionsApi.module'
import { API_DATE_FORMAT_CHECK } from 'utils'

import { ForwardedRef, forwardRef, useEffect, useRef, useState } from 'react'

import styles from './styles.module.scss'

interface ICheckComponent {
    transactionItem: TransactionCheck
}

const CheckComponent = forwardRef(
    (
        { transactionItem }: ICheckComponent,
        ref: ForwardedRef<HTMLDivElement>,
    ) => {
        const ndsNspValue = (nds: number, nsp: number, taxSystem: string) => {
            if (nds === 0 && nsp === 0 && taxSystem !== 'Общий налоговый режим')
                return 'Без НДС, без НСП'
            else if (nds !== 0 || nsp || 0) {
                return `НДС ${nds}, НСП ${nsp}`
            }
            return null
        }

        const ndsValue = (taxSum: any, taxSystem: string) => {
            if (taxSum.rate === 0 && taxSystem !== 'Общий налоговый режим') {
                return 'Без НДС'
            } else {
                return taxSum.rate
            }
        }

        const nspValue = (taxSum: any, taxSystem: string) => {
            if (taxSum.rate === 0 && taxSystem !== 'Общий налоговый режим') {
                return 'Без НСП'
            } else {
                return taxSum.rate
            }
        }

        return (
            <div className={styles.test}>
                <div ref={ref} className={styles.wrapper}>
                    <div className={styles.header}>
                        <MbankIconFull />
                        <div>
                            <div className={styles.headerCheck}>
                                Контрольно - кассовый чек
                            </div>
                            <div className={styles.headerType}>
                                {transactionItem.receipt.fdName}
                            </div>
                        </div>
                    </div>
                    <div className={styles.check}>
                        <div
                            className={clsx(
                                styles.companyName,
                                styles.valueInfo,
                            )}
                        >
                            {transactionItem.company_name}
                        </div>
                        <div>ИНН {transactionItem.receipt.tin}</div>
                        <div className={styles.block}>
                            <div className={styles.label}>Дата расчета:</div>
                            <div className={styles.value}>
                                {dayjs(transactionItem.created_at).format(
                                    API_DATE_FORMAT_CHECK,
                                )}
                            </div>
                        </div>
                        <div className={styles.block}>
                            <div className={styles.label}>Чек:</div>
                            <div className={styles.value}>
                                {transactionItem.receipt.checkNumber}
                            </div>
                        </div>
                        <div className={styles.block}>
                            <div className={styles.label}>Cмена:</div>
                            <div className={styles.value}>
                                {transactionItem.receipt.shiftNumber}
                            </div>
                        </div>
                        <div className={clsx(styles.goods, styles.underline)}>
                            {transactionItem.receipt.goods.map(goodsItem => (
                                <div className={styles.goodsItem}>
                                    <div className={styles.goodTitle}>
                                        {goodsItem.name}
                                    </div>
                                    <div className={styles.goodsItemInfo}>
                                        <div>{goodsItem.initial_price}</div>
                                        <div>
                                            {goodsItem.quantity}
                                            {goodsItem.unit}
                                        </div>
                                        <div>
                                            {Number(goodsItem.initial_cost) *
                                                goodsItem.quantity}
                                        </div>
                                    </div>
                                    <div>
                                        {ndsNspValue(
                                            goodsItem.vat,
                                            goodsItem.st,
                                            transactionItem.receipt.taxSystem,
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={clsx(styles.total, styles.underline)}>
                            <div className={styles.label}>ИТОГ сом</div>
                            <div className={styles.value}>
                                {transactionItem.receipt.totalSum}
                            </div>
                        </div>
                        <div className={styles.block}>
                            <div className={styles.label}>
                                Сумма без налогов
                            </div>
                            <div className={styles.value}>
                                {transactionItem.receipt.totalTaxlessSum}
                            </div>
                        </div>
                        {transactionItem.receipt.isCashless ? (
                            <div className={styles.block}>
                                <div className={styles.label}>Безналичным</div>
                                <div className={styles.value}>
                                    {transactionItem.receipt.totalCashlessSum || 0}
                                </div>
                            </div>
                        ) : (
                            <div className={styles.block}>
                                <div className={styles.label}>Наличным</div>
                                <div className={styles.value}>
                                    {transactionItem.receipt.paidSum || 0}
                                </div>
                            </div>
                        )}

                        {transactionItem.receipt.isCashless === false && (
                            <div className={styles.block}>
                                <div className={styles.label}>Сдача</div>
                                <div className={styles.value}>
                                    {transactionItem.receipt.exchange || 0}
                                </div>
                            </div>
                        )}
                        {transactionItem.receipt.isCashless === false && (
                            <div className={styles.block}>
                                <div className={styles.label}>
                                    Способ расчета
                                </div>
                                <div className={styles.value}>
                                    {transactionItem.receipt.paymentType}
                                </div>
                            </div>
                        )}
                        <div className={styles.block}>
                            <div className={styles.label}>Сумма скидки</div>
                            <div className={styles.value}>
                                {transactionItem.receipt.discountSum}
                            </div>
                        </div>
                        <div className={styles.block}>
                            <div className={styles.label}>НДС</div>
                            <div className={styles.value}>
                                {ndsValue(
                                    transactionItem.receipt.taxSums.find(
                                        el => el.type === 'VAT',
                                    ),
                                    transactionItem.receipt.taxSystem,
                                )}
                            </div>
                        </div>
                        <div className={clsx(styles.block, styles.underline)}>
                            <div className={styles.label}>НСП</div>
                            <div className={styles.value}>
                                {nspValue(
                                    transactionItem.receipt.taxSums.find(
                                        el => el.type === 'ST',
                                    ),
                                    transactionItem.receipt.taxSystem,
                                )}
                            </div>
                        </div>
                        <div className={styles.block}>
                            <div className={styles.label}>СНО:</div>
                            <div className={styles.value}>
                                {transactionItem.receipt.taxSystem}
                            </div>
                        </div>
                        <div className={styles.block}>
                            <div className={styles.label}>
                                Наименование объекта:
                            </div>
                            <div className={styles.value}>
                                {transactionItem.receipt.address}
                            </div>
                        </div>
                        <div className={styles.block}>
                            <div className={styles.label}>Адрес расчета:</div>
                            <div className={styles.value}>
                                {transactionItem.receipt.storeName}
                            </div>
                        </div>
                        <div className={styles.block}>
                            <div className={styles.label}>ККМ версия:</div>
                            <div className={styles.value}>
                                {transactionItem.receipt.crModelVersion}
                            </div>
                        </div>
                        <div className={clsx(styles.block, styles.underline)}>
                            <div className={styles.label}>Кассир:</div>
                            <div className={styles.value}>
                                {transactionItem.receipt.cashierName}
                            </div>
                        </div>
                        <div className={styles.block}>
                            <div className={styles.label}>РН ККМ:</div>
                            <div className={styles.value}>
                                {transactionItem.receipt.registrationNumber}
                            </div>
                        </div>
                        <div className={styles.block}>
                            <div className={styles.label}>ФМ:</div>
                            <div className={styles.value}>
                                {transactionItem.receipt.fnNumber}
                            </div>
                        </div>
                        <div className={styles.block}>
                            <div className={styles.label}>ФД:</div>
                            <div className={styles.value}>
                                {transactionItem.receipt.fdNumber}
                            </div>
                        </div>
                        <div className={styles.block}>
                            <div className={styles.label}>ФПД:</div>
                            <div className={styles.value}>
                                {transactionItem.receipt.fiscalAttribute}
                            </div>
                        </div>
                        <div className={styles.qr}>
                            <AppQr
                                url={transactionItem.receipt.qr_link}
                                options={{
                                    width: 150,
                                    height: 150,
                                    dotsOptions: {
                                        color: '#000000',
                                        type: 'square',
                                    },
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    },
)

interface IProps {
    transactionId: string
}

export default function ModalCheck({ transactionId }: IProps) {
    const [imageSrc, setImageSrc] = useState<string | undefined>(undefined)

    const [transactionCheckData, setTransactionCheckData] =
        useState<TransactionCheck | null>(null)
    const [isTransactionDataLoading, setIsTransactionDataLoading] =
        useState(true)
    const elementRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        setIsTransactionDataLoading(true)
        TransactionsApi.getTransactionCheckData(transactionId)
            .then(({ data }) => {
                setTransactionCheckData(data)
            })
            .finally(() => {
                setIsTransactionDataLoading(false)
            })
    }, [])

    useEffect(() => {
        const htmlToImageConvert = () => {
            if (!elementRef.current || !transactionCheckData) {
                return
            }
            html2canvas(elementRef.current, {
                width: elementRef.current.offsetWidth,
                height: elementRef.current.offsetHeight,
                scale: 1,
            }).then(function (canvas) {
                setImageSrc(canvas.toDataURL())
            })
        }
        setTimeout(htmlToImageConvert, 500)
    }, [transactionCheckData, elementRef.current])

    if (isTransactionDataLoading) return <Spin className={styles.spin} />

    if (!transactionCheckData) return <Empty description="Нет данных" />

    return (
        <div>
            <img src={imageSrc} alt="" />
            <CheckComponent
                ref={elementRef}
                transactionItem={transactionCheckData}
            />
        </div>
    )
}
