import AppInput from 'components/AppInput'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useDebouncedCallback } from 'use-debounce'

interface Props {
	queryKey: string
	placeholder?: string
	icon: React.ReactNode
}

const AppTextFiledQuery = ({ queryKey, placeholder, icon }: Props) => {
	const [searchParams, setSearchParams] = useSearchParams()
	const searchBranch = searchParams.get('searchBranch')

	const [value, setValue] = useState<string>(searchBranch || '')

	const onChange = (_value: string) => {
		// @ts-ignore
		setValue(_value.target.value)

		// @ts-ignore
		debounced(_value.target.value)
	}

	const resetCurrentPage = () => {
        if (searchParams.get('currentPage')) {
            searchParams.set('currentPage', '1')
        }
    }

	const debounced = useDebouncedCallback(
		// function
		value => {
			resetCurrentPage()
			searchParams.set(queryKey, value)

			setSearchParams(searchParams, {replace: true})
		},
		// delay in ms
		1000
	)

	return (
		<div>
			{/* @ts-ignore */}
			<AppInput
				icon={icon}
				placeholder={placeholder}
				// @ts-ignore
				onChange={onChange}
				value={value}
			/>
		</div>
	)
}

export default AppTextFiledQuery
