import { useNavigate, useParams } from 'react-router'
import { useResolvedPath } from 'react-router-dom'

import { Col, Row } from 'antd'
import { Controller, useForm, useWatch } from 'react-hook-form'

import { AppButton, AppInput, AppInputPhone, SelectField } from 'components'

import { KG_PHONE_MAX_LENGTH, LENGTH_INN } from 'constants/NumberLength'
import { SIZE_INPUT } from 'constants/ComponentSize'

import { CashierApi } from 'services/api/CashierApi'

import styles from './create.module.scss'
import { DevicesType, EDevices } from 'models/common.model'

interface FormProps {
	name: string
	phone: string
	inn: string
	is_mpos: boolean
	mpos_serial_number: string | null
	mpos_j: string
	mpos_type: DevicesType
}

const MposOptions = [
	{ id: true, name: 'SmartPOS' },
	{ id: false, name: 'Mobile app' },
]

const devicesOptions = [
	{ id: EDevices.MOBILE_APP, name: 'Mobile app' },
	{ id: EDevices.SMARTPOS_MBANK, name: 'SmartPOS MBank' },
	{ id: EDevices.SMARTPOS_PAYMOB, name: 'SmartPOS Paymob' },
]

const Create = () => {
	const navigate = useNavigate()
	const { branchId } = useParams()
	const pathname = useResolvedPath('../').pathname

	const {
		control,
		register,
		setValue,
		handleSubmit,
		setError,
		clearErrors,
		formState: { errors },
	} = useForm<FormProps>({
		defaultValues: { mpos_type: 'MOBILE_APP', is_mpos: false },
	})

	const mpos_type = useWatch({ control, name: 'mpos_type' })

	const isMobApp = mpos_type === 'MOBILE_APP'

	const onBackNav = () => {
		navigate(pathname)
	}

	const onSubmit = async (values: FormProps) => {
		if (!values.mpos_serial_number) {
			values.mpos_serial_number = null
		}
		try {
			branchId &&
				(await CashierApi.createCashier({ ...values, branch_id: +branchId }))
			onBackNav()
		} catch (e: any) {
			const err = e.response.data.errors
			if (err) {
				Object.keys(err).forEach((key: string) => {
					// @ts-ignore
					setError(key, { message: err[key] })
				})
			}
		}
	}

	return (
		<div className={styles.wrapper}>
			<form
				onSubmit={e => {
					clearErrors()
					handleSubmit(onSubmit)(e)
				}}
				className={styles.form}
			>
				<h3 className={styles.title}>Создание кассира</h3>

				<Row gutter={[16, 16]}>
					<Col span={8}>
						<div className={styles.formItem}>
							<AppInput label='Ф.И.О. кассира' {...register('name')} />
						</div>

						<div className={styles.formItem}>
							<Controller
								control={control}
								name='inn'
								rules={{
									required: 'Введите ИНН',
									minLength: {
										value: LENGTH_INN,
										message: 'Введите валидный ИНН',
									},
									maxLength: {
										value: LENGTH_INN,
										message: 'Введите валидный ИНН',
									},
								}}
								render={({ field: { value } }) => {
									return (
										<AppInput
											label='ИНН'
											type='tel'
											maxLength={LENGTH_INN}
											placeholder='Введите ИНН'
											value={value}
											error={errors?.inn}
											{...register('inn')}
										/>
									)
								}}
							/>
						</div>

						<div className={styles.formItem}>
							<Controller
								control={control}
								name='phone'
								rules={{
									required: 'Введите номер телефона',
									minLength: {
										value: KG_PHONE_MAX_LENGTH,
										message: 'Введите валидный номер',
									},
								}}
								render={({ field: { value } }) => {
									return (
										<AppInputPhone
											label='Номер телефона'
											placeholder='Введите номер телефона'
											value={value}
											onChangePhone={value => setValue('phone', value.phone)}
											error={errors?.phone}
										/>
									)
								}}
							/>
						</div>
					</Col>

					<Col span={8}>
						<div className={styles.formItem}>
							<Controller
								control={control}
								name='mpos_type'
								rules={
									{
										// required: "Выберите устройство",
									}
								}
								render={() => {
									return (
										<SelectField
											label='Тип устройства'
											sizeInput={SIZE_INPUT.SMALL}
											options={devicesOptions}
											value={mpos_type}
											onChange={val => setValue('mpos_type', val.value)}
											error={errors?.mpos_type}
										/>
									)
								}}
							/>
						</div>

						<div className={styles.formItem}>
							<AppInput
								label='Серийный номер'
								{...register('mpos_serial_number', {
									required: !isMobApp ? 'Обязательное поле' : false,
								})}
								error={errors?.mpos_serial_number}
							/>
						</div>

						<div className={styles.formItem}>
							<AppInput
								label='J номер'
								{...register('mpos_j', {
									required: !isMobApp ? 'Обязательное поле' : false,
								})}
								error={errors?.mpos_j}
							/>
						</div>
					</Col>
				</Row>

				<div className={styles.buttons}>
					<AppButton variant='lightBlue' onClick={onBackNav}>
						Отменить
					</AppButton>

					<AppButton type='submit'>Сохранить</AppButton>
				</div>
			</form>
		</div>
	)
}

export default Create
