import { useNavigate, useParams } from "react-router";
import { useResolvedPath } from "react-router-dom";

import { Col, Row, notification } from "antd";
import { Controller, useForm, useWatch } from "react-hook-form";

import { AppButton, AppInput, AppInputPhone, SelectField } from "components";

import { KG_PHONE_MAX_LENGTH, LENGTH_INN } from "constants/NumberLength";
import { SIZE_INPUT } from "constants/ComponentSize";

import { CashierApi } from "services/api/CashierApi";
import { BranchesApi } from "services/api/BranchesApi";

import styles from "./create.module.scss";
import { DevicesType, EDevices } from "models/common.model";
import { CreatePartnersMachinesData } from "services/api/CashierApi.models";

const devicesOptions = [
  { id: EDevices.MOBILE_APP, name: "Mobile app" },
  { id: EDevices.SMARTPOS_MBANK, name: "SmartPOS MBabk" },
  { id: EDevices.SMARTPOS_PAYMOB, name: "SmartPOS Paymob" },
];
const CreateCashier = () => {
  const navigate = useNavigate();
  const { merchantId } = useParams();
  const pathname = useResolvedPath("../").pathname;

  const {
    control,
    register,
    setValue,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<CreatePartnersMachinesData>({
    defaultValues: { mpos_type: EDevices.MACHINE },
  });

  const mpos_type = useWatch({ control, name: "mpos_type" });

  const isMobApp = mpos_type === "MOBILE_APP";

  const branchId = useWatch({ control, name: "branch_id" });

  const onBackNav = () => {
    navigate(pathname);
  };

  const getBranches = (
    query: string,
    params: { offset: number; limit: number }
  ) => {
    return BranchesApi.getListBranches({
      query,
      company: merchantId,
      ...params,
    });
  };

  const onSubmit = async (values: CreatePartnersMachinesData) => {

    try {
      await CashierApi.createPartnerMachines(values);
      notification.info({
        message: `Успешно`,
        description: "КСО успешно создано",
      });
      onBackNav();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={styles.wrapper}>
      <form
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
        className={styles.form}
      >
        <h3 className={styles.title}>Создание КСО</h3>

        <div className={styles.formFields}>
          <div className={styles.formItem}>
            <AppInput label="Ф.И.О. кассира" {...register("name")} />
          </div>
          <div className={styles.formItem}>
            <Controller
              control={control}
              name="branch_id"
              rules={{
                required: "Выберите торговую точку",
              }}
              render={() => {
                return (
                  <SelectField
                    label={"Торговая точка"}
                    value={branchId}
                    onChange={(val) => setValue("branch_id", val)}
                    // @ts-ignore
                    fetchOptions={getBranches}
                    className={styles.selectField}
                    optionLabelKey={"name"}
                    sizeInput={SIZE_INPUT.SMALL}
                    error={errors.branch_id}
                  />
                );
              }}
            />
          </div>
        </div>

        <div className={styles.buttons}>
          <AppButton variant="lightBlue" onClick={onBackNav}>
            Отменить
          </AppButton>

          <AppButton type="submit">Сохранить</AppButton>
        </div>
      </form>
    </div>
  );
};

export default CreateCashier;
