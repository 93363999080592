import { PALETTE } from "styles/palette";


interface CashoutStatus {
  [key: string]: {
    label: string;
    color: keyof typeof PALETTE;
  };
}

export const CASHOUT_STATUSES: CashoutStatus = {
  COMMITTED: {
    label: "Успешно ",
    color: "$green",
  },
  CANCELED_BY_TIMER: {
    label: "Отменен по таймеру",
    color: "$black",
  },
  UNPAID: {
    label: "Не оплачен",
    color: "$dark_blue",
  },
  ROLLBACKED: {
    label: "Возвращено ",
    color: "$gray_5",
  },
  PROCESSING: {
    label: "В обработке",
    color: "$gray_blue",
  },
  QR_SCANNED: {
    label: "QR отсканирован",
    color: "$gray_blue_2",
  },
  CONFIRM: {
    label: "Подтвержден",
    color: "$gray_blue_2",
  },
  FAILED: {
    label: "Ошибка",
    color: "$red",
  },
  IN_PROGRESS: {
    label: "В процессе",
    color: "$orange",
  },
  UNKNOWN: {
    label: "Неизвестно",
    color: "$orange_2",
  },
  FOR_ROLLBACK: {
    label: "Идет на возврат",
    color: "$dark_blue",
  },
};
