import React from 'react';

interface Props {
    className?: string;
}

const DownloadIcon: React.FC<Props> = ({className}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none"
             className={className}>
            <g clipPath="url(#clip0_2709_15017)">
                <path
                    d="M15.8333 10.6665C15.6565 10.6665 15.487 10.7367 15.3619 10.8618C15.2369 10.9868 15.1667 11.1564 15.1667 11.3332V13.9998C15.1667 14.1766 15.0964 14.3462 14.9714 14.4712C14.8464 14.5963 14.6768 14.6665 14.5 14.6665H2.5C2.32319 14.6665 2.15362 14.5963 2.0286 14.4712C1.90357 14.3462 1.83333 14.1766 1.83333 13.9998V11.3332C1.83333 11.1564 1.7631 10.9868 1.63807 10.8618C1.51305 10.7367 1.34348 10.6665 1.16667 10.6665C0.989856 10.6665 0.820286 10.7367 0.695262 10.8618C0.570238 10.9868 0.5 11.1564 0.5 11.3332L0.5 13.9998C0.5 14.5303 0.710714 15.039 1.08579 15.4141C1.46086 15.7891 1.96957 15.9998 2.5 15.9998H14.5C15.0304 15.9998 15.5391 15.7891 15.9142 15.4141C16.2893 15.039 16.5 14.5303 16.5 13.9998V11.3332C16.5 11.1564 16.4298 10.9868 16.3047 10.8618C16.1797 10.7367 16.0101 10.6665 15.8333 10.6665Z"
                    fill="white" />
                <path
                    d="M4.02866 9.02533L7.08599 12.0833C7.46165 12.4571 7.97004 12.667 8.49999 12.667C9.02994 12.667 9.53833 12.4571 9.91399 12.0833L12.9713 9.026C13.035 8.9645 13.0858 8.89094 13.1207 8.8096C13.1557 8.72827 13.1741 8.64079 13.1748 8.55227C13.1756 8.46375 13.1587 8.37596 13.1252 8.29403C13.0917 8.2121 13.0422 8.13766 12.9796 8.07507C12.917 8.01247 12.8426 7.96297 12.7606 7.92945C12.6787 7.89593 12.5909 7.87906 12.5024 7.87983C12.4139 7.8806 12.3264 7.89899 12.2451 7.93393C12.1637 7.96887 12.0902 8.01966 12.0287 8.08333L9.16666 10.944V0.666667C9.16666 0.489856 9.09642 0.320286 8.9714 0.195262C8.84637 0.0702379 8.6768 0 8.49999 0C8.32318 0 8.15361 0.0702379 8.02859 0.195262C7.90356 0.320286 7.83333 0.489856 7.83333 0.666667V10.9447L4.97133 8.08267C4.90983 8.01899 4.83627 7.9682 4.75493 7.93327C4.67359 7.89833 4.58611 7.87994 4.49759 7.87917C4.40907 7.8784 4.32129 7.89526 4.23936 7.92878C4.15743 7.96231 4.08299 8.01181 4.0204 8.0744C3.9578 8.137 3.9083 8.21143 3.87478 8.29336C3.84126 8.37529 3.82439 8.46308 3.82516 8.5516C3.82593 8.64012 3.84432 8.7276 3.87926 8.80894C3.9142 8.89027 3.96499 8.96383 4.02866 9.02533Z"
                    fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_2709_15017">
                    <rect width="16" height="16" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default React.memo(DownloadIcon);