import { clientApi } from './clientApi'
import { Pagination } from 'models/pagination.models'
import {
	ITransactionDetail,
	ITransactionDetailBranch,
	ITransactionStatus,
	TransactionCheck,
	TransactionDetailList,
	TransactionList,
} from './TransactionsApi.module'
import { QueryParamsModel } from 'models/QueryParams.model'

interface Props extends QueryParamsModel {
	company?: string
	branches?: string
	cashier?: string
	created_at__lte?: string
	created_at__gte?: string
	transaction_type?: string
	status?: string
}

export const TransactionsApi = {
	getTransactions(params: object) {
		return clientApi.get<TransactionList>(`crm/crm_transactions/`, { params })
	},
	getTransactionCheckData(transactionId: string) {
		return clientApi.get<TransactionCheck>(`crm/crm_transactions/${transactionId}/`)
	},
	getTransactionStatus({ params, id }: { id: number | string; params: any }) {
		return clientApi.get<ITransactionStatus>(
			`crm/crm_transactions/${id}/status/`,
			{
				params,
			}
		)
	},
	getTransactionDetail({ params }: { params?: QueryParamsModel }) {
		return clientApi.get<ITransactionDetail>(`transactions-detail/`, {
			params,
		})
	},
	getTransactionDetailBranches(params: QueryParamsModel) {
		return clientApi.get<ITransactionDetailBranch>(
			`transactions-detail/branches/`,
			{
				params,
			}
		)
	},
	getBranchTransactionDetail(params: QueryParamsModel) {
		return clientApi.get<TransactionDetailList[]>(
			`transactions-detail/branches/transactions/`,
			{
				params,
			}
		)
	},
}
