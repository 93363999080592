import { AppBreadСrumbs } from 'components'
import styles from './merchant-header.module.scss'
import UserInfo from './components/UserInfo'
import MERCHANT_DASHBOARD_BREADCRUBMS from 'pages/MerchantDashboard/merchBreadCrumbs'
import { store } from 'store'
import Notifications from 'components/Notifications'

const MerchantHeader = () => {
	const { user } = store

	const userData = user.getUser()

	if (!userData) return null

	return (
		<div className={styles.wrapper}>
			<div className={styles.bread}>
				<AppBreadСrumbs links={[]} routes={MERCHANT_DASHBOARD_BREADCRUBMS} />
			</div>

			<div className={styles.userInfo}>
				<Notifications />
				<UserInfo data={userData} />
			</div>
		</div>
	)
}

export default MerchantHeader
