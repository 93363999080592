const EyeOpenIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9974 11.9987C14.9974 13.5899 13.653 14.8798 11.9946 14.8798C10.3362 14.8798 8.99177 13.5899 8.99177 11.9987C8.99177 10.4076 10.3362 9.11771 11.9946 9.11771C13.653 9.11769 14.9974 10.4076 14.9974 11.9987ZM12 6C10.2829 6.00763 8.5031 6.42625 6.81827 7.22773C5.5673 7.84732 4.34817 8.72149 3.2899 9.80046C2.77013 10.3512 2.10718 11.1488 2 11.9997C2.01267 12.7368 2.80217 13.6465 3.2899 14.1989C4.28228 15.2355 5.46967 16.0853 6.81827 16.7723C8.38945 17.5359 10.1285 17.9755 12 18C13.7187 17.9923 15.4981 17.5688 17.1811 16.7723C18.4321 16.1527 19.6518 15.2779 20.7101 14.1989C21.2299 13.6481 21.8928 12.8506 22 11.9997C21.9873 11.2626 21.1978 10.3528 20.7101 9.80043C19.7177 8.76383 18.5297 7.91472 17.1811 7.22769C15.6107 6.46466 13.8674 6.02802 12 6ZM11.9987 7.48962C14.6007 7.48962 16.71 9.50911 16.71 12.0003C16.71 14.4915 14.6007 16.511 11.9987 16.511C9.39675 16.511 7.28748 14.4915 7.28748 12.0003C7.28748 9.50911 9.39675 7.48962 11.9987 7.48962Z"
        fill="#B5C7D9"
      />
    </svg>
  );
};

export default EyeOpenIcon;
