import React from 'react'

import { UserIcon } from 'components/icon'
import { ArrowDownFillSmall } from 'components/icon'
import { LogOutIcon } from 'components/icon'
import { store } from 'store'
import styles from './UserInfo.module.scss'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'
import { RouterConstants } from 'constants/routerConstants'
import { deleteStorage } from 'services/storage'

const UserInfo = observer(() => {
	const { user } = store
	const userData = user.user
	const navigate = useNavigate()

	const onLogOutUser = () => {
		navigate(RouterConstants.AUTH)
		deleteStorage()
	}

	const ACTIONS = [
		{
			name: 'Выйти',
			onClick: onLogOutUser,
			icon: <LogOutIcon />,
		},
	]

	const initialsUser = `${userData?.last_name || ''} ${
		userData?.first_name?.[0] || ''
	} ${userData?.last_name?.[0] || ''}`
	return (
		<div className={styles.container}>
			<div className={styles.icon}>
				<UserIcon />
			</div>

			<div className={styles.user}>
				{initialsUser}
				<span className={styles.arrow}>
					<ArrowDownFillSmall />
				</span>
			</div>

			<div className={styles.addActions}>
				<ul className={styles.action}>
					{ACTIONS.map((item, index) => (
						<li key={index} onClick={item.onClick}>
							<span>{item.icon}</span>

							{item.name}
						</li>
					))}
				</ul>
			</div>
		</div>
	)
})

export default UserInfo
