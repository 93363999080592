import { makeAutoObservable } from "mobx";

export interface ISearchParams {
  [key: string]: {
    [key: string]: any;
  };
}

class SearchParams {
  searchParams: ISearchParams = {};

  constructor() {
    makeAutoObservable(this);
  }

  private initializeSearchParams(key: string) {
    if (!this.searchParams[key]) {
      this.searchParams[key] = {};
    }
  }

  setSearchParams(parentKey: string, childKey: string, value: any) {
    this.initializeSearchParams(parentKey);
    this.searchParams[parentKey][childKey] = value;
  }
}

export default SearchParams;
