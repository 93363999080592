const SuccessIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M27.0835 4.89575C21.0002 -1.18758 11.0002 -1.18758 4.91683 4.89575C-1.1665 10.9791 -1.1665 20.9791 4.91683 27.0624C8.00017 30.1458 12.0002 31.6458 16.0002 31.6458C20.0002 31.6458 24.0002 30.1458 27.0835 27.0624C33.1668 20.9791 33.1668 10.9791 27.0835 4.89575ZM23.0305 12.7866C23.4631 12.354 23.387 11.6258 22.9203 11.2907C22.4535 10.9557 21.8937 11.0499 21.5165 11.4888L14.9198 18.9507L11.2429 15.2738C10.8103 14.8413 10.1615 14.8413 9.72891 15.2738C9.29634 15.7064 9.29634 16.3553 9.72891 16.7878L13.6221 20.681C13.6932 20.7521 13.7643 20.8291 13.8364 20.9071C14.2027 21.3036 14.5939 21.7269 15.1361 21.5461C15.4433 21.4693 15.6415 21.2288 15.8467 20.9797C15.9305 20.8781 16.0154 20.775 16.1094 20.681C16.9745 19.7077 22.598 13.3273 23.0305 12.7866Z"
        fill="#389E0D"
      />
    </svg>
  );
};

export default SuccessIcon;
