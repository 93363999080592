export enum TypeTransactionEnum {
	QR = 'qr',
	_QR = 'QR',
	CARD = 'card',
	_CARD = 'CARD',
	OTP = 'otp',
	Cash = 'cash',
	Static = 'static',
}

export const TYPE_TRANSACTION = {
	[TypeTransactionEnum.QR]: 'Оплата по QR',
	[TypeTransactionEnum._QR]: 'Оплата по QR',
	[TypeTransactionEnum.CARD]: 'Банковская карта',
	[TypeTransactionEnum._CARD]: 'Банковская карта',
	[TypeTransactionEnum.OTP]: 'Оплата по ОТП',
	[TypeTransactionEnum.Static]: 'Статичный',
	[TypeTransactionEnum.Cash]: 'Наличными',
}

export const TYPE_TRANSACTION_SELECT = {
	[TypeTransactionEnum.QR]: 'Оплата по QR',
	[TypeTransactionEnum.CARD]: 'Банковская карта',
	[TypeTransactionEnum.OTP]: 'Оплата по ОТП',
	[TypeTransactionEnum.Static]: 'Статичный',
	[TypeTransactionEnum.Cash]: 'Наличными',
}
