import { deleteStorageCashierUser } from './MerchantUserDetail'
import { MKASSA_ARM_TOKEN } from './constants'
import {
	setLocalStorage,
	getLocalStorage,
	deleteLocalStorage,
} from './localStorage'
import {
	setSessionStorage,
	getSessionStorage,
	deleteSessionStorage,
} from './sessionStorage'

export interface Props {
	key?: string
	value: string
}

interface saveStorage {
	saveLocal: boolean
	key?: string
	value: string
}

export const saveInStorage = ({
	saveLocal,
	value,
	key = MKASSA_ARM_TOKEN,
}: saveStorage) => {
	if (saveLocal) {
		setLocalStorage({ value, key })
		return
	}

	setSessionStorage({ value, key })
}

const params = new URLSearchParams(window.location.search)

export const getInStorage = (key = MKASSA_ARM_TOKEN) => {
	const localStorage = getLocalStorage(key)
	const sessionStorage = getSessionStorage(key)

	const merchantToken = params.get('merchantToken')

	return localStorage || sessionStorage || merchantToken
}

export const deleteStorage = () => {
	deleteLocalStorage()
	deleteSessionStorage()
}
