import React from 'react'

import styles from './Register.module.scss'

const NUMBER = '+996 (312) 61 33 33'
const WS = '+996 (556) 61 33 33'
const MBANK = '3333'

const ResetPassword = () => {
	return (
		<div className={styles.container}>
			<p>Пожалуйста, позвоните по номеру </p>

			<div>
				<a href={`tel:${NUMBER}`}>{NUMBER}</a>
			</div>
			{/* <div>
				<a href={`tel:${WS}`}>{WS}</a>
			</div> */}
			{/* <div>
				<a href={`tel:${MBANK}`}>{MBANK}</a>
			</div> */}
		</div>
	)
}

export default ResetPassword
